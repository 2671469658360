import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-input',
  template: `
   <div class="flex flex-column gap-1">
      <label style="color: #7A7F9A; font-size: 12px; font-weight: 600;" >{{ props['display_label'] }}</label>


    <input
            pInputText
            styleClass="w-full"
            id="text-input"
            [placeholder]="props['placeholder']"
            [maxlength]="props['maxLength']!"
            [formControl]="formControl"
            [formlyAttributes]="field"
            />


    </div>
  `,
})
export class InputFieldType extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type || 'text';
  }
}
