
<p-tabView [(activeIndex)]="activeIndex">
  <p-tabPanel (click)="setModule('LEADS')" header="{{ 'Integration.ZohoCRM.Leads' | translate }}">
    <app-formly
          [recordData] = "recordData"
          [module] = "'leads'"
          [useLayout] = true
          [titleCode] = ""
          (emitAction)="submit($event)"
          [sending] = "sending"
          (cancelAction)="cancel()"
          [zohoType]="'crm'"
        ></app-formly>
  </p-tabPanel>
  <p-tabPanel (click)="setModule('CONTACTS')" header="{{ 'Integration.ZohoCRM.Contacts' | translate }}">
    <app-formly
          [recordData] = "recordData"
          [module] = "'contacts'"
          [useLayout] = true
          [titleCode] = ""
          (emitAction)="submit($event)"
          [sending] = "sending"
          (cancelAction)="cancel()"
          [zohoType]="'crm'"
        ></app-formly>
  </p-tabPanel>
</p-tabView>
