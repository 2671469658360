export enum MessageType {
  Text = 0,
  Template = 1,
  Document = 2,
  Video = 3,
  Audio = 4,
  Sticker = 5,
  Reaction = 6,
  Location = 7,
  Image = 8
}