import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ZohoDeskBackendService } from '../../../data-access/zoho-desk-backend.service';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';

@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss']
})
export class TicketsListComponent implements OnDestroy, OnInit {

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  private subscriptions = new Subscription();
  public contact?: any;
  loading = false;
  hasError = false;
  tickets = [];
  updateCache?: boolean;

  ngOnInit(): void {
    let id = '-1';
    if (this.contact && this.contact['id']) {
      id = this.contact['id'];
    }
    this.getTickets(id);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTickets(id: string): void {
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .getTickets(id, this.updateCache)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.tickets = resp.body;
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
        this.hasError = true;
    });
  }

  ticketsDetails(ticket: any): void {
    this.emitAction.emit({
      action: 'ticket-details',
      values: ticket,
      module: ''
    });
  }

}
