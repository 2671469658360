import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from '../../../models/emitData.interface';
import { ZohoIntegrationBackendService } from '../../../data-access/zoho-integration-backend.service';
import { NoteResponse } from '../../../models/note-response.model';

@Component({
	selector: 'app-contact-details',
	templateUrl: './contact-details.component.html',
	styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit, OnDestroy {

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  private fb = inject(FormBuilder);
  private _ZohoIntegrationBackendService = inject(ZohoIntegrationBackendService);

  noteForm: FormGroup;
  savingNote: boolean;

  public recordData?: Map<string, any>;
  public module?: string;
  loaded = false;
  activeTab = 'tab1';
  loadingNotes = false;
  updatingRecord = false;
  notes: NoteResponse[] = [];

  private subscriptions = new Subscription();

  ngOnInit(): void {
    setTimeout(() => {
      this.loaded = true;
      this.createNoteForm();
      this.getNotes(false);
    });
  }

  ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

  setActiveTab(tabId: string) {
    this.activeTab = tabId;
  }

  cancel() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: this.module
		});
	}

  getNotes(update: boolean): void {
    this.loadingNotes = true ;
    const getNotes$ = this._ZohoIntegrationBackendService
    .getNotes(this.module, this.recordData['id'], update)
    .subscribe(
        (resp: HttpResponse<NoteResponse[]>) => {
            if (resp.status == 200) {
              this.notes = resp.body;
              this.loadingNotes = false ;
            }
            this.subscriptions.add(getNotes$);
        },
        (error: HttpErrorResponse) => this.errorHandler(error));
	}

  addNote(): void {
    this.disableNoteForm();
    const saveNote$ = this._ZohoIntegrationBackendService
      .saveNote(this.noteForm.value)
      .subscribe(() => {
          this.enableNoteForm();
          this.subscriptions.add(saveNote$);
          this.getNotes(true);
          this.createNoteForm();
      },
      (error: HttpErrorResponse) => {
        this.savingNote = false;
        this.errorHandler(error);
      });
	}

  openOnZohoCRM(recordId: number) {
    const domainName = localStorage.getItem('ZohoCrmOrgDomainName');
    window.open('https://crm.zoho.com/crm/'+domainName+'/tab/'+this.capitalize(this.module)+'/'+recordId);
  }

  createNoteForm() {
    this.noteForm = this.fb.group({});
    this.noteForm.addControl('noteContent', this.fb.control('', [Validators.required]));
    this.noteForm.addControl('recordId', this.fb.control(this.recordData['id'], []));
    this.noteForm.addControl('moduleZohoCrmType', this.fb.control(this.module, []));
  }

  public disableNoteForm() {
    this.savingNote = true;
    this.noteForm.disable();
  }

  public enableNoteForm() {
    this.noteForm.enable();
    this.noteForm.reset();
    this.savingNote = false;
  }

  errorHandler(error: HttpErrorResponse) {
    this.loadingNotes = false ;
		this.emitAction.emit({
			action: 'error',
			values: error,
      module: this.module
		});
	}

  edit(): void {
    this.emitAction.emit({
			action: 'edit',
			values: this.recordData,
      module: this.module
		});
  }

  capitalize(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  emitOthersAction(emit: any): void {
    this.emitAction.emit(emit);
  }

  convert(): void {
    this.emitAction.emit({
			action: 'convert',
			values: this.recordData,
      module: this.module
		});
  }

}
