<div
	*ngIf="openPanelIntegration"
	class="integration-panel-sidebar"
	[ngClass]="{ 'hidden-panel': openPanel, active: integrationSelected || integrationZoho }"
>
	<div *ngIf="!integrationSelected" class="expansition" (click)="showPanel()">
		<i class="material-icons-outlined">{{ openPanel ? 'chevron_left' : 'chevron_right' }}</i>
	</div>
	<div #childComponents></div>
	<!-- Dinamic components below -->

	<app-select-integration-module
		[integrations]="integrations"
		[ocultIntegrationSidebar]="openPanel"
		(selectIntegration)="handleSelectModule($event)"
	></app-select-integration-module>
</div>
