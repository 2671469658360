import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ZohoIntegrationBackendService } from 'src/app/integrations/zoho/data-access/zoho-integration-backend.service';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnDestroy, OnInit {

  private _ZohoIntegrationBackendService = inject(ZohoIntegrationBackendService);
  private subscriptions = new Subscription();
  public recordData?: Map<string, any>;
  public module?: string;
  loading = false;
  hasError = false;
  activities = [];
  updateCache?: boolean;

  ngOnInit(): void {
    let recordId = '-1';
    if (this.recordData && this.recordData['id']) {
      recordId = this.recordData['id'];
    }
    this.getActivities(recordId, this.updateCache);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getActivities(recordId: string, update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoIntegrationBackendService
    .getActivity(recordId, this.module, update)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.activities = resp.body;
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
        this.hasError = true;
    });
  }

  getIcon(type: string): string {
    if (type === 'tasks') return "pi-list-check";
    if (type === 'calls') return "pi-phone";
    return "pi-calendar-clock";
  }
}
