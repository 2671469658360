
    <p-menubar *ngIf="showNavBar">
      <ng-template pTemplate="start">
        <div class="flex align-items-center gap-2">
          <i pTooltip="{{ 'Integration.ZohoCRM.Exit ZohoCRM' | translate }}" style="cursor: pointer"
            (click)="this.closeZCRM.emit()" class="pi pi-arrow-left mr-1"></i>
          <i pTooltip="{{ 'Integration.ZohoCRM.Configure and sync Layouts' | translate }}" style="cursor: pointer"
            (click)="startIntegrationConfig()" class="pi pi-cog mr-1"></i>
          <i pTooltip="{{ 'Integration.ZohoCRM.Close the integration with ZohoCRM' | translate }}"
            style="cursor: pointer" (click)="logout()" class="pi pi-sign-out"></i>
        </div>
      </ng-template>
      <ng-template pTemplate="end">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <div class="flex align-items-center gap-2">
            <input formControlName="searchInput" type="text" pInputText
              placeholder="{{ 'Integration.ZohoCRM.Search on Zoho' | translate }}"
              pTooltip="{{ 'Integration.ZohoCRM.Search on Zoho' | translate }}"
            />
            <i  pTooltip="{{ 'Integration.ZohoCRM.Search on Zoho' | translate }}" *ngIf="!searching" style="cursor: pointer" class="pi pi-search" (click)="search()"></i>
            <i *ngIf="searching" class="pi pi-spin pi-spinner"></i>
          </div>
        </form>
      </ng-template>
    </p-menubar>

    <div #childZohoDeskComponents></div>
    <!-- Dinamic components below -->
