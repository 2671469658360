import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
	ServiceInstanceManagerI,
	channelManagerI
} from '../../data-access/interface/service-instance-manager.interface';

@Component({
	selector: 'app-chat-conversation',
	templateUrl: './chat-conversation.component.html',
	styleUrls: ['./chat-conversation.component.scss']
})
export class ChatConversationComponent implements OnChanges {
	@Input() serviceInstanceManager: ServiceInstanceManagerI = null;

	@Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();

	public channelManager: channelManagerI[] = [];

	ngOnChanges(): void {
		if (this.serviceInstanceManager) this.channelManager = this.serviceInstanceManager.config.channels;
	}

  handleDataChanged(event: any): void {
    this.dataChanged.emit(event);
  }
}
