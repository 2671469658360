<div *ngIf="!loading && ticketMetrics" class="mt-2">

  <span class="text-secondary text-sm"> Summary </span>
  <table class="table-tickets-details">
    <tr>
      <td class="row-details tlabel">First response time</td>
      <td class="tvalue">{{ ticketMetrics.firstResponseTime }}</td>
    </tr>
    <tr>
      <td class="row-details tlabel">Reassign count</td>
      <td class="tvalue">{{ ticketMetrics.reassignCount }}</td>
    </tr>
    <tr>
      <td class="row-details tlabel">Outgoing count</td>
      <td class="tvalue">{{ ticketMetrics.outgoingCount }}</td>
    </tr>
    <tr>
      <td class="row-details tlabel">Thread count</td>
      <td class="tvalue">{{ ticketMetrics.threadCount }}</td>
    </tr>
    <tr>
      <td class="row-details tlabel">Response count</td>
      <td class="tvalue">{{ ticketMetrics.responseCount }}</td>
    </tr>
    <tr>
      <td class="row-details tlabel">Reopen count</td>
      <td class="tvalue">{{ ticketMetrics.reopenCount }}</td>
    </tr>
    <tr>
      <td class="row-details tlabel">Total response time</td>
      <td class="tvalue">{{ ticketMetrics.totalResponseTime }}</td>
    </tr>
    <tr>
      <td class="row-details tlabel">Resolution time</td>
      <td class="tvalue">{{ ticketMetrics.resolutionTime }}</td>
    </tr>
  </table>
  <hr />
  <span *ngIf="ticketMetrics.agentsHandled && ticketMetrics.agentsHandled.length > 0" class="text-secondary text-sm"> Agents handled </span>
  <table *ngIf="ticketMetrics.agentsHandled && ticketMetrics.agentsHandled.length > 0" class="table-tickets-details">
      <tr *ngFor="let ah of ticketMetrics.agentsHandled">
        <td class="row-details tlabel"> {{ ah.handlingTime }}</td>
        <td class="tvalue">
          <div class="flex flex-column gap-1">
            <span class="font-bold text-md">{{  ah.agentName }}</span>
        </div>
        </td>
      </tr>
  </table>

  <hr />
  <span *ngIf="ticketMetrics.stagingData && ticketMetrics.stagingData.length > 0" class="text-secondary text-sm"> Staging data </span>
  <table *ngIf="ticketMetrics.stagingData && ticketMetrics.stagingData.length > 0" class="table-tickets-details">
    <tr *ngFor="let ah of ticketMetrics.stagingData">
        <td class="row-details tlabel"> {{ ah.handledTime }}</td>
        <td class="tvalue">
          <div class="flex flex-column gap-1">
            <span class="font-bold text-md">{{  ah.status }}</span>
        </div>
        </td>
      </tr>
  </table>

</div>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>
