import {
	Component,
	ComponentRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	ViewChild,
	ViewContainerRef,
	inject
} from '@angular/core';
import { ConversationEventService } from 'src/app/chat/data-access/services/conversation-event.service';
import { IntegrationService } from 'src/app/chat/data-access/services/integration.service';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { TimeToChangeComponent } from '../../time-to-change/feature/time-to-change.component';
import { integrations } from '../data-access/enums/integration.enum';
import { IntegrationI, IntegrationSetupI } from '../data-access/interfaces/integration.interface';
import { ZohoComponent } from '../../zoho/feature/zoho.component';

@Component({
	selector: 'app-integration-panel',
	templateUrl: './integration-panel.component.html',
	styleUrls: ['./integration-panel.component.scss']
})
export class IntegrationPanelComponent implements OnChanges {
	@ViewChild('childComponents', { read: ViewContainerRef }) container!: ViewContainerRef;

	@Input() serviceInstance: IServiceInstance | undefined = undefined;

	@Output() closePanel = new EventEmitter<boolean>();

	private integrationService = inject(IntegrationService);
	private conversationEventService = inject(ConversationEventService);

	public integrations: IntegrationI[] = [];
	public integrationsInitial: IntegrationI[] = [];
	public integrationTypeSetup: string[] = [];

	public openPanel: boolean = false;
	public openPanelIntegration: boolean = false;
	public integrationSelected: boolean = false;
	public integrationZoho: boolean = false;

  private zohoComponentRef: ComponentRef<ZohoComponent> | null = null;  // Añadir referencia al componente

	ngOnChanges(): void {
		if (this.serviceInstance) {
			this.integrationTypeSetup = this.serviceInstance.config.Integrations.map((element: IntegrationSetupI) => {
				return element.Id;
			});

			this.openPanelIntegration = true;

			setTimeout(() => this.searchIntegrations());
		}

		this.conversationEventService.getEventTransfer().subscribe((valueTransfer) => {
			if (valueTransfer) this.openPanelIntegration = false;
		});
	}

	searchIntegrations(): void {
		this.integrationService.queryPageParams(0, 10, 'serviceType=0').subscribe({
			next: (data) => {
				this.integrationsInitial = data.results;
				this.integrations = this.newRows(data.results);
			},
			error: (_) => {
				console.error('An error has occurred');
			}
		});
	}

  handleSelectModule(selection: number): void {
    switch (selection) {
      case integrations.TimeToChange:
        this.integrationSelected = true;
        this.container.clear();
        this.startTimeToChange();
        break;
      case integrations.ZohoCRM:
        this.integrationZoho = true;
        this.container.clear();
        this.startZoho('crm');
        break;
      case integrations.ZohoDesk:
        this.integrationZoho = true;
        this.container.clear();
        this.startZoho('desk');
        break;
    }
  }

	startTimeToChange(): void {
		const onTTCIntegration$ = this.container.createComponent(TimeToChangeComponent);

		onTTCIntegration$.instance.closeTTC.subscribe((_) => {
			this.container.clear();
			this.integrationSelected = false;
		});
	}

  startZoho(integration: string): void {
    localStorage.setItem('activeZoho', integration)
    this.zohoComponentRef = this.container.createComponent(ZohoComponent);
    this.zohoComponentRef.instance.serviceInstance = this.serviceInstance;
    this.zohoComponentRef.instance.zohoIntegration = integration;
    this.zohoComponentRef.instance.ocultIntegrationSidebar = this.openPanel;  // Asignar openPanel

    this.zohoComponentRef.instance.closeZCRM.subscribe((_) => {
      this.container.clear();
      this.zohoComponentRef = null;  // Limpiar referencia
      // this.startSelectModule();
    });
  }

	closeChatInfo(): void {
		this.closePanel.emit(true);
	}

	newRows(results: IntegrationI[]): IntegrationI[] {
		return results.map((element) => {
			return {
				...element,
				IsIntegrationAdded: this.integrationTypeSetup.includes(element.id),
				Image: this.getImage(element.id),
				selected: false
			};
		});
	}

	getNameIntegration(id: string): string {
		const integration = this.integrationsInitial.filter((item) => item.id === id);
		return integration[0].name;
	}

	getImage(id: string): string {
		switch (this.getNameIntegration(id)) {
			case 'Zoho Desk':
				return 'assets/images/integrations/Zoho Desk.svg';
			case 'Zoho CRM':
				return 'assets/images/integrations/Zoho CRM.svg';
			case 'Zoho People':
				return 'assets/images/integrations/Zoho People.svg';

			default:
				return 'assets/images/integrations/Zoho People.svg';
		}
	}

	showPanel(): void {
		this.openPanel = !this.openPanel;

    // Emitir el cambio para actualizar ocultIntegrationSidebar en ZohoCRMComponent
    if (this.integrationZoho && this.zohoComponentRef) {
      this.zohoComponentRef.instance.ocultIntegrationSidebar = this.openPanel;
    }
  }
}
