<div class="contact-content">
	<div class="head-contact">
		<div class="flex flex-row gap-3">
			<div class="avatar">A</div>

			<div class="phone-name">
				<span class="text-name"> Aaron Adams </span>
				<span class="text-phonenumber"> +1 555-123-4567 </span>
			</div>
		</div>
		<div class="item-action" pTooltip="{{ 'Delete' | translate }}" tooltipPosition="left">
			<i class="material-icons-outlined">delete</i>
		</div>
	</div>
	<div class="body-contact">
		<div class="flex flex-column gap-4 w-full">
			<app-contact-form class="w-full"></app-contact-form>
			<div class="buttons">
				<div class="cancel">{{ 'Cancel' | translate }}</div>
				<p-button>
					<div class="flex flex-row align-items-center flex-wrap gap-2">
						<span class="label">{{ 'Save changes' | translate }}</span>
						<i class="material-icons-outlined icon">save</i>
					</div>
				</p-button>
			</div>
		</div>
		<div class="flex flex-column gap-3 w-full">
			<div class="title-search">
				<span class="title-class">{{ 'Conversation history' | translate }}</span>
				<app-search-field [whiteBackground]="true"></app-search-field>
			</div>
			<app-table-agent class="w-full"></app-table-agent>
		</div>
	</div>
</div>
