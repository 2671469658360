<div class="pt-4">
    <div style="text-align: right;" class="mb-4">
      <p-button
        (click)="create()"
        label="Nueva"
        icon="pi pi-plus"
        size="small"
      />
    </div>
    <div #childDealsComponents></div>
  </div>
