import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RedirectZohoCRMRoutingModule } from './redirect-zoho-routing.module';
import { RedirectZohoCRMComponent } from './redirect-zoho.component';

@NgModule({
	declarations: [RedirectZohoCRMComponent],
	imports: [CommonModule, RedirectZohoCRMRoutingModule],
	providers: [],
	exports: []
})
export class RedirectZohoCRMModule {}
