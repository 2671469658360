import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { DealsFormComponent } from './form/deals-form.component';
import { DealsListComponent } from './list/deals-list.component';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';

@Component({
	selector: 'app-zohocrm-deals',
	templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements OnInit {

  @ViewChild('childDealsComponents', { read: ViewContainerRef }) dealContainer!: ViewContainerRef;

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();
  @Input("recordData") recordData?: Map<string, any> ;
  @Input("module") module?: string;

  private subscriptions = new Subscription();

  ngOnInit(): void {
    setTimeout(() => {
      this.refreshList(false);
    });
  }

  create(): void {
    this.dealContainer.clear();
    const dealsFormComponent = this.dealContainer.createComponent(DealsFormComponent);
    dealsFormComponent.instance.recordData = this.recordData;
    const dealsFormEvent$ = dealsFormComponent.instance.emitAction.subscribe((values) => {
        this.subscriptions.add(dealsFormEvent$);
        if (values.action === 'deals-created') {
          this.refreshList(true);
        } else {
          if (values.action === 'error') {
            this.emitAction.emit(values);
          } else {
            if (values.action === 'cancel') {
              this.refreshList(false);
            }
          }
        }
    });
	}

  refreshList(update): void {
    this.dealContainer.clear();
    const activityFormComponent = this.dealContainer.createComponent(DealsListComponent);
    activityFormComponent.instance.recordData = this.recordData ;
    activityFormComponent.instance.updateCache = update ;
	}

}
