<div *ngIf="!loading && description && description.length > 0" class="mt-2">

<p class="text-secondary text-sm">{{ description }}</p>

</div>

<p class="empty-list" *ngIf="!loading && (!description || description.length == 0)">
  {{ 'Integration.ZohoCRM.There are no records' | translate }}
</p>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>
