<div class="pt-4">
  <div>
    <h6>{{ 'Integration.ZohoCRM.Select Layouts' | translate }}</h6>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="col-md-12">
        <div class="row">
          <div *ngFor="let key of getValues()">
            <div *ngIf="layoutResponse[key]" class="col-md-6 pt-3 pb-3">
              <h6> {{ 'Integration.ZohoCRM.' + key | translate }}</h6>
              <div formGroupName="{{ key }}">
                <div *ngFor="let option of layoutResponse[key]">
                  <p-checkbox
                    [formControlName]="option.name"
                    label="{{ option.name }}"
                    [binary]="true"
                    (click)="updateSelection(key, option.name)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="col-md-12 mt-3 mr-2">
        <p-inputSwitch
          formControlName="showOnlyRequiredFields"
          (click)="updateShowOnlyRequiredFields($event)"
          class="ng-dirty ng-invalid"
          inputId="chkshowOnlyRequiredFields"
        />
        <label class="ml-3"  for="chkshowOnlyRequiredFields">
          {{ 'Integration.ZohoCRM.Only show required fields on forms' | translate }}
        </label>
      </div>

      <div class="flex justify-content-center flex-wrap align-items-center mt-5">
        <p-button label="{{ 'Integration.ZohoCRM.Sync' | translate }}" icon="pi pi-refresh" size="small" class="mr-2"
          type="submit" [disabled]="sending" />
      </div>

    </form>
  </div>

</div>
