<app-formly
  *ngIf="loaded"
  [recordData] = "recordData"
  [module] = "'deals'"
  [useLayout] = true
  [titleCode] = ""
  (emitAction)="submit($event)"
  (cancelAction)="cancel()"
  [sending] = "sending"
></app-formly>

