import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { BarSearchComponent } from './ui/bar-search/bar-search.component';
import { FieldPhonenumberComponent } from './ui/fields/field-phonenumber/field-phonenumber.component';
import { SearchFieldComponent } from './ui/fields/search-field/search-field.component';
import { TextFieldComponent } from './ui/fields/text-field/text-field.component';
import { MiniCardContactComponent } from './ui/mini-card-contact/mini-card-contact.component';
import { SelectCustomComponent } from './ui/select-custom/select-custom.component';

const primeNGModules = [TooltipModule, ButtonModule, DialogModule, CheckboxModule, InputTextModule, InputMaskModule];

@NgModule({
	declarations: [
		SelectCustomComponent,
		BarSearchComponent,
		TextFieldComponent,
		MiniCardContactComponent,
		SearchFieldComponent,
		FieldPhonenumberComponent
	],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, ...primeNGModules],
	exports: [
		SelectCustomComponent,
		BarSearchComponent,
		TextFieldComponent,
		MiniCardContactComponent,
		SearchFieldComponent,
		FieldPhonenumberComponent
	]
})
export class SharedModule {}
