import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { emitData } from '../../../models/emitData.interface';
import { RecordResponse } from '../../../models/records-response.model';

@Component({
	selector: 'app-record-multiples-records',
	templateUrl: './record-multiples-records.component.html',
	styleUrls: ['./record-multiples-records.component.scss']
})
export class RecordMultiplesRecordsComponent implements OnInit {

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  public records?: RecordResponse[];

  ngOnInit(): void {
  }

selected(module: string, data: Map<string, any>) {
    this.emitAction.emit({
        action: 'selected',
        values: data,
        module: module
    });
}

  cancel() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: ''
		});
	}
}
