import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CustomerService } from '../data-access/customer.service';
import { ISubscription } from '../interfaces/subscription.interface';
import { LoadSubscription } from './subs.actions';

declare const Zone: any;

export class SubscriptionStateModel {
	subscription?: ISubscription | null;
}

const defaults: SubscriptionStateModel = {
	subscription: null
};

@State<SubscriptionStateModel>({
	name: 'subs',
	defaults
})
@Injectable()
export class SubscriptionState {
	private customerService = inject(CustomerService);

	@Selector()
	static getSubscription(state: SubscriptionStateModel): ISubscription {
		let subscription: any = state;
		return subscription;
	}

	@Selector()
	static hasSubscription(state: SubscriptionStateModel): boolean {
		return state.subscription !== null;
	}

	@Selector()
	static isSubscriptionActive(state: SubscriptionStateModel): boolean {
		let currentDate = new Date();
		return state.subscription!.end! >= currentDate;
	}

	@Action(LoadSubscription)
	LoadSubsicription(ctx: StateContext<ISubscription>, action: LoadSubscription) {
		this.customerService.getCustomerSubscription().subscribe((data) => {
			ctx.setState(data);
		});
	}
}
