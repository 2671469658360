import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoadToken } from './token-ttc.actions';

export class TokenTTCStateModel {
	token?: string;
}

const defaults: TokenTTCStateModel = {
	token: null
};

@State<TokenTTCStateModel>({
  name: 'ttc',
  defaults
})
@Injectable()
export class TokenTTCState {
  @Selector()
  static getToken(state: TokenTTCStateModel): string {
    return state.token;
  }

  @Action(LoadToken)
  LoadToken(ctx: StateContext<TokenTTCStateModel>, action: LoadToken) {
    ctx.patchState({ token: action.token });
  }
}
