import { Channel } from '../enum/channel-type.enum';
import { ConversationState } from '../enum/conversation-type.enum';
import { Message } from '../interface/chat.interface';

export class Chat {
	id: string;
	fullName: string;
	avatar: '../icons/logoEpika-128X128.png';
	about: string;
	role: 'Frontend Developer';
	State: ConversationState;
	clientPhone: string;
	businessPhone: string;
  setupId: string;
	messages: Message[] = [];
	unSeenMsg: number;
	channel: Channel;
	groupedMessagesByDate: any[];

	constructor(
		id: string,
		fullName: string,
		about: string,
		clientPhone: string,
		businessPhone: string,
		message: Message | undefined,
		unSeenMsg: number,
		channel: Channel,
		state: ConversationState = ConversationState.Active
	) {
		this.id = id;
		this.fullName = fullName;
		this.about = about;
		this.clientPhone = clientPhone;
		this.businessPhone = businessPhone;
		if (message != undefined) {
			this.messages.push(message);
		}

		this.unSeenMsg = unSeenMsg;
		this.channel = channel;
		this.State = state;
	}

	addMessage(message: Message) {
		this.messages.push(message);
	}
	addFirstMessage(message: Message) {
		this.messages.unshift(message);
	}
	// setGroupedMessagesByDate(messages: Message[]) {
	// 	const groupedMessages: GroupedMessages = {};
	// 	const now = new Date();
	// 	const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	// 	const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
	// 	const lastFiveDays = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 4);

	// 	for (const message of messages) {
	// 		const date = new Date(message.timeStamp * 1000);
	// 		let groupKey = date.toLocaleDateString('es-ES', { day: 'numeric', month: 'short', year: 'numeric' });
	// 		const diffTime = Math.abs(date.getTime() - now.getTime());
	// 		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	// 		// Comparar las fechas ignorando la hora, los minutos y los segundos
	// 		const isToday =
	// 			date.getFullYear() === today.getFullYear() &&
	// 			date.getMonth() === today.getMonth() &&
	// 			date.getDate() === today.getDate();
	// 		const isYesterday =
	// 			date.getFullYear() === yesterday.getFullYear() &&
	// 			date.getMonth() === yesterday.getMonth() &&
	// 			date.getDate() === yesterday.getDate();

	// 		if (isToday) {
	// 			groupKey = 'Hoy';
	// 		} else if (isYesterday) {
	// 			groupKey = 'Ayer';
	// 		}

	// 		if (groupedMessages[groupKey]) {
	// 			groupedMessages[groupKey].push(message);
	// 		} else {
	// 			groupedMessages[groupKey] = [message];
	// 		}
	// 	}

	// 	this.groupedMessagesByDate = groupedMessages;
	// }
	getGroupedMessagesByDate(): any {
		return this.groupedMessagesByDate;
	}

	increaseUnSeenMsg(): void {
		this.unSeenMsg++;
	}

	setGroupedMessagesByDate(): void {
		let fechaActual = null;
		this.groupedMessagesByDate = [];

		for (const mensaje of this.messages) {
			const fechaMensaje = this.obtenerFechaFormateada(mensaje.timeStamp);

			if (fechaMensaje !== fechaActual) {
				this.groupedMessagesByDate.push({
					haveDate: true,
					date: fechaMensaje
				});
				fechaActual = fechaMensaje;
			}

			this.groupedMessagesByDate.push(mensaje);
		}
	}

	obtenerFechaFormateada(timeStamp: number): string {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

		const date = new Date(timeStamp * 1000); // Convertir la marca de tiempo a milisegundos

		// Comparar las fechas ignorando la hora, los minutos y los segundos
		const isToday =
			date.getFullYear() === today.getFullYear() &&
			date.getMonth() === today.getMonth() &&
			date.getDate() === today.getDate();
		const isYesterday =
			date.getFullYear() === yesterday.getFullYear() &&
			date.getMonth() === yesterday.getMonth() &&
			date.getDate() === yesterday.getDate();

		if (isToday) {
			return 'Hoy';
		} else if (isYesterday) {
			return 'Ayer';
		}

		const opcionesFecha = { day: 'numeric', month: 'short', year: 'numeric' } as const;
		return date.toLocaleDateString('es-ES', opcionesFecha);
	}
}
