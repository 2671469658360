import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-input',
  template: `
    <div class="flex flex-column gap-1">
        <label style="color: #7A7F9A; font-size: 12px; font-weight: 600;">{{ props['display_label'] }}</label>
        <p-dropdown
          [options]="values"
          [placeholder]="props['placeholder']"
          [formControl]="formControl"
          [formlyAttributes]="field"
          inputId="select-input"
        ></p-dropdown>
    </div>
  `,
})
export class SelectFieldType
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  public values: any[] = [];

  ngOnInit() {
    this.props.options?.forEach((element) => {
      this.values.push(element['label']);
    });
  }

  get type() {
    return this.props.type || 'text';
  }
}
