<div *ngIf="!loading && tickets && tickets.length > 0" class="mt-2">
  <div *ngFor="let ticket of tickets" class="mb-2">
    <div class="row">
      <div class="col-12">
        <p-card [style]="{ padding: '15px' }">
          <div class="flex justify-content-between gap-5">
              <div class="flex flex-column gap-1">
                  <span class="font-bold text-md">{{  ticket.subject }}</span>
                  <span class="text-secondary text-sm"> #{{ ticket.ticketNumber }}</span>
              </div>
              <span class="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" >
                  <p-badge [value]="ticket.statusType" severity="success" />
              </span>
          </div>
          <div class="row">
            <div class="col-9">
              <div class="col-md-6-custom text-left">
                <span style="font-style: italic;" class="text-secondary text-sm">{{ ticket.createdTime }}</span>
              </div>
            </div>
            <div class="col-3">
              <div class="col-md-6-custom text-right">
                <button
                  [disabled]="sending"
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="ticketsDetails(ticket)"
                >
                  {{ 'Integration.ZohoCRM.Details' | translate }}
                </button>
              </div>
            </div>
          </div>
      </p-card>
      </div>
    </div>
  </div>
</div>

<p *ngIf="!loading && (!tickets || tickets.length == 0)">
  {{ 'Integration.ZohoCRM.There are no tickets' | translate }}
</p>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>
