import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoIntegrationBackendService } from 'src/app/integrations/zoho/data-access/zoho-integration-backend.service';
import { Record } from 'src/app/integrations/zoho/models/records-response.model';
import { ZohoDeskBackendService } from '../../../data-access/zoho-desk-backend.service';

@Component({
	selector: 'app-contact-desk-banner',
	templateUrl: './contact-desk-banner.component.html',
	styleUrls: ['./contact-desk-banner.component.scss']
})
export class ContactDeskBannerComponent implements OnInit {

	@Output() emitRecordUpdate: EventEmitter<Map<string, any>> = new EventEmitter<Map<string, any>>();
	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  @Input("contact") contact?: any ;

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);

  updating = false;
  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.contact = JSON.parse(JSON.stringify(this.contact));
  }

  errorHandler(error: HttpErrorResponse) {
		this.emitAction.emit({
			action: 'error',
			values: error,
      module: ''
		});
	}

  updateContact(): void {
    this.updating = true;
    const updateRecord$ = this._ZohoDeskBackendService
    .getContacts(this.contact.id)
    .subscribe((res: HttpResponse<any>) => {
        if (res && res.body) {
          this.contact = res.body;
        }
        this.subscriptions.add(updateRecord$);
        this.updating = false;
    },
    (error: HttpErrorResponse) => {
      this.updating = false;
      this.errorHandler(error);
    });
  }

}
