import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { emitData } from '../../../models/emitData.interface';

@Component({
	selector: 'app-desk-contact-details',
	templateUrl: './contact-desk-details.component.html',
	styleUrls: ['./contact-desk-details.component.scss']
})
export class ContactDeskDetailsComponent implements OnInit, OnDestroy {

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  public contact?: any;
  loaded = false;
  activeTab = 'tab1';
  loading = false;
  updatingRecord = false;
  tickets: any[] = [];

  private subscriptions = new Subscription();

  ngOnInit(): void {
    setTimeout(() => {
      this.loaded = true;
    });
  }

  ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

  setActiveTab(tabId: string) {
    this.activeTab = tabId;
  }

  cancel() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: ''
		});
	}

  errorHandler(error: HttpErrorResponse) {
    this.loading = false ;
		this.emitAction.emit({
			action: 'error',
			values: error,
      module: ''
		});
	}

  capitalize(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  emitOthersAction(emit: any): void {
    this.emitAction.emit(emit);
  }
}
