<p-table [columns]="cols" [value]="agents">
	<ng-template pTemplate="header" let-columns>
		<tr>
			<th *ngFor="let col of columns">
				{{ col.header }}
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowData let-columns="columns">
		<tr>
			<td *ngFor="let col of columns">
				<ng-container *ngIf="col.field === 'name'">
					<span class="d-flex align-items-center">
						<span [ngClass]="rowData['color']" class="profile mr-2">
							{{ rowData[col.field].charAt(0) }}
						</span>
						{{ rowData[col.field] }}
					</span>
				</ng-container>

				<ng-container *ngIf="col.field === 'status'">
					<span class="d-flex align-items-center">
						<span [ngClass]="rowData['colorStatus']" class="badge statusText">
							{{ rowData[col.field] }}
						</span>
					</span>
				</ng-container>

				<ng-container *ngIf="!['name', 'actions', 'status'].includes(col.field)">
					{{ rowData[col.field] }}
				</ng-container>

				<ng-container *ngIf="col.field === 'actions'">
					<div class="btn-icon">
						<i class="material-icons-outlined" (click)="menuTable.toggle($event)">more_vert</i>
					</div>
				</ng-container>
			</td>
		</tr>
	</ng-template>
</p-table>

<p-menu #menuTable [model]="listActions" [popup]="true">
	<ng-template pTemplate="item" let-item>
		<div class="contaner-option" (click)="handleClickOption(item.icon)">
			<span class="label">{{ item.label }}</span>
			<i class="material-icons-outlined icon">{{ item.icon }}</i>
		</div>
	</ng-template>
</p-menu>
