<ul *ngIf="!loading && activities && activities.length > 0" class="list-group list-group-flush mt-2">
  <li *ngFor="let activity of activities" class="list-group-item mb-2">
    <div class="row">
      <div class="col-2">
        <i style="font-size: 1.5rem" class="pi" ngClass="{{ getIcon(activity.type) }}"></i>
      </div>
      <div class="col-10">
        <p style="font-weight: bold;">{{ activity.title }}</p>
        {{ activity.createdBy }}
        <br />
        {{ activity.created }}
      </div>
    </div>
</ul>

<p *ngIf="!loading && (!activities || activities.length == 0)">
  {{ 'Integration.ZohoCRM.There are no activities' | translate }}
</p>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>
