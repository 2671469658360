export interface clientType {
  value: number;
  text: string;
}

export const clientTypes: clientType [] = [
  {
    value: 3,
    text: "Cliente",
  },
  {
    value: 2,
    text: "Aliado",
  },
  {
    value: 1,
    text: "Distribuidora",
  },
];
