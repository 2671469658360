import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-text-field',
	templateUrl: './text-field.component.html',
	styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent {
	@Input() valueField: string = '';
	@Input() placeholder: string = '';
	@Input() icon: string = '';

	@Output() emitValue: EventEmitter<string> = new EventEmitter<string>();

	handleValue(): void {
		this.emitValue.emit(this.valueField);
	}
}
