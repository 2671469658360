import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-input',
  template: `
    <div class="flex flex-column gap-1">
      <p-checkbox
        [formControl]="formControl"
        [formlyAttributes]="field"
        binary="true"
        label="{{ props.label }}"
      ></p-checkbox>
    </div>
  `,
})
export class CheckboxType extends FieldType<FieldTypeConfig> {
  get type() {
    return this.props.type || 'text';
  }
}
