<li class="chats" [ngClass]="{ right: !isFriend }">
	<div *ngIf="!messageGruop.haveDate" class="conversation-list">
		<div class="chat-avatar">
			<div class="avatar-sm">
				<span class="avatar-title rounded-circle bg-primary-subtle text-primary">
					{{ messageGruop.contact.charAt(0) }}
				</span>
			</div>
		</div>

		<div class="user-chat-content">
			<div class="ctext-wrap">
				<div class="ctext-wrap-content">
					<p *ngIf="this.messageGruop.messageType === 0" class="mb-0 messageText">
						{{ messageGruop.message }}
					</p>
					<div *ngIf="this.messageGruop.messageType === 2" class="card p-2 mb-2">
						<div class="d-flex align-items-center">
							<div class="avatar-sm me-3 ms-0">
								<div class="avatar-title bg-primary-subtle text-primary rounded font-size-20">
									<i [ngClass]="getMessageMimeTypeIconClass()"></i>
								</div>
							</div>
							<div class="d-flex-body">
								<div class="text-start">
									<h5 class="font-size-14 mb-1">{{ getFileName() }}</h5>
								</div>
							</div>

							<div class="ms-4">
								<ul class="list-inline mb-0 font-size-20">
									<li class="list-inline-item">
										<a href="javascript:void(0);" class="text-muted">
											<i class="ri-download-2-line" (click)="downloadFile()"></i>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div *ngIf="this.messageGruop.messageType === 3">
						<video [src]="this.messageGruop.message.url" class="card-img-top" height="180" controls></video>
					</div>
					<div *ngIf="this.messageGruop.messageType === 4">
						<audio controls class="custom-audio">
							<source [src]="this.messageGruop.message.url" />
						</audio>
					</div>
					<ul *ngIf="this.messageGruop.messageType === 8" class="list-inline message-img mb-0">
						<li class="list-inline-item message-img-list">
							<div>
								<a class="popup-img d-inline-block m-1 p-0" href="javascript:void(0);" title="Project 1">
									<img [src]="messageGruop.message.url" alt="" class="rounded border" (click)="openImage()" />
								</a>
							</div>
						</li>
					</ul>
					<p class="chat-time mb-0">
						<i class="ri-time-line align-middle"></i>
						<span class="align-middle">{{ getTime(this.messageGruop.timeStamp) }}</span>
					</p>
				</div>
			</div>
			<div class="conversation-name">
				<span>{{ messageGruop.contact }}</span>
			</div>
		</div>
	</div>
	<div class="chat-day-title" *ngIf="messageGruop.haveDate">
		<span class="title">{{ messageGruop.date }}</span>
	</div>
</li>
