import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ICustomer } from '../interfaces/customer.interface';
import { CompleteLogin } from './auth.actions';

declare const Zone: any;

export class AuthStateModel {
	customer?: ICustomer | null;
	completingLogin?: boolean;
}

const defaults: AuthStateModel = {
	customer: null,
	completingLogin: false
};

@State<AuthStateModel>({
	name: 'auth',
	defaults
})
@Injectable()
export class AuthState {
	@Selector()
	static isAuthenticated(state: AuthStateModel): boolean {
		let isAuthenticated: any = state.completingLogin;
		return isAuthenticated;
	}

	@Action(CompleteLogin)
	completeLogin(ctx: StateContext<AuthStateModel>, action: CompleteLogin) {
		ctx.patchState({ completingLogin: action.completingLogin });
	}
}
