<div class="d-lg-flex">
	<div class="chat-leftsidebar ms-lg-0">
		<div class="contact-leftsidebar">
			<div class="head-content">
				<span class="title flex align-items-center"> Contacts </span>
				<p-button>
					<div class="flex flex-row align-items-center flex-wrap gap-2">
						<i class="material-icons-outlined icon">group_add</i>
						<span class="label">{{ 'Add contact' | translate }}</span>
					</div>
				</p-button>
			</div>
      <app-search-field class="w-full"></app-search-field>
			<app-list-contact></app-list-contact>
		</div>
	</div>
  <div class="open-contact">
    <app-contact-content></app-contact-content>
  </div>
</div>
