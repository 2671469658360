import { Component, EventEmitter, HostListener, Output, inject } from '@angular/core';
import { emitData } from 'src/app/integrations/time-to-change/data-access/interface/emitData.interface';
import { PopupService } from '../../data-access/popup-autentication.service';

@Component({
	selector: 'app-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
export class LoadingZohoCrmComponent {}
