<div class="item-class">
	<span class="name">{{ infoContact.name }}</span>
	<div class="btn-icon">
		<i class="material-icons-outlined" (click)="menuContact.toggle($event)">more_vert</i>
	</div>
</div>

<p-menu #menuContact [model]="listOptions" [popup]="true">
	<ng-template pTemplate="item" let-item>
		<div class="contaner-option" (click)="handleClickOption(item.icon)">
			<span class="label">{{ item.label }}</span>
			<i class="material-icons-outlined icon">{{ item.icon }}</i>
		</div>
	</ng-template>
</p-menu>
