<div class="account-container">
	<form class="form-account" [formGroup]="form">
		<span class="title">Crear Cuenta</span>

		<div class="flex flex-column gap-1">
			<label class="label">Nombre</label>
			<input type="text" pInputText class="form-control" formControlName="name" placeholder="Nombre" />
		</div>

		<div class="flex flex-column gap-1">
			<label class="label">NIT</label>
			<input type="text" pInputText class="form-control" formControlName="nit" placeholder="NIT" />
		</div>

		<div class="flex flex-column gap-1">
			<label class="label">Tipo </label>
			<p-dropdown [options]="typeAccount" optionLabel="text" optionValue="value" formControlName="type" />
		</div>

		<div class="flex flex-column gap-1">
			<label class="label">Departamento</label>
			<p-dropdown [options]="departments" optionLabel="text" optionValue="value" formControlName="state" />
		</div>

		<div class="mb-3">
			<label class="label">Ciudad</label>
			<p-dropdown [options]="municipalitiesDepartment" optionLabel="text" optionValue="value" formControlName="city" />
		</div>

		<div class="flex flex-row gap-3">
			<div class="col-md-6-custom text-center">
				<button type="button" class="btn btn-secondary btn-sm" (click)="cancel()">Regresar</button>
			</div>
			<div class="col-md-6-custom text-center">
				<button type="button" class="btn btn-primary btn-sm" (click)="createAccount()">Crear</button>
			</div>
		</div>
	</form>
</div>
