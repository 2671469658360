import { Injectable } from '@angular/core';
import parsePhoneNumberFromString, { getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import { CountryCode } from 'libphonenumber-js/types';
import { PhoneNumberExample } from '../interfaces/lib-phonenumer.interface';

@Injectable({
	providedIn: 'root'
})
export class PhoneMaskService {
	getPhoneMask(countryCode: string): string {
		const exampleNumber = getExampleNumber(countryCode as CountryCode, examples) as PhoneNumberExample;
		return exampleNumber ? exampleNumber.nationalNumber : '';
	}

	isValidPhoneNumber(phoneNumber: string): boolean {
		const parsedNumber = parsePhoneNumberFromString(phoneNumber);
		return parsedNumber ? parsedNumber.isValid() : false;
	}
}
