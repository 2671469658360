import { SafeUrl } from '@angular/platform-browser';
import { MessageType } from '../interface/message.interface';

export class Message {
  id: string;
  conversationId: string | null;
  message: any;
  timeStamp: number;
  messageType: MessageType; //*
  from: string | null;
  to: string;
  channel: number;
  contact: string;

  constructor(
    id: string,
    conversationId: string | null,
    content: any,
    timeStamp: number,
    messageType: MessageType,
    to: string,
    from: string | null,
    contact: string
  ) {
    this.id = id;
    this.conversationId = conversationId;
    this.message = content;
    this.timeStamp = timeStamp;
    this.messageType = messageType;
    this.to = to;
    this.from = from;
    this.contact = contact;
  }
}

export interface MessageContent {
  id: string;
  mime_type: string;
  Caption?: string;
  FileName?: string;
  url?: SafeUrl;
}

export interface GroupedMessages {
  [key: string]: Message[];
}
