import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Account } from '../../../data-access/interface/search-account.interface';
import { AccountTTCService } from '../../../data-access/services/account-ttc.service';

@Component({
	selector: 'app-search-field-ttc',
	templateUrl: './search-field.component.html',
	styleUrls: ['./search-field.component.scss']
})
export class SearchFieldTTCComponent {
	@Input() accountType: string;
	@Input() placeholder: string;
	@Input() accountName: string | undefined;

	@Output() accountContent = new EventEmitter<string | undefined>();

	private _AccountTTCService = inject(AccountTTCService);
	public stateSearchAccount: boolean = true;
	public accounts: Account[];

	searchAccount() {
		this.stateSearchAccount = false;

		this._AccountTTCService.searchAccounts(this.accountName).subscribe((accounts) => {
			this.accounts = accounts;
			if (this.accountType === 'Distribuidor' || this.accountType === 'Aliado') {
				this.accounts = this.accounts.filter((data) => data.id_account_type === this.accountType);
			}
		});
	}

	searchStateAccount() {
		this.stateSearchAccount = true;
	}

	emitSelectAcount() {
		this.accountContent.emit(this.accountName);
	}
}
