export interface cityCode {
  codigoDepartamento: number;
  value: number;
  nombreDepartamento: string;
  text: string;
  tipo: string; //Municipio/Isla/Área no municipalizada
}

export const cityCodes: cityCode[] = [
  {
    codigoDepartamento: 5,
    value: 5001,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'MEDELLÍN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5002,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ABEJORRAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5004,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ABRIAQUÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5021,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ALEJANDRÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5030,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'AMAGÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5031,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'AMALFI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5034,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ANDES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5036,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ANGELÓPOLIS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5038,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ANGOSTURA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5040,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ANORÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5042,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SANTA FÉ DE ANTIOQUIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5044,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ANZÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5045,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'APARTADÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 551,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ARBOLETES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 555,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ARGELIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 559,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ARMENIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5079,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'BARBOSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 586,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'BELMIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 588,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'BELLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5091,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'BETANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5093,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'BETULIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5101,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CIUDAD BOLÍVAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5107,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'BRICEÑO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5113,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'BURITICÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5120,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CÁCERES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5125,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CAICEDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5129,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CALDAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5134,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CAMPAMENTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5138,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CAÑASGORDAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5142,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CARACOLÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5145,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CARAMANTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5147,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CAREPA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5148,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'EL CARMEN DE VIBORAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5150,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CAROLINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5154,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CAUCASIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5172,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CHIGORODÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5190,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CISNEROS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5197,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'COCORNÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5206,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CONCEPCIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5209,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'CONCORDIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5212,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'COPACABANA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5234,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'DABEIBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5237,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'DONMATÍAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5240,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'EBÉJICO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5250,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'EL BAGRE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5264,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ENTRERRÍOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5266,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ENVIGADO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5282,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'FREDONIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5284,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'FRONTINO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5306,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'GIRALDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 538,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'GIRARDOTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5310,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'GÓMEZ PLATA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5313,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'GRANADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5315,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'GUADALUPE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5318,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'GUARNE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5321,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'GUATAPÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5347,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'HELICONIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5353,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'HISPANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5360,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ITAGÜÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5361,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ITUANGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5364,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'JARDÍN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5368,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'JERICÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5376,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'LA CEJA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5380,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'LA ESTRELLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5390,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'LA PINTADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5400,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'LA UNIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5411,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'LIBORINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5425,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'MACEO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5440,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'MARINILLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5467,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'MONTEBELLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5475,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'MURINDÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5480,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'MUTATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5483,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'NARIÑO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5490,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'NECOCLÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5495,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'NECHÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5501,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'OLAYA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5541,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'PEÑOL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5543,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'PEQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5576,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'PUEBLORRICO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5579,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'PUERTO BERRÍO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5585,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'PUERTO NARE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5591,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'PUERTO TRIUNFO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5604,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'REMEDIOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5607,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'RETIRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5615,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'RIONEGRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5628,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SABANALARGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5631,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SABANETA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5642,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SALGAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5647,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN ANDRÉS DE CUERQUÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5649,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN CARLOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5652,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN FRANCISCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5656,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN JERÓNIMO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5658,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN JOSÉ DE LA MONTAÑA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5659,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN JUAN DE URABÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5660,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN LUIS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5664,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN PEDRO DE LOS MILAGROS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5665,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN PEDRO DE URABÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5667,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN RAFAEL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5670,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN ROQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5674,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SAN VICENTE FERRER',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5679,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SANTA BÁRBARA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5686,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SANTA ROSA DE OSOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5690,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SANTO DOMINGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5697,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'EL SANTUARIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5736,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SEGOVIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5756,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SONSÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5761,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'SOPETRÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5789,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'TÁMESIS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5790,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'TARAZÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5792,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'TARSO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5809,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'TITIRIBÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5819,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'TOLEDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5837,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'TURBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5842,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'URAMITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5847,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'URRAO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5854,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'VALDIVIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5856,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'VALPARAÍSO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5858,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'VEGACHÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5861,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'VENECIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5873,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'VIGÍA DEL FUERTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5885,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'YALÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5887,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'YARUMAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5890,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'YOLOMBÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5893,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'YONDÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 5,
    value: 5895,
    nombreDepartamento: 'ANTIOQUIA',
    text: 'ZARAGOZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8001,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'BARRANQUILLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8078,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'BARANOA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8137,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'CAMPO DE LA CRUZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8141,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'CANDELARIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8296,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'GALAPA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8372,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'JUAN DE ACOSTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8421,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'LURUACO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8433,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'MALAMBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8436,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'MANATÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8520,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'PALMAR DE VARELA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8549,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'PIOJÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8558,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'POLONUEVO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8560,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'PONEDERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8573,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'PUERTO COLOMBIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8606,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'REPELÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8634,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'SABANAGRANDE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8638,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'SABANALARGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8675,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'SANTA LUCÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8685,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'SANTO TOMÁS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8758,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'SOLEDAD',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8770,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'SUAN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8832,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'TUBARÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 8,
    value: 8849,
    nombreDepartamento: 'ATLÁNTICO',
    text: 'USIACURÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 11,
    value: 11001,
    nombreDepartamento: 'BOGOTÁ. D.C.',
    text: 'BOGOTÁ. D.C.',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13001,
    nombreDepartamento: 'BOLÍVAR',
    text: 'CARTAGENA DE INDIAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13006,
    nombreDepartamento: 'BOLÍVAR',
    text: 'ACHÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13030,
    nombreDepartamento: 'BOLÍVAR',
    text: 'ALTOS DEL ROSARIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13042,
    nombreDepartamento: 'BOLÍVAR',
    text: 'ARENAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 1352,
    nombreDepartamento: 'BOLÍVAR',
    text: 'ARJONA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13062,
    nombreDepartamento: 'BOLÍVAR',
    text: 'ARROYOHONDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13074,
    nombreDepartamento: 'BOLÍVAR',
    text: 'BARRANCO DE LOBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13140,
    nombreDepartamento: 'BOLÍVAR',
    text: 'CALAMAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13160,
    nombreDepartamento: 'BOLÍVAR',
    text: 'CANTAGALLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13188,
    nombreDepartamento: 'BOLÍVAR',
    text: 'CICUCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13212,
    nombreDepartamento: 'BOLÍVAR',
    text: 'CÓRDOBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13222,
    nombreDepartamento: 'BOLÍVAR',
    text: 'CLEMENCIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13244,
    nombreDepartamento: 'BOLÍVAR',
    text: 'EL CARMEN DE BOLÍVAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13248,
    nombreDepartamento: 'BOLÍVAR',
    text: 'EL GUAMO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13268,
    nombreDepartamento: 'BOLÍVAR',
    text: 'EL PEÑÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13300,
    nombreDepartamento: 'BOLÍVAR',
    text: 'HATILLO DE LOBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13430,
    nombreDepartamento: 'BOLÍVAR',
    text: 'MAGANGUÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13433,
    nombreDepartamento: 'BOLÍVAR',
    text: 'MAHATES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13440,
    nombreDepartamento: 'BOLÍVAR',
    text: 'MARGARITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13442,
    nombreDepartamento: 'BOLÍVAR',
    text: 'MARÍA LA BAJA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13458,
    nombreDepartamento: 'BOLÍVAR',
    text: 'MONTECRISTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13468,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SANTA CRUZ DE MOMPOX',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13473,
    nombreDepartamento: 'BOLÍVAR',
    text: 'MORALES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13490,
    nombreDepartamento: 'BOLÍVAR',
    text: 'NOROSÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13549,
    nombreDepartamento: 'BOLÍVAR',
    text: 'PINILLOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13580,
    nombreDepartamento: 'BOLÍVAR',
    text: 'REGIDOR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13600,
    nombreDepartamento: 'BOLÍVAR',
    text: 'RÍO VIEJO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13620,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SAN CRISTÓBAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13647,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SAN ESTANISLAO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13650,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SAN FERNANDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13654,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SAN JACINTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13655,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SAN JACINTO DEL CAUCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13657,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SAN JUAN NEPOMUCENO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13667,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SAN MARTÍN DE LOBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13670,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SAN PABLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13673,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SANTA CATALINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13683,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SANTA ROSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13688,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SANTA ROSA DEL SUR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13744,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SIMITÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13760,
    nombreDepartamento: 'BOLÍVAR',
    text: 'SOPLAVIENTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13780,
    nombreDepartamento: 'BOLÍVAR',
    text: 'TALAIGUA NUEVO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13810,
    nombreDepartamento: 'BOLÍVAR',
    text: 'TIQUISIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13836,
    nombreDepartamento: 'BOLÍVAR',
    text: 'TURBACO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13838,
    nombreDepartamento: 'BOLÍVAR',
    text: 'TURBANÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13873,
    nombreDepartamento: 'BOLÍVAR',
    text: 'VILLANUEVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 13,
    value: 13894,
    nombreDepartamento: 'BOLÍVAR',
    text: 'ZAMBRANO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15001,
    nombreDepartamento: 'BOYACÁ',
    text: 'TUNJA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15022,
    nombreDepartamento: 'BOYACÁ',
    text: 'ALMEIDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15047,
    nombreDepartamento: 'BOYACÁ',
    text: 'AQUITANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 1551,
    nombreDepartamento: 'BOYACÁ',
    text: 'ARCABUCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 1587,
    nombreDepartamento: 'BOYACÁ',
    text: 'BELÉN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15090,
    nombreDepartamento: 'BOYACÁ',
    text: 'BERBEO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15092,
    nombreDepartamento: 'BOYACÁ',
    text: 'BETÉITIVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15097,
    nombreDepartamento: 'BOYACÁ',
    text: 'BOAVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15104,
    nombreDepartamento: 'BOYACÁ',
    text: 'BOYACÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15106,
    nombreDepartamento: 'BOYACÁ',
    text: 'BRICEÑO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15109,
    nombreDepartamento: 'BOYACÁ',
    text: 'BUENAVISTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15114,
    nombreDepartamento: 'BOYACÁ',
    text: 'BUSBANZÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15131,
    nombreDepartamento: 'BOYACÁ',
    text: 'CALDAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15135,
    nombreDepartamento: 'BOYACÁ',
    text: 'CAMPOHERMOSO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15162,
    nombreDepartamento: 'BOYACÁ',
    text: 'CERINZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15172,
    nombreDepartamento: 'BOYACÁ',
    text: 'CHINAVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15176,
    nombreDepartamento: 'BOYACÁ',
    text: 'CHIQUINQUIRÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15180,
    nombreDepartamento: 'BOYACÁ',
    text: 'CHISCAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15183,
    nombreDepartamento: 'BOYACÁ',
    text: 'CHITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15185,
    nombreDepartamento: 'BOYACÁ',
    text: 'CHITARAQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15187,
    nombreDepartamento: 'BOYACÁ',
    text: 'CHIVATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15189,
    nombreDepartamento: 'BOYACÁ',
    text: 'CIÉNEGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15204,
    nombreDepartamento: 'BOYACÁ',
    text: 'CÓMBITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15212,
    nombreDepartamento: 'BOYACÁ',
    text: 'COPER',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15215,
    nombreDepartamento: 'BOYACÁ',
    text: 'CORRALES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15218,
    nombreDepartamento: 'BOYACÁ',
    text: 'COVARACHÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15223,
    nombreDepartamento: 'BOYACÁ',
    text: 'CUBARÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19318,
    nombreDepartamento: 'CAUCA',
    text: 'GUAPI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15224,
    nombreDepartamento: 'BOYACÁ',
    text: 'CUCAITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15226,
    nombreDepartamento: 'BOYACÁ',
    text: 'CUÍTIVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15232,
    nombreDepartamento: 'BOYACÁ',
    text: 'CHÍQUIZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15236,
    nombreDepartamento: 'BOYACÁ',
    text: 'CHIVOR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15238,
    nombreDepartamento: 'BOYACÁ',
    text: 'DUITAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15244,
    nombreDepartamento: 'BOYACÁ',
    text: 'EL COCUY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15248,
    nombreDepartamento: 'BOYACÁ',
    text: 'EL ESPINO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15272,
    nombreDepartamento: 'BOYACÁ',
    text: 'FIRAVITOBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15276,
    nombreDepartamento: 'BOYACÁ',
    text: 'FLORESTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15293,
    nombreDepartamento: 'BOYACÁ',
    text: 'GACHANTIVÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15296,
    nombreDepartamento: 'BOYACÁ',
    text: 'GÁMEZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15299,
    nombreDepartamento: 'BOYACÁ',
    text: 'GARAGOA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15317,
    nombreDepartamento: 'BOYACÁ',
    text: 'GUACAMAYAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15322,
    nombreDepartamento: 'BOYACÁ',
    text: 'GUATEQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15325,
    nombreDepartamento: 'BOYACÁ',
    text: 'GUAYATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15332,
    nombreDepartamento: 'BOYACÁ',
    text: 'GÜICÁN DE LA SIERRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15362,
    nombreDepartamento: 'BOYACÁ',
    text: 'IZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15367,
    nombreDepartamento: 'BOYACÁ',
    text: 'JENESANO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15368,
    nombreDepartamento: 'BOYACÁ',
    text: 'JERICÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15377,
    nombreDepartamento: 'BOYACÁ',
    text: 'LABRANZAGRANDE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15380,
    nombreDepartamento: 'BOYACÁ',
    text: 'LA CAPILLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15401,
    nombreDepartamento: 'BOYACÁ',
    text: 'LA VICTORIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15403,
    nombreDepartamento: 'BOYACÁ',
    text: 'LA UVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15407,
    nombreDepartamento: 'BOYACÁ',
    text: 'VILLA DE LEYVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15425,
    nombreDepartamento: 'BOYACÁ',
    text: 'MACANAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15442,
    nombreDepartamento: 'BOYACÁ',
    text: 'MARIPÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15455,
    nombreDepartamento: 'BOYACÁ',
    text: 'MIRAFLORES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15464,
    nombreDepartamento: 'BOYACÁ',
    text: 'MONGUA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15466,
    nombreDepartamento: 'BOYACÁ',
    text: 'MONGUÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15469,
    nombreDepartamento: 'BOYACÁ',
    text: 'MONIQUIRÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15476,
    nombreDepartamento: 'BOYACÁ',
    text: 'MOTAVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15480,
    nombreDepartamento: 'BOYACÁ',
    text: 'MUZO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15491,
    nombreDepartamento: 'BOYACÁ',
    text: 'NOBSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15494,
    nombreDepartamento: 'BOYACÁ',
    text: 'NUEVO COLÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15500,
    nombreDepartamento: 'BOYACÁ',
    text: 'OICATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15507,
    nombreDepartamento: 'BOYACÁ',
    text: 'OTANCHE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15511,
    nombreDepartamento: 'BOYACÁ',
    text: 'PACHAVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15514,
    nombreDepartamento: 'BOYACÁ',
    text: 'PÁEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15516,
    nombreDepartamento: 'BOYACÁ',
    text: 'PAIPA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15518,
    nombreDepartamento: 'BOYACÁ',
    text: 'PAJARITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15522,
    nombreDepartamento: 'BOYACÁ',
    text: 'PANQUEBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15531,
    nombreDepartamento: 'BOYACÁ',
    text: 'PAUNA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15533,
    nombreDepartamento: 'BOYACÁ',
    text: 'PAYA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15537,
    nombreDepartamento: 'BOYACÁ',
    text: 'PAZ DE RÍO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15542,
    nombreDepartamento: 'BOYACÁ',
    text: 'PESCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15550,
    nombreDepartamento: 'BOYACÁ',
    text: 'PISBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15572,
    nombreDepartamento: 'BOYACÁ',
    text: 'PUERTO BOYACÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15580,
    nombreDepartamento: 'BOYACÁ',
    text: 'QUÍPAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15599,
    nombreDepartamento: 'BOYACÁ',
    text: 'RAMIRIQUÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15600,
    nombreDepartamento: 'BOYACÁ',
    text: 'RÁQUIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15621,
    nombreDepartamento: 'BOYACÁ',
    text: 'RONDÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15632,
    nombreDepartamento: 'BOYACÁ',
    text: 'SABOYÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15638,
    nombreDepartamento: 'BOYACÁ',
    text: 'SÁCHICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15646,
    nombreDepartamento: 'BOYACÁ',
    text: 'SAMACÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15660,
    nombreDepartamento: 'BOYACÁ',
    text: 'SAN EDUARDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15664,
    nombreDepartamento: 'BOYACÁ',
    text: 'SAN JOSÉ DE PARE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15667,
    nombreDepartamento: 'BOYACÁ',
    text: 'SAN LUIS DE GACENO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15673,
    nombreDepartamento: 'BOYACÁ',
    text: 'SAN MATEO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15676,
    nombreDepartamento: 'BOYACÁ',
    text: 'SAN MIGUEL DE SEMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15681,
    nombreDepartamento: 'BOYACÁ',
    text: 'SAN PABLO DE BORBUR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15686,
    nombreDepartamento: 'BOYACÁ',
    text: 'SANTANA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15690,
    nombreDepartamento: 'BOYACÁ',
    text: 'SANTA MARÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15693,
    nombreDepartamento: 'BOYACÁ',
    text: 'SANTA ROSA DE VITERBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15696,
    nombreDepartamento: 'BOYACÁ',
    text: 'SANTA SOFÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15720,
    nombreDepartamento: 'BOYACÁ',
    text: 'SATIVANORTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15723,
    nombreDepartamento: 'BOYACÁ',
    text: 'SATIVASUR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15740,
    nombreDepartamento: 'BOYACÁ',
    text: 'SIACHOQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15753,
    nombreDepartamento: 'BOYACÁ',
    text: 'SOATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15755,
    nombreDepartamento: 'BOYACÁ',
    text: 'SOCOTÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15757,
    nombreDepartamento: 'BOYACÁ',
    text: 'SOCHA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15759,
    nombreDepartamento: 'BOYACÁ',
    text: 'SOGAMOSO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15761,
    nombreDepartamento: 'BOYACÁ',
    text: 'SOMONDOCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15762,
    nombreDepartamento: 'BOYACÁ',
    text: 'SORA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15763,
    nombreDepartamento: 'BOYACÁ',
    text: 'SOTAQUIRÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15764,
    nombreDepartamento: 'BOYACÁ',
    text: 'SORACÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15774,
    nombreDepartamento: 'BOYACÁ',
    text: 'SUSACÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15776,
    nombreDepartamento: 'BOYACÁ',
    text: 'SUTAMARCHÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15778,
    nombreDepartamento: 'BOYACÁ',
    text: 'SUTATENZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15790,
    nombreDepartamento: 'BOYACÁ',
    text: 'TASCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15798,
    nombreDepartamento: 'BOYACÁ',
    text: 'TENZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15804,
    nombreDepartamento: 'BOYACÁ',
    text: 'TIBANÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15806,
    nombreDepartamento: 'BOYACÁ',
    text: 'TIBASOSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 1588,
    nombreDepartamento: 'BOYACÁ',
    text: 'TINJACÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15810,
    nombreDepartamento: 'BOYACÁ',
    text: 'TIPACOQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15814,
    nombreDepartamento: 'BOYACÁ',
    text: 'TOCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15816,
    nombreDepartamento: 'BOYACÁ',
    text: 'TOGÜÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15820,
    nombreDepartamento: 'BOYACÁ',
    text: 'TÓPAGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15822,
    nombreDepartamento: 'BOYACÁ',
    text: 'TOTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15832,
    nombreDepartamento: 'BOYACÁ',
    text: 'TUNUNGUÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15835,
    nombreDepartamento: 'BOYACÁ',
    text: 'TURMEQUÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15837,
    nombreDepartamento: 'BOYACÁ',
    text: 'TUTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15839,
    nombreDepartamento: 'BOYACÁ',
    text: 'TUTAZÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15842,
    nombreDepartamento: 'BOYACÁ',
    text: 'ÚMBITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15861,
    nombreDepartamento: 'BOYACÁ',
    text: 'VENTAQUEMADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15879,
    nombreDepartamento: 'BOYACÁ',
    text: 'VIRACACHÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 15,
    value: 15897,
    nombreDepartamento: 'BOYACÁ',
    text: 'ZETAQUIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17001,
    nombreDepartamento: 'CALDAS',
    text: 'MANIZALES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17013,
    nombreDepartamento: 'CALDAS',
    text: 'AGUADAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17042,
    nombreDepartamento: 'CALDAS',
    text: 'ANSERMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 1750,
    nombreDepartamento: 'CALDAS',
    text: 'ARANZAZU',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 1788,
    nombreDepartamento: 'CALDAS',
    text: 'BELALCÁZAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17174,
    nombreDepartamento: 'CALDAS',
    text: 'CHINCHINÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17272,
    nombreDepartamento: 'CALDAS',
    text: 'FILADELFIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17380,
    nombreDepartamento: 'CALDAS',
    text: 'LA DORADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17388,
    nombreDepartamento: 'CALDAS',
    text: 'LA MERCED',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17433,
    nombreDepartamento: 'CALDAS',
    text: 'MANZANARES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17442,
    nombreDepartamento: 'CALDAS',
    text: 'MARMATO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17444,
    nombreDepartamento: 'CALDAS',
    text: 'MARQUETALIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17446,
    nombreDepartamento: 'CALDAS',
    text: 'MARULANDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17486,
    nombreDepartamento: 'CALDAS',
    text: 'NEIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17495,
    nombreDepartamento: 'CALDAS',
    text: 'NORCASIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17513,
    nombreDepartamento: 'CALDAS',
    text: 'PÁCORA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17524,
    nombreDepartamento: 'CALDAS',
    text: 'PALESTINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17541,
    nombreDepartamento: 'CALDAS',
    text: 'PENSILVANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17614,
    nombreDepartamento: 'CALDAS',
    text: 'RIOSUCIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17616,
    nombreDepartamento: 'CALDAS',
    text: 'RISARALDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17653,
    nombreDepartamento: 'CALDAS',
    text: 'SALAMINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17662,
    nombreDepartamento: 'CALDAS',
    text: 'SAMANÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17665,
    nombreDepartamento: 'CALDAS',
    text: 'SAN JOSÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17777,
    nombreDepartamento: 'CALDAS',
    text: 'SUPÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17867,
    nombreDepartamento: 'CALDAS',
    text: 'VICTORIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17873,
    nombreDepartamento: 'CALDAS',
    text: 'VILLAMARÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 17,
    value: 17877,
    nombreDepartamento: 'CALDAS',
    text: 'VITERBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18001,
    nombreDepartamento: 'CAQUETÁ',
    text: 'FLORENCIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18029,
    nombreDepartamento: 'CAQUETÁ',
    text: 'ALBANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18094,
    nombreDepartamento: 'CAQUETÁ',
    text: 'BELÉN DE LOS ANDAQUÍES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18150,
    nombreDepartamento: 'CAQUETÁ',
    text: 'CARTAGENA DEL CHAIRÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 1825,
    nombreDepartamento: 'CAQUETÁ',
    text: 'CURILLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18247,
    nombreDepartamento: 'CAQUETÁ',
    text: 'EL DONCELLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18256,
    nombreDepartamento: 'CAQUETÁ',
    text: 'EL PAUJÍL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18410,
    nombreDepartamento: 'CAQUETÁ',
    text: 'LA MONTAÑITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18460,
    nombreDepartamento: 'CAQUETÁ',
    text: 'MILÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18479,
    nombreDepartamento: 'CAQUETÁ',
    text: 'MORELIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18592,
    nombreDepartamento: 'CAQUETÁ',
    text: 'PUERTO RICO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18610,
    nombreDepartamento: 'CAQUETÁ',
    text: 'SAN JOSÉ DEL FRAGUA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18753,
    nombreDepartamento: 'CAQUETÁ',
    text: 'SAN VICENTE DEL CAGUÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18756,
    nombreDepartamento: 'CAQUETÁ',
    text: 'SOLANO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18785,
    nombreDepartamento: 'CAQUETÁ',
    text: 'SOLITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 18,
    value: 18860,
    nombreDepartamento: 'CAQUETÁ',
    text: 'VALPARAÍSO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19001,
    nombreDepartamento: 'CAUCA',
    text: 'POPAYÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19022,
    nombreDepartamento: 'CAUCA',
    text: 'ALMAGUER',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 1950,
    nombreDepartamento: 'CAUCA',
    text: 'ARGELIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19075,
    nombreDepartamento: 'CAUCA',
    text: 'BALBOA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19100,
    nombreDepartamento: 'CAUCA',
    text: 'BOLÍVAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19110,
    nombreDepartamento: 'CAUCA',
    text: 'BUENOS AIRES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19130,
    nombreDepartamento: 'CAUCA',
    text: 'CAJIBÍO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19137,
    nombreDepartamento: 'CAUCA',
    text: 'CALDONO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19142,
    nombreDepartamento: 'CAUCA',
    text: 'CALOTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19212,
    nombreDepartamento: 'CAUCA',
    text: 'CORINTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19256,
    nombreDepartamento: 'CAUCA',
    text: 'EL TAMBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19290,
    nombreDepartamento: 'CAUCA',
    text: 'FLORENCIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19300,
    nombreDepartamento: 'CAUCA',
    text: 'GUACHENÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19355,
    nombreDepartamento: 'CAUCA',
    text: 'INZÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19364,
    nombreDepartamento: 'CAUCA',
    text: 'JAMBALÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19392,
    nombreDepartamento: 'CAUCA',
    text: 'LA SIERRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19397,
    nombreDepartamento: 'CAUCA',
    text: 'LA VEGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19418,
    nombreDepartamento: 'CAUCA',
    text: 'LÓPEZ DE MICAY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19450,
    nombreDepartamento: 'CAUCA',
    text: 'MERCADERES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19455,
    nombreDepartamento: 'CAUCA',
    text: 'MIRANDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19473,
    nombreDepartamento: 'CAUCA',
    text: 'MORALES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19513,
    nombreDepartamento: 'CAUCA',
    text: 'PADILLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19517,
    nombreDepartamento: 'CAUCA',
    text: 'PÁEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19532,
    nombreDepartamento: 'CAUCA',
    text: 'PATÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19533,
    nombreDepartamento: 'CAUCA',
    text: 'PIAMONTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19548,
    nombreDepartamento: 'CAUCA',
    text: 'PIENDAMÓ - TUNÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19573,
    nombreDepartamento: 'CAUCA',
    text: 'PUERTO TEJADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19585,
    nombreDepartamento: 'CAUCA',
    text: 'PURACÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19622,
    nombreDepartamento: 'CAUCA',
    text: 'ROSAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19693,
    nombreDepartamento: 'CAUCA',
    text: 'SAN SEBASTIÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19698,
    nombreDepartamento: 'CAUCA',
    text: 'SANTANDER DE QUILICHAO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19701,
    nombreDepartamento: 'CAUCA',
    text: 'SANTA ROSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19743,
    nombreDepartamento: 'CAUCA',
    text: 'SILVIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19760,
    nombreDepartamento: 'CAUCA',
    text: 'SOTARÁ PAISPAMBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19780,
    nombreDepartamento: 'CAUCA',
    text: 'SUÁREZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19785,
    nombreDepartamento: 'CAUCA',
    text: 'SUCRE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19807,
    nombreDepartamento: 'CAUCA',
    text: 'TIMBÍO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19809,
    nombreDepartamento: 'CAUCA',
    text: 'TIMBIQUÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19821,
    nombreDepartamento: 'CAUCA',
    text: 'TORIBÍO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19824,
    nombreDepartamento: 'CAUCA',
    text: 'TOTORÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 19,
    value: 19845,
    nombreDepartamento: 'CAUCA',
    text: 'VILLA RICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20001,
    nombreDepartamento: 'CESAR',
    text: 'VALLEDUPAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20011,
    nombreDepartamento: 'CESAR',
    text: 'AGUACHICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20013,
    nombreDepartamento: 'CESAR',
    text: 'AGUSTÍN CODAZZI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20032,
    nombreDepartamento: 'CESAR',
    text: 'ASTREA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20045,
    nombreDepartamento: 'CESAR',
    text: 'BECERRIL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20060,
    nombreDepartamento: 'CESAR',
    text: 'BOSCONIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20175,
    nombreDepartamento: 'CESAR',
    text: 'CHIMICHAGUA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20178,
    nombreDepartamento: 'CESAR',
    text: 'CHIRIGUANÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20228,
    nombreDepartamento: 'CESAR',
    text: 'CURUMANÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20238,
    nombreDepartamento: 'CESAR',
    text: 'EL COPEY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20250,
    nombreDepartamento: 'CESAR',
    text: 'EL PASO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20295,
    nombreDepartamento: 'CESAR',
    text: 'GAMARRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20310,
    nombreDepartamento: 'CESAR',
    text: 'GONZÁLEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20383,
    nombreDepartamento: 'CESAR',
    text: 'LA GLORIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20400,
    nombreDepartamento: 'CESAR',
    text: 'LA JAGUA DE IBIRICO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20443,
    nombreDepartamento: 'CESAR',
    text: 'MANAURE BALCÓN DEL CESAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 2517,
    nombreDepartamento: 'CESAR',
    text: 'PAILITAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 2550,
    nombreDepartamento: 'CESAR',
    text: 'PELAYA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 2570,
    nombreDepartamento: 'CESAR',
    text: 'PUEBLO BELLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20614,
    nombreDepartamento: 'CESAR',
    text: 'RÍO DE ORO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20621,
    nombreDepartamento: 'CESAR',
    text: 'LA PAZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20710,
    nombreDepartamento: 'CESAR',
    text: 'SAN ALBERTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20750,
    nombreDepartamento: 'CESAR',
    text: 'SAN DIEGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20770,
    nombreDepartamento: 'CESAR',
    text: 'SAN MARTÍN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 20,
    value: 20787,
    nombreDepartamento: 'CESAR',
    text: 'TAMALAMEQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23001,
    nombreDepartamento: 'CÓRDOBA',
    text: 'MONTERÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23068,
    nombreDepartamento: 'CÓRDOBA',
    text: 'AYAPEL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23079,
    nombreDepartamento: 'CÓRDOBA',
    text: 'BUENAVISTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23090,
    nombreDepartamento: 'CÓRDOBA',
    text: 'CANALETE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23162,
    nombreDepartamento: 'CÓRDOBA',
    text: 'CERETÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23168,
    nombreDepartamento: 'CÓRDOBA',
    text: 'CHIMÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23182,
    nombreDepartamento: 'CÓRDOBA',
    text: 'CHINÚ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23189,
    nombreDepartamento: 'CÓRDOBA',
    text: 'CIÉNAGA DE ORO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23300,
    nombreDepartamento: 'CÓRDOBA',
    text: 'COTORRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23350,
    nombreDepartamento: 'CÓRDOBA',
    text: 'LA APARTADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23417,
    nombreDepartamento: 'CÓRDOBA',
    text: 'LORICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23419,
    nombreDepartamento: 'CÓRDOBA',
    text: 'LOS CÓRDOBAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23464,
    nombreDepartamento: 'CÓRDOBA',
    text: 'MOMIL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23466,
    nombreDepartamento: 'CÓRDOBA',
    text: 'MONTELÍBANO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23500,
    nombreDepartamento: 'CÓRDOBA',
    text: 'MOÑITOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23555,
    nombreDepartamento: 'CÓRDOBA',
    text: 'PLANETA RICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23570,
    nombreDepartamento: 'CÓRDOBA',
    text: 'PUEBLO NUEVO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23574,
    nombreDepartamento: 'CÓRDOBA',
    text: 'PUERTO ESCONDIDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23580,
    nombreDepartamento: 'CÓRDOBA',
    text: 'PUERTO LIBERTADOR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23586,
    nombreDepartamento: 'CÓRDOBA',
    text: 'PURÍSIMA DE LA CONCEPCIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23660,
    nombreDepartamento: 'CÓRDOBA',
    text: 'SAHAGÚN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23670,
    nombreDepartamento: 'CÓRDOBA',
    text: 'SAN ANDRÉS DE SOTAVENTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23672,
    nombreDepartamento: 'CÓRDOBA',
    text: 'SAN ANTERO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23675,
    nombreDepartamento: 'CÓRDOBA',
    text: 'SAN BERNARDO DEL VIENTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23678,
    nombreDepartamento: 'CÓRDOBA',
    text: 'SAN CARLOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23682,
    nombreDepartamento: 'CÓRDOBA',
    text: 'SAN JOSÉ DE URÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23686,
    nombreDepartamento: 'CÓRDOBA',
    text: 'SAN PELAYO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23807,
    nombreDepartamento: 'CÓRDOBA',
    text: 'TIERRALTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23815,
    nombreDepartamento: 'CÓRDOBA',
    text: 'TUCHÍN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 23,
    value: 23855,
    nombreDepartamento: 'CÓRDOBA',
    text: 'VALENCIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25001,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'AGUA DE DIOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25019,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'ALBÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25035,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'ANAPOIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25040,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'ANOLAIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 2553,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'ARBELÁEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 2586,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'BELTRÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25095,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'BITUIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25099,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'BOJACÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25120,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CABRERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25123,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CACHIPAY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25126,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CAJICÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25148,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CAPARRAPÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25151,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CÁQUEZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25154,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CARMEN DE CARUPA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25168,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CHAGUANÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25175,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CHÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25178,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CHIPAQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25181,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CHOACHÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25183,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CHOCONTÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25200,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'COGUA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25214,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'COTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25224,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'CUCUNUBÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25245,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'EL COLEGIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25258,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'EL PEÑÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25260,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'EL ROSAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25269,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'FACATATIVÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25279,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'FÓMEQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25281,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'FOSCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25286,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'FUNZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25288,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'FÚQUENE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25290,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'FUSAGASUGÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25293,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GACHALÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25295,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GACHANCIPÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25297,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GACHETÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25299,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25307,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GIRARDOT',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25312,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GRANADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25317,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GUACHETÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25320,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GUADUAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25322,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GUASCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25324,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GUATAQUÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25326,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GUATAVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25328,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GUAYABAL DE SÍQUIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25335,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GUAYABETAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25339,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'GUTIÉRREZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25368,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'JERUSALÉN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25372,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'JUNÍN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25377,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'LA CALERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25386,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'LA MESA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25394,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'LA PALMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25398,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'LA PEÑA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25402,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'LA VEGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25407,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'LENGUAZAQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25426,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'MACHETÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25430,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'MADRID',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25436,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'MANTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25438,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'MEDINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25473,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'MOSQUERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25483,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'NARIÑO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25486,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'NEMOCÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25488,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'NILO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25489,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'NIMAIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25491,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'NOCAIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25506,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'VENECIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25513,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'PACHO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25518,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'PAIME',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25524,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'PANDI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25530,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'PARATEBUENO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25535,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'PASCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25572,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'PUERTO SALGAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25580,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'PULÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25592,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'QUEBRADANEGRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25594,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'QUETAME',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25596,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'QUIPILE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25599,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'APULO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25612,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'RICAURTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25645,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SAN ANTONIO DEL TEQUENDAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25649,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SAN BERNARDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25653,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SAN CAYETANO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25658,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SAN FRANCISCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25662,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SAN JUAN DE RIOSECO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25718,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SASAIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25736,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SESQUILÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25740,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SIBATÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25743,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SILVANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25745,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SIMIJACA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25754,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SOACHA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25758,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SOPÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25769,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SUBACHOQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25772,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SUESCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25777,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SUPATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25779,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SUSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25781,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'SUTATAUSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25785,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TABIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25793,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TAUSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25797,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TENA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25799,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TENJO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 2585,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TIBACUY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25807,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TIBIRITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25815,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TOCAIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25817,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TOCANCIPÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25823,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'TOPAIPÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25839,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'UBALÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25841,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'UBAQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25843,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'VILLA DE SAN DIEGO DE UBATÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25845,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'UNE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25851,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'ÚTICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25862,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'VERGARA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25867,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'VIANÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25871,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'VILLAGÓMEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25873,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'VILLAPINZÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25875,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'VILLETA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25878,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'VIOTÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25885,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'YACOPÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25898,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'ZIPACÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 25,
    value: 25899,
    nombreDepartamento: 'CUNDINAMARCA',
    text: 'ZIPAQUIRÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27001,
    nombreDepartamento: 'CHOCÓ',
    text: 'QUIBDÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27006,
    nombreDepartamento: 'CHOCÓ',
    text: 'ACANDÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27025,
    nombreDepartamento: 'CHOCÓ',
    text: 'ALTO BAUDÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 2750,
    nombreDepartamento: 'CHOCÓ',
    text: 'ATRATO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27073,
    nombreDepartamento: 'CHOCÓ',
    text: 'BAGADÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27075,
    nombreDepartamento: 'CHOCÓ',
    text: 'BAHÍA SOLANO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27077,
    nombreDepartamento: 'CHOCÓ',
    text: 'BAJO BAUDÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27099,
    nombreDepartamento: 'CHOCÓ',
    text: 'BOJAYÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27135,
    nombreDepartamento: 'CHOCÓ',
    text: 'EL CANTÓN DEL SAN PABLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27150,
    nombreDepartamento: 'CHOCÓ',
    text: 'CARMEN DEL DARIÉN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27160,
    nombreDepartamento: 'CHOCÓ',
    text: 'CÉRTEGUI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 2725,
    nombreDepartamento: 'CHOCÓ',
    text: 'CONDOTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27245,
    nombreDepartamento: 'CHOCÓ',
    text: 'EL CARMEN DE ATRATO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27250,
    nombreDepartamento: 'CHOCÓ',
    text: 'EL LITORAL DEL SAN JUAN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27361,
    nombreDepartamento: 'CHOCÓ',
    text: 'ISTMINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27372,
    nombreDepartamento: 'CHOCÓ',
    text: 'JURADÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27413,
    nombreDepartamento: 'CHOCÓ',
    text: 'LLORÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27425,
    nombreDepartamento: 'CHOCÓ',
    text: 'MEDIO ATRATO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27430,
    nombreDepartamento: 'CHOCÓ',
    text: 'MEDIO BAUDÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27450,
    nombreDepartamento: 'CHOCÓ',
    text: 'MEDIO SAN JUAN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27491,
    nombreDepartamento: 'CHOCÓ',
    text: 'NÓVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27495,
    nombreDepartamento: 'CHOCÓ',
    text: 'NUQUÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27580,
    nombreDepartamento: 'CHOCÓ',
    text: 'RÍO IRÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27600,
    nombreDepartamento: 'CHOCÓ',
    text: 'RÍO QUITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27615,
    nombreDepartamento: 'CHOCÓ',
    text: 'RIOSUCIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27660,
    nombreDepartamento: 'CHOCÓ',
    text: 'SAN JOSÉ DEL PALMAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27745,
    nombreDepartamento: 'CHOCÓ',
    text: 'SIPÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27787,
    nombreDepartamento: 'CHOCÓ',
    text: 'TADÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27800,
    nombreDepartamento: 'CHOCÓ',
    text: 'UNGUÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 27,
    value: 27810,
    nombreDepartamento: 'CHOCÓ',
    text: 'UNIÓN PANAMERICANA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41001,
    nombreDepartamento: 'HUILA',
    text: 'NEIVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41006,
    nombreDepartamento: 'HUILA',
    text: 'ACEVEDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41013,
    nombreDepartamento: 'HUILA',
    text: 'AGRADO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41016,
    nombreDepartamento: 'HUILA',
    text: 'AIPE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41020,
    nombreDepartamento: 'HUILA',
    text: 'ALGECIRAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41026,
    nombreDepartamento: 'HUILA',
    text: 'ALTAMIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41078,
    nombreDepartamento: 'HUILA',
    text: 'BARAYA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41132,
    nombreDepartamento: 'HUILA',
    text: 'CAMPOALEGRE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41206,
    nombreDepartamento: 'HUILA',
    text: 'COLOMBIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41244,
    nombreDepartamento: 'HUILA',
    text: 'ELÍAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41298,
    nombreDepartamento: 'HUILA',
    text: 'GARZÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41306,
    nombreDepartamento: 'HUILA',
    text: 'GIGANTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41319,
    nombreDepartamento: 'HUILA',
    text: 'GUADALUPE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41349,
    nombreDepartamento: 'HUILA',
    text: 'HOBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41357,
    nombreDepartamento: 'HUILA',
    text: 'ÍQUIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41359,
    nombreDepartamento: 'HUILA',
    text: 'ISNOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41378,
    nombreDepartamento: 'HUILA',
    text: 'LA ARGENTINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41396,
    nombreDepartamento: 'HUILA',
    text: 'LA PLATA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41483,
    nombreDepartamento: 'HUILA',
    text: 'NÁTAGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41503,
    nombreDepartamento: 'HUILA',
    text: 'OPORAPA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41518,
    nombreDepartamento: 'HUILA',
    text: 'PAICOL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41524,
    nombreDepartamento: 'HUILA',
    text: 'PALERMO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41530,
    nombreDepartamento: 'HUILA',
    text: 'PALESTINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41548,
    nombreDepartamento: 'HUILA',
    text: 'PITAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41551,
    nombreDepartamento: 'HUILA',
    text: 'PITALITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41615,
    nombreDepartamento: 'HUILA',
    text: 'RIVERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41660,
    nombreDepartamento: 'HUILA',
    text: 'SALADOBLANCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41668,
    nombreDepartamento: 'HUILA',
    text: 'SAN AGUSTÍN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41676,
    nombreDepartamento: 'HUILA',
    text: 'SANTA MARÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41770,
    nombreDepartamento: 'HUILA',
    text: 'SUAZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41791,
    nombreDepartamento: 'HUILA',
    text: 'TARQUI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41797,
    nombreDepartamento: 'HUILA',
    text: 'TESALIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41799,
    nombreDepartamento: 'HUILA',
    text: 'TELLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41801,
    nombreDepartamento: 'HUILA',
    text: 'TERUEL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41807,
    nombreDepartamento: 'HUILA',
    text: 'TIMANÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41872,
    nombreDepartamento: 'HUILA',
    text: 'VILLAVIEJA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 41,
    value: 41885,
    nombreDepartamento: 'HUILA',
    text: 'YAGUARÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44001,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'RIOHACHA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44035,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'ALBANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44078,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'BARRANCAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44090,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'DIBULLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44098,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'DISTRACCIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44110,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'EL MOLINO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44279,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'FONSECA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44378,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'HATONUEVO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44420,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'LA JAGUA DEL PILAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44430,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'MAICAO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44560,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'MANAURE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44650,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'SAN JUAN DEL CESAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44847,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'URIBIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44855,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'URUMITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 44,
    value: 44874,
    nombreDepartamento: 'LA GUAJIRA',
    text: 'VILLANUEVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47001,
    nombreDepartamento: 'MAGDALENA',
    text: 'SANTA MARTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47030,
    nombreDepartamento: 'MAGDALENA',
    text: 'ALGARROBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 4753,
    nombreDepartamento: 'MAGDALENA',
    text: 'ARACATACA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 4758,
    nombreDepartamento: 'MAGDALENA',
    text: 'ARIGUANÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47161,
    nombreDepartamento: 'MAGDALENA',
    text: 'CERRO DE SAN ANTONIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47170,
    nombreDepartamento: 'MAGDALENA',
    text: 'CHIVOLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47189,
    nombreDepartamento: 'MAGDALENA',
    text: 'CIÉNAGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 4725,
    nombreDepartamento: 'MAGDALENA',
    text: 'CONCORDIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47245,
    nombreDepartamento: 'MAGDALENA',
    text: 'EL BANCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47258,
    nombreDepartamento: 'MAGDALENA',
    text: 'EL PIÑÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47268,
    nombreDepartamento: 'MAGDALENA',
    text: 'EL RETÉN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47288,
    nombreDepartamento: 'MAGDALENA',
    text: 'FUNDACIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47318,
    nombreDepartamento: 'MAGDALENA',
    text: 'GUAMAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47460,
    nombreDepartamento: 'MAGDALENA',
    text: 'NUEVA GRANADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47541,
    nombreDepartamento: 'MAGDALENA',
    text: 'PEDRAZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47545,
    nombreDepartamento: 'MAGDALENA',
    text: 'PIJIÑO DEL CARMEN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47551,
    nombreDepartamento: 'MAGDALENA',
    text: 'PIVIJAY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47555,
    nombreDepartamento: 'MAGDALENA',
    text: 'PLATO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47570,
    nombreDepartamento: 'MAGDALENA',
    text: 'PUEBLOVIEJO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 4765,
    nombreDepartamento: 'MAGDALENA',
    text: 'REMOLINO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47660,
    nombreDepartamento: 'MAGDALENA',
    text: 'SABANAS DE SAN ÁNGEL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47675,
    nombreDepartamento: 'MAGDALENA',
    text: 'SALAMINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47692,
    nombreDepartamento: 'MAGDALENA',
    text: 'SAN SEBASTIÁN DE BUENAVISTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47703,
    nombreDepartamento: 'MAGDALENA',
    text: 'SAN ZENÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47707,
    nombreDepartamento: 'MAGDALENA',
    text: 'SANTA ANA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47720,
    nombreDepartamento: 'MAGDALENA',
    text: 'SANTA BÁRBARA DE PINTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47745,
    nombreDepartamento: 'MAGDALENA',
    text: 'SITIONUEVO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47798,
    nombreDepartamento: 'MAGDALENA',
    text: 'TENERIFE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47960,
    nombreDepartamento: 'MAGDALENA',
    text: 'ZAPAYÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 47,
    value: 47980,
    nombreDepartamento: 'MAGDALENA',
    text: 'ZONA BANANERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50001,
    nombreDepartamento: 'META',
    text: 'VILLAVICENCIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50006,
    nombreDepartamento: 'META',
    text: 'ACACÍAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50110,
    nombreDepartamento: 'META',
    text: 'BARRANCA DE UPÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50124,
    nombreDepartamento: 'META',
    text: 'CABUYARO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50150,
    nombreDepartamento: 'META',
    text: 'CASTILLA LA NUEVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50223,
    nombreDepartamento: 'META',
    text: 'CUBARRAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50226,
    nombreDepartamento: 'META',
    text: 'CUMARAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50245,
    nombreDepartamento: 'META',
    text: 'EL CALVARIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50251,
    nombreDepartamento: 'META',
    text: 'EL CASTILLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50270,
    nombreDepartamento: 'META',
    text: 'EL DORADO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50287,
    nombreDepartamento: 'META',
    text: 'FUENTE DE ORO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50313,
    nombreDepartamento: 'META',
    text: 'GRANADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50318,
    nombreDepartamento: 'META',
    text: 'GUAMAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50325,
    nombreDepartamento: 'META',
    text: 'MAPIRIPÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50330,
    nombreDepartamento: 'META',
    text: 'MESETAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50350,
    nombreDepartamento: 'META',
    text: 'LA MACARENA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50370,
    nombreDepartamento: 'META',
    text: 'URIBE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50400,
    nombreDepartamento: 'META',
    text: 'LEJANÍAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50450,
    nombreDepartamento: 'META',
    text: 'PUERTO CONCORDIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 5568,
    nombreDepartamento: 'META',
    text: 'PUERTO GAITÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 5573,
    nombreDepartamento: 'META',
    text: 'PUERTO LÓPEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 5577,
    nombreDepartamento: 'META',
    text: 'PUERTO LLERAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 5590,
    nombreDepartamento: 'META',
    text: 'PUERTO RICO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50606,
    nombreDepartamento: 'META',
    text: 'RESTREPO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50680,
    nombreDepartamento: 'META',
    text: 'SAN CARLOS DE GUAROA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50683,
    nombreDepartamento: 'META',
    text: 'SAN JUAN DE ARAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50686,
    nombreDepartamento: 'META',
    text: 'SAN JUANITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50689,
    nombreDepartamento: 'META',
    text: 'SAN MARTÍN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 50,
    value: 50711,
    nombreDepartamento: 'META',
    text: 'VISTAHERMOSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52001,
    nombreDepartamento: 'NARIÑO',
    text: 'PASTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52019,
    nombreDepartamento: 'NARIÑO',
    text: 'ALBÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52022,
    nombreDepartamento: 'NARIÑO',
    text: 'ALDANA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52036,
    nombreDepartamento: 'NARIÑO',
    text: 'ANCUYA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 5251,
    nombreDepartamento: 'NARIÑO',
    text: 'ARBOLEDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52079,
    nombreDepartamento: 'NARIÑO',
    text: 'BARBACOAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 5283,
    nombreDepartamento: 'NARIÑO',
    text: 'BELÉN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52110,
    nombreDepartamento: 'NARIÑO',
    text: 'BUESACO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52203,
    nombreDepartamento: 'NARIÑO',
    text: 'COLÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52207,
    nombreDepartamento: 'NARIÑO',
    text: 'CONSACÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52210,
    nombreDepartamento: 'NARIÑO',
    text: 'CONTADERO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52215,
    nombreDepartamento: 'NARIÑO',
    text: 'CÓRDOBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52224,
    nombreDepartamento: 'NARIÑO',
    text: 'CUASPUD CARLOSAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52227,
    nombreDepartamento: 'NARIÑO',
    text: 'CUMBAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52233,
    nombreDepartamento: 'NARIÑO',
    text: 'CUMBITARA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52240,
    nombreDepartamento: 'NARIÑO',
    text: 'CHACHAGÜÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52250,
    nombreDepartamento: 'NARIÑO',
    text: 'EL CHARCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52254,
    nombreDepartamento: 'NARIÑO',
    text: 'EL PEÑOL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52256,
    nombreDepartamento: 'NARIÑO',
    text: 'EL ROSARIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52258,
    nombreDepartamento: 'NARIÑO',
    text: 'EL TABLÓN DE GÓMEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52260,
    nombreDepartamento: 'NARIÑO',
    text: 'EL TAMBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52287,
    nombreDepartamento: 'NARIÑO',
    text: 'FUNES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52317,
    nombreDepartamento: 'NARIÑO',
    text: 'GUACHUCAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52320,
    nombreDepartamento: 'NARIÑO',
    text: 'GUAITARILLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52323,
    nombreDepartamento: 'NARIÑO',
    text: 'GUALMATÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52352,
    nombreDepartamento: 'NARIÑO',
    text: 'ILES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52354,
    nombreDepartamento: 'NARIÑO',
    text: 'IMUÉS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52356,
    nombreDepartamento: 'NARIÑO',
    text: 'IPIALES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52378,
    nombreDepartamento: 'NARIÑO',
    text: 'LA CRUZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52381,
    nombreDepartamento: 'NARIÑO',
    text: 'LA FLORIDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52385,
    nombreDepartamento: 'NARIÑO',
    text: 'LA LLANADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52390,
    nombreDepartamento: 'NARIÑO',
    text: 'LA TOLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52399,
    nombreDepartamento: 'NARIÑO',
    text: 'LA UNIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 5245,
    nombreDepartamento: 'NARIÑO',
    text: 'LEIVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52411,
    nombreDepartamento: 'NARIÑO',
    text: 'LINARES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52418,
    nombreDepartamento: 'NARIÑO',
    text: 'LOS ANDES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52427,
    nombreDepartamento: 'NARIÑO',
    text: 'MAGÜÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52435,
    nombreDepartamento: 'NARIÑO',
    text: 'MALLAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52473,
    nombreDepartamento: 'NARIÑO',
    text: 'MOSQUERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52480,
    nombreDepartamento: 'NARIÑO',
    text: 'NARIÑO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52490,
    nombreDepartamento: 'NARIÑO',
    text: 'OLAYA HERRERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52506,
    nombreDepartamento: 'NARIÑO',
    text: 'OSPINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52520,
    nombreDepartamento: 'NARIÑO',
    text: 'FRANCISCO PIZARRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52540,
    nombreDepartamento: 'NARIÑO',
    text: 'POLICARPA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52560,
    nombreDepartamento: 'NARIÑO',
    text: 'POTOSÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52565,
    nombreDepartamento: 'NARIÑO',
    text: 'PROVIDENCIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52573,
    nombreDepartamento: 'NARIÑO',
    text: 'PUERRES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52585,
    nombreDepartamento: 'NARIÑO',
    text: 'PUPIALES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52612,
    nombreDepartamento: 'NARIÑO',
    text: 'RICAURTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52621,
    nombreDepartamento: 'NARIÑO',
    text: 'ROBERTO PAYÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52678,
    nombreDepartamento: 'NARIÑO',
    text: 'SAMANIEGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52683,
    nombreDepartamento: 'NARIÑO',
    text: 'SANDONÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52685,
    nombreDepartamento: 'NARIÑO',
    text: 'SAN BERNARDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52687,
    nombreDepartamento: 'NARIÑO',
    text: 'SAN LORENZO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52693,
    nombreDepartamento: 'NARIÑO',
    text: 'SAN PABLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52694,
    nombreDepartamento: 'NARIÑO',
    text: 'SAN PEDRO DE CARTAGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52696,
    nombreDepartamento: 'NARIÑO',
    text: 'SANTA BÁRBARA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52699,
    nombreDepartamento: 'NARIÑO',
    text: 'SANTACRUZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52720,
    nombreDepartamento: 'NARIÑO',
    text: 'SAPUYES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52786,
    nombreDepartamento: 'NARIÑO',
    text: 'TAMINANGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52788,
    nombreDepartamento: 'NARIÑO',
    text: 'TANGUA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52835,
    nombreDepartamento: 'NARIÑO',
    text: 'SAN ANDRÉS DE TUMACO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52838,
    nombreDepartamento: 'NARIÑO',
    text: 'TÚQUERRES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 52,
    value: 52885,
    nombreDepartamento: 'NARIÑO',
    text: 'YACUANQUER',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54001,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'SAN JOSÉ DE CÚCUTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54003,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'ÁBREGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 5451,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'ARBOLEDAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54099,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'BOCHALEMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54109,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'BUCARASICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54125,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'CÁCOTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54128,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'CÁCHIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54172,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'CHINÁCOTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54174,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'CHITAGÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54206,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'CONVENCIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54223,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'CUCUTILLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54239,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'DURANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54245,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'EL CARMEN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54250,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'EL TARRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54261,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'EL ZULIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54313,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'GRAMALOTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54344,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'HACARÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54347,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'HERRÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54377,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'LABATECA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54385,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'LA ESPERANZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54398,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'LA PLAYA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 5445,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'LOS PATIOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54418,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'LOURDES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54480,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'MUTISCUA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54498,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'OCAÑA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54518,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'PAMPLONA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54520,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'PAMPLONITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54553,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'PUERTO SANTANDER',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54599,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'RAGONVALIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54660,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'SALAZAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54670,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'SAN CALIXTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54673,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'SAN CAYETANO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54680,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'SANTIAGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54720,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'SARDINATA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54743,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'SILOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54800,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'TEORAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54810,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'TIBÚ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54820,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'TOLEDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54871,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'VILLA CARO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 54,
    value: 54874,
    nombreDepartamento: 'NORTE DE SANTANDER',
    text: 'VILLA DEL ROSARIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63001,
    nombreDepartamento: 'QUINDÍO',
    text: 'ARMENIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63111,
    nombreDepartamento: 'QUINDÍO',
    text: 'BUENAVISTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63130,
    nombreDepartamento: 'QUINDÍO',
    text: 'CALARCÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63190,
    nombreDepartamento: 'QUINDÍO',
    text: 'CIRCASIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63212,
    nombreDepartamento: 'QUINDÍO',
    text: 'CÓRDOBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63272,
    nombreDepartamento: 'QUINDÍO',
    text: 'FILANDIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63302,
    nombreDepartamento: 'QUINDÍO',
    text: 'GÉNOVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63401,
    nombreDepartamento: 'QUINDÍO',
    text: 'LA TEBAIDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63470,
    nombreDepartamento: 'QUINDÍO',
    text: 'MONTENEGRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63548,
    nombreDepartamento: 'QUINDÍO',
    text: 'PIJAO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63594,
    nombreDepartamento: 'QUINDÍO',
    text: 'QUIMBAYA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 63,
    value: 63690,
    nombreDepartamento: 'QUINDÍO',
    text: 'SALENTO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66001,
    nombreDepartamento: 'RISARALDA',
    text: 'PEREIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66045,
    nombreDepartamento: 'RISARALDA',
    text: 'APÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66075,
    nombreDepartamento: 'RISARALDA',
    text: 'BALBOA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 6688,
    nombreDepartamento: 'RISARALDA',
    text: 'BELÉN DE UMBRÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66170,
    nombreDepartamento: 'RISARALDA',
    text: 'DOSQUEBRADAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66318,
    nombreDepartamento: 'RISARALDA',
    text: 'GUÁTICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66383,
    nombreDepartamento: 'RISARALDA',
    text: 'LA CELIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66400,
    nombreDepartamento: 'RISARALDA',
    text: 'LA VIRGINIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66440,
    nombreDepartamento: 'RISARALDA',
    text: 'MARSELLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66456,
    nombreDepartamento: 'RISARALDA',
    text: 'MISTRATÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66572,
    nombreDepartamento: 'RISARALDA',
    text: 'PUEBLO RICO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66594,
    nombreDepartamento: 'RISARALDA',
    text: 'QUINCHÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66682,
    nombreDepartamento: 'RISARALDA',
    text: 'SANTA ROSA DE CABAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 66,
    value: 66687,
    nombreDepartamento: 'RISARALDA',
    text: 'SANTUARIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68001,
    nombreDepartamento: 'SANTANDER',
    text: 'BUCARAMANGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68013,
    nombreDepartamento: 'SANTANDER',
    text: 'AGUADA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68020,
    nombreDepartamento: 'SANTANDER',
    text: 'ALBANIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 6851,
    nombreDepartamento: 'SANTANDER',
    text: 'ARATOCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68077,
    nombreDepartamento: 'SANTANDER',
    text: 'BARBOSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68079,
    nombreDepartamento: 'SANTANDER',
    text: 'BARICHARA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 6881,
    nombreDepartamento: 'SANTANDER',
    text: 'BARRANCABERMEJA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68092,
    nombreDepartamento: 'SANTANDER',
    text: 'BETULIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68101,
    nombreDepartamento: 'SANTANDER',
    text: 'BOLÍVAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68121,
    nombreDepartamento: 'SANTANDER',
    text: 'CABRERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68132,
    nombreDepartamento: 'SANTANDER',
    text: 'CALIFORNIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68147,
    nombreDepartamento: 'SANTANDER',
    text: 'CAPITANEJO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68152,
    nombreDepartamento: 'SANTANDER',
    text: 'CARCASÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68160,
    nombreDepartamento: 'SANTANDER',
    text: 'CEPITÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68162,
    nombreDepartamento: 'SANTANDER',
    text: 'CERRITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68167,
    nombreDepartamento: 'SANTANDER',
    text: 'CHARALÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68169,
    nombreDepartamento: 'SANTANDER',
    text: 'CHARTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68176,
    nombreDepartamento: 'SANTANDER',
    text: 'CHIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68179,
    nombreDepartamento: 'SANTANDER',
    text: 'CHIPATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68190,
    nombreDepartamento: 'SANTANDER',
    text: 'CIMITARRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68207,
    nombreDepartamento: 'SANTANDER',
    text: 'CONCEPCIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68209,
    nombreDepartamento: 'SANTANDER',
    text: 'CONFINES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68211,
    nombreDepartamento: 'SANTANDER',
    text: 'CONTRATACIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68217,
    nombreDepartamento: 'SANTANDER',
    text: 'COROMORO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68229,
    nombreDepartamento: 'SANTANDER',
    text: 'CURITÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68235,
    nombreDepartamento: 'SANTANDER',
    text: 'EL CARMEN DE CHUCURI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68245,
    nombreDepartamento: 'SANTANDER',
    text: 'EL GUACAMAYO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68250,
    nombreDepartamento: 'SANTANDER',
    text: 'EL PEÑÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68255,
    nombreDepartamento: 'SANTANDER',
    text: 'EL PLAYÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68264,
    nombreDepartamento: 'SANTANDER',
    text: 'ENCINO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68266,
    nombreDepartamento: 'SANTANDER',
    text: 'ENCISO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68271,
    nombreDepartamento: 'SANTANDER',
    text: 'FLORIÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68276,
    nombreDepartamento: 'SANTANDER',
    text: 'FLORIDABLANCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68296,
    nombreDepartamento: 'SANTANDER',
    text: 'GALÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68298,
    nombreDepartamento: 'SANTANDER',
    text: 'GÁMBITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68307,
    nombreDepartamento: 'SANTANDER',
    text: 'GIRÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68318,
    nombreDepartamento: 'SANTANDER',
    text: 'GUACA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68320,
    nombreDepartamento: 'SANTANDER',
    text: 'GUADALUPE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68322,
    nombreDepartamento: 'SANTANDER',
    text: 'GUAPOTÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68324,
    nombreDepartamento: 'SANTANDER',
    text: 'GUAVATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68327,
    nombreDepartamento: 'SANTANDER',
    text: 'GÜEPSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68344,
    nombreDepartamento: 'SANTANDER',
    text: 'HATO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68368,
    nombreDepartamento: 'SANTANDER',
    text: 'JESÚS MARÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68370,
    nombreDepartamento: 'SANTANDER',
    text: 'JORDÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68377,
    nombreDepartamento: 'SANTANDER',
    text: 'LA BELLEZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68385,
    nombreDepartamento: 'SANTANDER',
    text: 'LANDÁZURI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68397,
    nombreDepartamento: 'SANTANDER',
    text: 'LA PAZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68406,
    nombreDepartamento: 'SANTANDER',
    text: 'LEBRIJA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68418,
    nombreDepartamento: 'SANTANDER',
    text: 'LOS SANTOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68425,
    nombreDepartamento: 'SANTANDER',
    text: 'MACARAVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68432,
    nombreDepartamento: 'SANTANDER',
    text: 'MÁLAGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68444,
    nombreDepartamento: 'SANTANDER',
    text: 'MATANZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68464,
    nombreDepartamento: 'SANTANDER',
    text: 'MOGOTES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68468,
    nombreDepartamento: 'SANTANDER',
    text: 'MOLAGAVITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68498,
    nombreDepartamento: 'SANTANDER',
    text: 'OCAMONTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68500,
    nombreDepartamento: 'SANTANDER',
    text: 'OIBA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68502,
    nombreDepartamento: 'SANTANDER',
    text: 'ONZAGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68522,
    nombreDepartamento: 'SANTANDER',
    text: 'PALMAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68524,
    nombreDepartamento: 'SANTANDER',
    text: 'PALMAS DEL SOCORRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68533,
    nombreDepartamento: 'SANTANDER',
    text: 'PÁRAMO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68547,
    nombreDepartamento: 'SANTANDER',
    text: 'PIEDECUESTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68549,
    nombreDepartamento: 'SANTANDER',
    text: 'PINCHOTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68572,
    nombreDepartamento: 'SANTANDER',
    text: 'PUENTE NACIONAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68573,
    nombreDepartamento: 'SANTANDER',
    text: 'PUERTO PARRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68575,
    nombreDepartamento: 'SANTANDER',
    text: 'PUERTO WILCHES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68615,
    nombreDepartamento: 'SANTANDER',
    text: 'RIONEGRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68655,
    nombreDepartamento: 'SANTANDER',
    text: 'SABANA DE TORRES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68669,
    nombreDepartamento: 'SANTANDER',
    text: 'SAN ANDRÉS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68673,
    nombreDepartamento: 'SANTANDER',
    text: 'SAN BENITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68679,
    nombreDepartamento: 'SANTANDER',
    text: 'SAN GIL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68682,
    nombreDepartamento: 'SANTANDER',
    text: 'SAN JOAQUÍN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68684,
    nombreDepartamento: 'SANTANDER',
    text: 'SAN JOSÉ DE MIRANDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68686,
    nombreDepartamento: 'SANTANDER',
    text: 'SAN MIGUEL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68689,
    nombreDepartamento: 'SANTANDER',
    text: 'SAN VICENTE DE CHUCURÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 6875,
    nombreDepartamento: 'SANTANDER',
    text: 'SANTA BÁRBARA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68720,
    nombreDepartamento: 'SANTANDER',
    text: 'SANTA HELENA DEL OPÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68745,
    nombreDepartamento: 'SANTANDER',
    text: 'SIMACOTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68755,
    nombreDepartamento: 'SANTANDER',
    text: 'SOCORRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68770,
    nombreDepartamento: 'SANTANDER',
    text: 'SUAITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68773,
    nombreDepartamento: 'SANTANDER',
    text: 'SUCRE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68780,
    nombreDepartamento: 'SANTANDER',
    text: 'SURATÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68820,
    nombreDepartamento: 'SANTANDER',
    text: 'TONA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68855,
    nombreDepartamento: 'SANTANDER',
    text: 'VALLE DE SAN JOSÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68861,
    nombreDepartamento: 'SANTANDER',
    text: 'VÉLEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68867,
    nombreDepartamento: 'SANTANDER',
    text: 'VETAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68872,
    nombreDepartamento: 'SANTANDER',
    text: 'VILLANUEVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 68,
    value: 68895,
    nombreDepartamento: 'SANTANDER',
    text: 'ZAPATOCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70001,
    nombreDepartamento: 'SUCRE',
    text: 'SINCELEJO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70110,
    nombreDepartamento: 'SUCRE',
    text: 'BUENAVISTA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70124,
    nombreDepartamento: 'SUCRE',
    text: 'CAIMITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70204,
    nombreDepartamento: 'SUCRE',
    text: 'COLOSÓ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70215,
    nombreDepartamento: 'SUCRE',
    text: 'COROZAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70221,
    nombreDepartamento: 'SUCRE',
    text: 'COVEÑAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70230,
    nombreDepartamento: 'SUCRE',
    text: 'CHALÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70233,
    nombreDepartamento: 'SUCRE',
    text: 'EL ROBLE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70235,
    nombreDepartamento: 'SUCRE',
    text: 'GALERAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70265,
    nombreDepartamento: 'SUCRE',
    text: 'GUARANDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70400,
    nombreDepartamento: 'SUCRE',
    text: 'LA UNIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70418,
    nombreDepartamento: 'SUCRE',
    text: 'LOS PALMITOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70429,
    nombreDepartamento: 'SUCRE',
    text: 'MAJAGUAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70473,
    nombreDepartamento: 'SUCRE',
    text: 'MORROA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 758,
    nombreDepartamento: 'SUCRE',
    text: 'OVEJAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 7523,
    nombreDepartamento: 'SUCRE',
    text: 'PALMITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70670,
    nombreDepartamento: 'SUCRE',
    text: 'SAMPUÉS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70678,
    nombreDepartamento: 'SUCRE',
    text: 'SAN BENITO ABAD',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70702,
    nombreDepartamento: 'SUCRE',
    text: 'SAN JUAN DE BETULIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 7078,
    nombreDepartamento: 'SUCRE',
    text: 'SAN MARCOS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70713,
    nombreDepartamento: 'SUCRE',
    text: 'SAN ONOFRE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70717,
    nombreDepartamento: 'SUCRE',
    text: 'SAN PEDRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70742,
    nombreDepartamento: 'SUCRE',
    text: 'SAN LUIS DE SINCÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 70771,
    nombreDepartamento: 'SUCRE',
    text: 'SUCRE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 7820,
    nombreDepartamento: 'SUCRE',
    text: 'SANTIAGO DE TOLÚ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 70,
    value: 7823,
    nombreDepartamento: 'SUCRE',
    text: 'SAN JOSÉ DE TOLUVIEJO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73001,
    nombreDepartamento: 'TOLIMA',
    text: 'IBAGUÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73024,
    nombreDepartamento: 'TOLIMA',
    text: 'ALPUJARRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73026,
    nombreDepartamento: 'TOLIMA',
    text: 'ALVARADO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73030,
    nombreDepartamento: 'TOLIMA',
    text: 'AMBALEMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73043,
    nombreDepartamento: 'TOLIMA',
    text: 'ANZOÁTEGUI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 7355,
    nombreDepartamento: 'TOLIMA',
    text: 'ARMERO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73067,
    nombreDepartamento: 'TOLIMA',
    text: 'ATACO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73124,
    nombreDepartamento: 'TOLIMA',
    text: 'CAJAMARCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73148,
    nombreDepartamento: 'TOLIMA',
    text: 'CARMEN DE APICALÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73152,
    nombreDepartamento: 'TOLIMA',
    text: 'CASABIANCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73168,
    nombreDepartamento: 'TOLIMA',
    text: 'CHAPARRAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73200,
    nombreDepartamento: 'TOLIMA',
    text: 'COELLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73217,
    nombreDepartamento: 'TOLIMA',
    text: 'COYAIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73226,
    nombreDepartamento: 'TOLIMA',
    text: 'CUNDAY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73236,
    nombreDepartamento: 'TOLIMA',
    text: 'DOLORES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73268,
    nombreDepartamento: 'TOLIMA',
    text: 'ESPINAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73270,
    nombreDepartamento: 'TOLIMA',
    text: 'FALAN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73275,
    nombreDepartamento: 'TOLIMA',
    text: 'FLANDES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73283,
    nombreDepartamento: 'TOLIMA',
    text: 'FRESNO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73319,
    nombreDepartamento: 'TOLIMA',
    text: 'GUAMO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73347,
    nombreDepartamento: 'TOLIMA',
    text: 'HERVEO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73349,
    nombreDepartamento: 'TOLIMA',
    text: 'HONDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73352,
    nombreDepartamento: 'TOLIMA',
    text: 'ICONONZO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 7348,
    nombreDepartamento: 'TOLIMA',
    text: 'LÉRIDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73411,
    nombreDepartamento: 'TOLIMA',
    text: 'LÍBANO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73443,
    nombreDepartamento: 'TOLIMA',
    text: 'SAN SEBASTIÁN DE MARIQUITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73449,
    nombreDepartamento: 'TOLIMA',
    text: 'MELGAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73461,
    nombreDepartamento: 'TOLIMA',
    text: 'MURILLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73483,
    nombreDepartamento: 'TOLIMA',
    text: 'NATAGAIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73504,
    nombreDepartamento: 'TOLIMA',
    text: 'ORTEGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73520,
    nombreDepartamento: 'TOLIMA',
    text: 'PALOCABILDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73547,
    nombreDepartamento: 'TOLIMA',
    text: 'PIEDRAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73555,
    nombreDepartamento: 'TOLIMA',
    text: 'PLANADAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73563,
    nombreDepartamento: 'TOLIMA',
    text: 'PRADO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73585,
    nombreDepartamento: 'TOLIMA',
    text: 'PURIFICACIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73616,
    nombreDepartamento: 'TOLIMA',
    text: 'RIOBLANCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73622,
    nombreDepartamento: 'TOLIMA',
    text: 'RONCESVALLES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73624,
    nombreDepartamento: 'TOLIMA',
    text: 'ROVIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73671,
    nombreDepartamento: 'TOLIMA',
    text: 'SALDAÑA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73675,
    nombreDepartamento: 'TOLIMA',
    text: 'SAN ANTONIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73678,
    nombreDepartamento: 'TOLIMA',
    text: 'SAN LUIS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73686,
    nombreDepartamento: 'TOLIMA',
    text: 'SANTA ISABEL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73770,
    nombreDepartamento: 'TOLIMA',
    text: 'SUÁREZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73854,
    nombreDepartamento: 'TOLIMA',
    text: 'VALLE DE SAN JUAN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73861,
    nombreDepartamento: 'TOLIMA',
    text: 'VENADILLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73870,
    nombreDepartamento: 'TOLIMA',
    text: 'VILLAHERMOSA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 73,
    value: 73873,
    nombreDepartamento: 'TOLIMA',
    text: 'VILLARRICA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76001,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'CALI',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76020,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'ALCALÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76036,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'ANDALUCÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76041,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'ANSERMANUEVO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 7654,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'ARGELIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76100,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'BOLÍVAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76109,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'BUENAVENTURA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76111,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'GUADALAJARA DE BUGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76113,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'BUGALAGRANDE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76122,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'CAICEDONIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76126,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'CALIMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76130,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'CANDELARIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76147,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'CARTAGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76233,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'DAGUA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76243,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'EL ÁGUILA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76246,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'EL CAIRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76248,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'EL CERRITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76250,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'EL DOVIO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76275,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'FLORIDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76306,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'GINEBRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76318,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'GUACARÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76364,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'JAMUNDÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76377,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'LA CUMBRE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76400,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'LA UNIÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76403,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'LA VICTORIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76497,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'OBANDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76520,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'PALMIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76563,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'PRADERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76606,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'RESTREPO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76616,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'RIOFRÍO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76622,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'ROLDANILLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76670,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'SAN PEDRO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76736,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'SEVILLA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76823,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'TORO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76828,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'TRUJILLO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76834,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'TULUÁ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76845,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'ULLOA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76863,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'VERSALLES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76869,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'VIJES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76890,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'YOTOCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76892,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'YUMBO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 76,
    value: 76895,
    nombreDepartamento: 'VALLE DEL CAUCA',
    text: 'ZARZAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 81,
    value: 81001,
    nombreDepartamento: 'ARAUCA',
    text: 'ARAUCA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 81,
    value: 81065,
    nombreDepartamento: 'ARAUCA',
    text: 'ARAUQUITA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 81,
    value: 81220,
    nombreDepartamento: 'ARAUCA',
    text: 'CRAVO NORTE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 81,
    value: 81300,
    nombreDepartamento: 'ARAUCA',
    text: 'FORTUL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 81,
    value: 81591,
    nombreDepartamento: 'ARAUCA',
    text: 'PUERTO RONDÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 81,
    value: 81736,
    nombreDepartamento: 'ARAUCA',
    text: 'SARAVENA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 81,
    value: 81794,
    nombreDepartamento: 'ARAUCA',
    text: 'TAME',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85001,
    nombreDepartamento: 'CASANARE',
    text: 'YOPAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85010,
    nombreDepartamento: 'CASANARE',
    text: 'AGUAZUL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85015,
    nombreDepartamento: 'CASANARE',
    text: 'CHÁMEZA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85125,
    nombreDepartamento: 'CASANARE',
    text: 'HATO COROZAL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85136,
    nombreDepartamento: 'CASANARE',
    text: 'LA SALINA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85139,
    nombreDepartamento: 'CASANARE',
    text: 'MANÍ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85162,
    nombreDepartamento: 'CASANARE',
    text: 'MONTERREY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85225,
    nombreDepartamento: 'CASANARE',
    text: 'NUNCHÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85230,
    nombreDepartamento: 'CASANARE',
    text: 'OROCUÉ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85250,
    nombreDepartamento: 'CASANARE',
    text: 'PAZ DE ARIPORO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85263,
    nombreDepartamento: 'CASANARE',
    text: 'PORE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85279,
    nombreDepartamento: 'CASANARE',
    text: 'RECETOR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85300,
    nombreDepartamento: 'CASANARE',
    text: 'SABANALARGA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85315,
    nombreDepartamento: 'CASANARE',
    text: 'SÁCAMA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85325,
    nombreDepartamento: 'CASANARE',
    text: 'SAN LUIS DE PALENQUE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85400,
    nombreDepartamento: 'CASANARE',
    text: 'TÁMARA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85410,
    nombreDepartamento: 'CASANARE',
    text: 'TAURAMENA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85430,
    nombreDepartamento: 'CASANARE',
    text: 'TRINIDAD',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 85,
    value: 85440,
    nombreDepartamento: 'CASANARE',
    text: 'VILLANUEVA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86001,
    nombreDepartamento: 'PUTUMAYO',
    text: 'MOCOA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86219,
    nombreDepartamento: 'PUTUMAYO',
    text: 'COLÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86320,
    nombreDepartamento: 'PUTUMAYO',
    text: 'ORITO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86568,
    nombreDepartamento: 'PUTUMAYO',
    text: 'PUERTO ASÍS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86569,
    nombreDepartamento: 'PUTUMAYO',
    text: 'PUERTO CAICEDO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86571,
    nombreDepartamento: 'PUTUMAYO',
    text: 'PUERTO GUZMÁN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86573,
    nombreDepartamento: 'PUTUMAYO',
    text: 'PUERTO LEGUÍZAMO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86749,
    nombreDepartamento: 'PUTUMAYO',
    text: 'SIBUNDOY',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86755,
    nombreDepartamento: 'PUTUMAYO',
    text: 'SAN FRANCISCO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86757,
    nombreDepartamento: 'PUTUMAYO',
    text: 'SAN MIGUEL',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86760,
    nombreDepartamento: 'PUTUMAYO',
    text: 'SANTIAGO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86865,
    nombreDepartamento: 'PUTUMAYO',
    text: 'VALLE DEL GUAMUEZ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 86,
    value: 86885,
    nombreDepartamento: 'PUTUMAYO',
    text: 'VILLAGARZÓN',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 88,
    value: 88001,
    nombreDepartamento:
      'ARCHIPIÉLAGO DE SAN ANDRÉS. PROVIDENCIA Y SANTA CATALINA',
    text: 'SAN ANDRÉS',
    tipo: 'Isla',
  },
  {
    codigoDepartamento: 88,
    value: 88564,
    nombreDepartamento:
      'ARCHIPIÉLAGO DE SAN ANDRÉS. PROVIDENCIA Y SANTA CATALINA',
    text: 'PROVIDENCIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 91,
    value: 91001,
    nombreDepartamento: 'AMAZONAS',
    text: 'LETICIA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 91,
    value: 91263,
    nombreDepartamento: 'AMAZONAS',
    text: 'EL ENCANTO',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 91,
    value: 9145,
    nombreDepartamento: 'AMAZONAS',
    text: 'LA CHORRERA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 91,
    value: 91407,
    nombreDepartamento: 'AMAZONAS',
    text: 'LA PEDRERA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 91,
    value: 91430,
    nombreDepartamento: 'AMAZONAS',
    text: 'LA VICTORIA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 91,
    value: 91460,
    nombreDepartamento: 'AMAZONAS',
    text: 'MIRITÍ - PARANÁ',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 91,
    value: 91530,
    nombreDepartamento: 'AMAZONAS',
    text: 'PUERTO ALEGRÍA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 91,
    value: 91536,
    nombreDepartamento: 'AMAZONAS',
    text: 'PUERTO ARICA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 91,
    value: 91540,
    nombreDepartamento: 'AMAZONAS',
    text: 'PUERTO NARIÑO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 91,
    value: 91669,
    nombreDepartamento: 'AMAZONAS',
    text: 'PUERTO SANTANDER',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 91,
    value: 91798,
    nombreDepartamento: 'AMAZONAS',
    text: 'TARAPACÁ',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 94,
    value: 94001,
    nombreDepartamento: 'GUAINÍA',
    text: 'INÍRIDA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 94,
    value: 94343,
    nombreDepartamento: 'GUAINÍA',
    text: 'BARRANCOMINAS',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 94,
    value: 94883,
    nombreDepartamento: 'GUAINÍA',
    text: 'SAN FELIPE',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 94,
    value: 94884,
    nombreDepartamento: 'GUAINÍA',
    text: 'PUERTO COLOMBIA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 94,
    value: 94885,
    nombreDepartamento: 'GUAINÍA',
    text: 'LA GUADALUPE',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 94,
    value: 94886,
    nombreDepartamento: 'GUAINÍA',
    text: 'CACAHUAL',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 94,
    value: 94887,
    nombreDepartamento: 'GUAINÍA',
    text: 'PANA PANA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 94,
    value: 94888,
    nombreDepartamento: 'GUAINÍA',
    text: 'MORICHAL',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 95,
    value: 95001,
    nombreDepartamento: 'GUAVIARE',
    text: 'SAN JOSÉ DEL GUAVIARE',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 95,
    value: 95015,
    nombreDepartamento: 'GUAVIARE',
    text: 'CALAMAR',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 95,
    value: 95025,
    nombreDepartamento: 'GUAVIARE',
    text: 'EL RETORNO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 95,
    value: 95200,
    nombreDepartamento: 'GUAVIARE',
    text: 'MIRAFLORES',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 97,
    value: 97001,
    nombreDepartamento: 'VAUPÉS',
    text: 'MITÚ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 97,
    value: 97161,
    nombreDepartamento: 'VAUPÉS',
    text: 'CARURÚ',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 97,
    value: 97511,
    nombreDepartamento: 'VAUPÉS',
    text: 'PACOA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 97,
    value: 97666,
    nombreDepartamento: 'VAUPÉS',
    text: 'TARAIRA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 97,
    value: 97777,
    nombreDepartamento: 'VAUPÉS',
    text: 'PAPUNAHUA',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 97,
    value: 97889,
    nombreDepartamento: 'VAUPÉS',
    text: 'YAVARATÉ',
    tipo: 'Área no municipalizada',
  },
  {
    codigoDepartamento: 99,
    value: 99001,
    nombreDepartamento: 'VICHADA',
    text: 'PUERTO CARREÑO',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 99,
    value: 99524,
    nombreDepartamento: 'VICHADA',
    text: 'LA PRIMAVERA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 99,
    value: 99624,
    nombreDepartamento: 'VICHADA',
    text: 'SANTA ROSALÍA',
    tipo: 'Municipio',
  },
  {
    codigoDepartamento: 99,
    value: 99773,
    nombreDepartamento: 'VICHADA',
    text: 'CUMARIBO',
    tipo: 'Municipio',
  },
];
