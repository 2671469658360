import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emitData } from '../../data-access/interface/emitData.interface';
import { cityCode, cityCodes } from '../../utils/codesData/cityCodes';
import { clientTypes } from '../../utils/codesData/clientTypes';
import { identificationTypes } from '../../utils/codesData/identificationTypes';
import { stateCodes } from '../../utils/codesData/stateCodes';

@Component({
	selector: 'app-account-form',
	templateUrl: './account-form.component.html',
	styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit {
	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

	private fb = inject(FormBuilder);

	public form = this.fb.nonNullable.group({
		name: ['', [Validators.required]],
		nit: ['', [Validators.required]],
		type: ['', [Validators.required]],
		state: ['', [Validators.required]],
		city: ['', [Validators.required]],
		country: ['COL']
	});

	istSearchAccounts: boolean;

	formAccount: FormGroup;

	//information burned
	departments = stateCodes;
	municipalities = cityCodes;
	municipalitiesDepartment: cityCode[];
	identificationType = identificationTypes;
	typeAccount = clientTypes;

	ngOnInit(): void {
		this.form.controls.state.valueChanges.subscribe((value) => {
			this.filterCity(Number(value));
		});
	}

	createAccount() {
		this.emitAction.emit({
			action: 'create account',
			values: this.form.value
		});

		this.formAccount.reset;
	}

	cancel() {
		this.emitAction.emit({
			action: 'cancel',
			values: null
		});
	}

	filterCity(idState: number) {
		this.municipalitiesDepartment = this.municipalities.filter((obj) => {
			return obj.codigoDepartamento === idState;
		});
	}
}
