import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-ticket-list-item',
	templateUrl: './ticket-list-item.component.html',
	styleUrls: ['./ticket-list-item.component.scss']
})
export class TicketListItemComponent {
	@Input() supportT: string;
	@Input() description: string;
	@Input() ticketT: string;

  public activePanelIds: string[] = [];
}
