import { Languages } from '../data-access/language.interface';

export const LANG: Languages[] = [
	{
		id: 'en',
		label: 'English',
		icon: 'US',
		title: 'us'
	},
	{
		id: 'es',
		label: 'Español',
		icon: 'ES',
		title: 'es'
	},
	{
		id: 'pt',
		label: 'Português',
		icon: 'PT',
		title: 'pt'
	}
];
