<div class="pt-4">

  <div style="text-align: right;" class="flex-wrap mb-3">
        <p-button
          (click)="createActivity('tasks')"
          icon="pi pi-list-check" size="small"
          class="mr-2"
          pTooltip="{{ 'Integration.ZohoCRM.New task' | translate }}"
        />
        <p-button
          (click)="createActivity('events')"
          pTooltip="{{ 'Integration.ZohoCRM.New event' | translate }}"
          icon="pi pi-calendar-clock" size="small"
          class="mr-2"
        />
        <!-- label="{{ 'Integration.ZohoCRM.New event' | translate }}"-->
      <p-button
        (click)="createActivity('calls')"
        pTooltip="{{ 'Integration.ZohoCRM.New call' | translate }}"
        icon="pi pi-phone" size="small"
        class="mr-2"
      />
  </div>

<div #childActivitiesComponents></div>

</div>
