import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-input',
  template: `
    <div class="flex flex-column gap-1">
      <label style="color: #7A7F9A; font-size: 12px; font-weight: 600;">{{ props['display_label'] }}</label>
      <p-multiSelect
        class="w-full"
        id="MultiSelectFieldType"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [options]="values"
        [filter]="false"
      ></p-multiSelect>
    </div>
  `,
})
export class MultiSelectFieldType extends FieldType<FieldTypeConfig> implements OnInit {
  public values: any[] = [];

  ngOnInit() {
    this.props.options?.forEach((element) => {
      this.values.push(element['name']);
    });
  }

  get type() {
    return this.props.type || 'text';
  }
}
