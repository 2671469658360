import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public translate = inject(TranslateService);

  constructor() { }

  showMessage(message: string, type: SweetAlertIcon, custom: boolean): void {
    const msg = (custom ? this.translate.instant('Integration.ZohoCRM.' + message) : message);
    Swal.fire({
      icon: type,
      title: msg,
      position: 'top-end',
      width: '430px;',
      toast: true,
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true
    });
  }

}
