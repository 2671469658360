<p-card *ngIf="!loading" [style]="{ padding: '15px'}">
  <ng-template pTemplate="header">
    <div class="row">
      <div class="col-9">
        <div class="col-md-6-custom text-left">
          <span class="text-secondary text-md">
            {{ 'Integration.ZohoCRM.Ticket details' | translate }}
          </span>
        </div>
      </div>
      <div class="col-3">
        <div style="text-align: right;">
          <i (click)="update()" pTooltip="{{ 'Integration.ZohoCRM.Update' | translate }}"
          style="cursor: pointer" class="pi pi-refresh mr-2"></i>

          <i (click)="cancel()" pTooltip="{{ 'Integration.ZohoCRM.Close' | translate }}"
            style="cursor: pointer" class="pi pi-times"></i>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="flex justify-content-between gap-5">
    <div class="flex flex-column gap-1">
        <span class="font-bold text-lg">{{  ticket.subject }}</span>
        <span class="text-secondary text-sm"> #{{ ticket.ticketNumber }}</span>
    </div>
    <span class="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" >
        <p-badge [value]="ticket.statusType" severity="success" />
    </span>
  </div>

  <div class="pt-4">
    <p-accordion [activeIndex]="0">
      <p-accordionTab header="{{ 'Integration.ZohoCRM.Basic information' | translate }}">
        <table class="table-tickets-details">
          <tr>
            <td class="row-details tlabel">Priority</td>
            <td class="tvalue">{{ ticket.priority }}</td>
          </tr>
          <tr>
            <td class="row-details tlabel">Status type</td>
            <td class="tvalue">{{ ticket.statusType }}</td>
          </tr>
          <tr>
            <td class="row-details tlabel">Status</td>
            <td class="tvalue">{{ ticket.status }}</td>
          </tr>
          <tr>
            <td class="row-details tlabel">Phone</td>
            <td class="tvalue">{{ ticket.phone }}</td>
          </tr>
          <tr>
            <td class="row-details tlabel">Email</td>
            <td class="tvalue">{{ ticket.email }}</td>
          </tr>
          <tr>
            <td class="row-details tlabel">Created</td>
            <td class="tvalue">{{ ticket.createdTimeFormated }}</td>
          </tr>
          <tr>
              <td class="row-details tlabel">Due date</td>
              <td class="tvalue">{{ ticket.dueDateFormated }}</td>
          </tr>
          <tr>
            <td class="row-details tlabel">Channel</td>
            <td class="tvalue">{{ ticket.channel }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Thread count</td>
          <td class="tvalue">{{ ticket.threadCount }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Shared count</td>
          <td class="tvalue">{{ ticket.sharedCount }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Approval count</td>
          <td class="tvalue">{{ ticket.approvalCount }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Time entry count</td>
          <td class="tvalue">{{ ticket.timeEntryCount }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Task count</td>
          <td class="tvalue">{{ ticket.taskCount }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Comment count</td>
          <td class="tvalue">{{ ticket.commentCount }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">On hold time</td>
          <td class="tvalue">{{ ticket.onholdTime }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Language</td>
          <td class="tvalue">{{ ticket.language }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Closed Time</td>
          <td class="tvalue">{{ ticket.closedTimeFormated }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Phone</td>
          <td class="tvalue">{{ ticket.phone }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Assignee</td>
          <td class="tvalue">
            <div *ngIf="ticket.assignee">
              <span>{{ ticket.assignee.firstName }} {{ ticket.assignee.lastName }}</span>
              <br/>
              <span>{{ ticket.assignee.email }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="row-details tlabel">Team</td>
          <td class="tvalue">
            <div *ngIf="ticket.team">
              <span>{{ ticket.team.name }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="row-details tlabel">Departament</td>
          <td class="tvalue">
            <div *ngIf="ticket.departament">
              <span>{{ ticket.departament.name }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="row-details tlabel">Subcategory</td>
          <td class="tvalue">{{ ticket.subCategory }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Is spam</td>
          <td class="tvalue">{{ ticket.isSpam }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Is read</td>
          <td class="tvalue">{{ ticket.isRead }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Customer response</td>
          <td class="tvalue">{{ ticket.customerResponseTimeFormated }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Close time</td>
          <td class="tvalue">{{ ticket.closedTimeFormated }}</td>
        </tr>
        <tr>
          <td class="row-details tlabel">Source</td>
          <td class="tvalue">
            <div *ngIf="ticket.source">
              <span>{{ ticket.source.type }}</span>
              <br />
              <span>{{ ticket.source.appName }}</span>
            </div>
          </td>
        </tr>

      </table>


      </p-accordionTab>

      <p-accordionTab header="{{ 'Integration.ZohoCRM.History' | translate }}">
        <app-tickets-details-history
          [ticketId]="ticketId"
          [updateCache]="updateCache"
        ></app-tickets-details-history>
      </p-accordionTab>

      <p-accordionTab header="{{ 'Integration.ZohoCRM.Resolutions' | translate }}">
        <app-tickets-details-resolution
          [ticketId]="ticketId"
          [updateCache]="updateCache"
        ></app-tickets-details-resolution>
      </p-accordionTab>

      <p-accordionTab header="{{ 'Integration.ZohoCRM.Metrics' | translate }}">
        <app-tickets-details-metrics
          [ticketId]="ticketId"
          [updateCache]="updateCache"
        ></app-tickets-details-metrics>
      </p-accordionTab>

      <p-accordionTab header="{{ 'Integration.ZohoCRM.Description' | translate }}">
        <app-tickets-details-description
          [description]="ticket.description"
        ></app-tickets-details-description>
      </p-accordionTab>


    </p-accordion>
  </div>


</p-card>

<div *ngIf="loading" class="spinner-border" role="status">
  <span class="visually-hidden"></span>
</div>
