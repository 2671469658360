import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoDeskBackendService } from '../../../data-access/zoho-desk-backend.service';

@Component({
  selector: 'app-tickets-details',
  templateUrl: './tickets-details.component.html',
  styleUrls: ['./tickets-details.component.scss']
})
export class TicketsDetailsComponent implements OnDestroy, OnInit {

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  private subscriptions = new Subscription();
  public ticketId?:  any;
  public ticket?:  any;
  updateCache = false;
  loading = false;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    this.loading = true;
    this.updateCache = false;
    setTimeout(() => {
      this.getTicketById(this.ticketId);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  cancel(): void {
    this.emitAction.emit({
      action: 'cancel',
      values: '',
      module: ''
    });
  }

  update(): void {
    this.loading = true;
    this.updateCache = true;
    this.getTicketById(this.ticketId);
  }

  getTicketById(id: string): void {
    const createRecord$ = this._ZohoDeskBackendService
    .getTicketById(id, this.updateCache)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.ticket = resp.body;
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
    });
  }

}
