import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Message } from '../interface/chat.interface';

@Injectable({
	providedIn: 'root'
})
export class MessagingHubService {
	private connection: HubConnection;
	private connectionState$: BehaviorSubject<HubConnectionState>;

	constructor(private _oidcSecurityService: OidcSecurityService, private httpClient: HttpClient) {
		this.connectionState$ = new BehaviorSubject<HubConnectionState>(HubConnectionState.Disconnected);

		this._oidcSecurityService.getAccessToken().subscribe((token) => {
			this.connection = new HubConnectionBuilder()
				.withUrl(environment.URL_API_SOCKET + '/console', {
					accessTokenFactory: () => token || ''
				})
				.withAutomaticReconnect([0, 2000, 5000, 10000]) // Backoff times for reconnection attempts
				.build();

			this.setupConnectionHandlers();
		});
	}

	private setupConnectionHandlers(): void {
		this.connection.onreconnecting((error) => {
			console.warn('Connection lost, attempting to reconnect...', error);
			this.connectionState$.next(HubConnectionState.Reconnecting);
		});

		this.connection.onreconnected((connectionId) => {
			console.log('Connection reestablished, connection ID:', connectionId);
			this.connectionState$.next(HubConnectionState.Connected);
		});

		this.connection.onclose((error) => {
			console.error('Connection closed:', error);
			this.connectionState$.next(HubConnectionState.Disconnected);
		});

		this.connection.on('ReceiveMessage', (message: Message) => {
			// Handle the received message here if needed
		});
	}

	startConnection(): Observable<void> {
		return new Observable<void>((observer) => {
			this.connection
				.start()
				.then(() => {
					this.connectionState$.next(HubConnectionState.Connected);
					observer.next();
					observer.complete();
				})
				.catch((error) => {
					observer.error(error);
				});
		});
	}

	stopConnection(): void {
		this.connection.stop().catch((error) => console.error('Error stopping connection:', error));
	}

	onReconnected(reconnectedCallback: (connectionId: string | undefined) => void): void {
		this.connection.onreconnected(reconnectedCallback);
	}

	onReceiveMessage(receiveMessageCallback: (message: Message) => void): void {
		this.connection.on('ReceiveMessage', receiveMessageCallback);
	}

	closeConversation(conversationId: string): void {
		this.connection
			.send('CloseConversation', conversationId)
			.catch((err) => console.error('Error closing conversation:', err));
	}

	onConversationClosed(conversationClosedCallback: (conversationId: string) => void): void {
		this.connection.on('ConversationClosed', conversationClosedCallback);
	}

	sendMessage(message: Message, setupId: string = ''): void {
		this.connection
			.send(
				'SendMessage',
				message.id,
				message.conversationId,
				setupId,
				message.from,
				message.to,
				message.contact,
				message.channel,
				message.messageType,
				message.message
			)
			.catch((err) => console.error('Error sending message:', err));
	}

	checkConnectionState(): HubConnectionState {
		return this.connection.state;
	}

	reconnectIfDisconnected(): Observable<any> {
		if (this.connection.state === HubConnectionState.Disconnected) {
			return this.startConnection();
		}
	}

	uploadFile(file: File, serviceInstanceId: string, setupId: string): Observable<any> {
		const url = environment.URL_API_SOCKET + '/v1/File/upload'; // URL del servicio backend que recibe el archivo

		const formData = new FormData();
		formData.append('file', file);
		formData.append('ServiceInstanceId', serviceInstanceId);
		formData.append('SetupId', setupId);

		const headers = new HttpHeaders();
		headers.append('Content-Type', 'multipart/form-data');

		const req = new HttpRequest('POST', url, formData, {
			reportProgress: true,
			headers: headers
		});

		return this.httpClient.request(req);
	}

	// Método para descargar un archivo
	downloadFile(id: string, serviceInstanceId?: string, setupId?: string): Observable<any> {
		// Configura los parámetros de la consulta
		let params = new HttpParams().set('serviceInstanceId', serviceInstanceId).set('setupId', setupId);

		return this.httpClient.get(`${environment.URL_API_SOCKET}/v1/File/${id}/download`, {
			params: params,
			responseType: 'blob' as 'json',
			reportProgress: true,
			observe: 'events'
		});
	}
}
