<form class="login" [formGroup]="form">
	<div class="flex flex-row justify-content-center gap-2">
		<img [src]="srcLogo" width="24" />
		<span class="title">Time to Change</span>
	</div>

	<div class="flex flex-column gap-2">
		<div class="flex flex-column gap-1">
			<span class="label">Email *</span>
			<app-text-field
				[valueField]="'spellty@brilla.com.co'"
				[icon]="'email'"
				[placeholder]="'Enter your email' | translate"
				(emitValue)="handleSetValue('usuario', $event)"
			></app-text-field>
		</div>

		<div class="flex flex-column gap-1">
			<span class="label">Password *</span>
			<app-text-field
				[valueField]="'arqu'"
				[icon]="'lock'"
				[placeholder]="'Enter your password' | translate"
				(emitValue)="handleSetValue('password', $event)"
			></app-text-field>
		</div>
	</div>

	<p-button class="w-12" [label]="'Login' | translate" (click)="login()" />

  <span class="forgot-your-password text-center">{{'Forgot your password?' | translate}}</span>
</form>
