export interface IIntegrationConfigZohoCrm {
  showOnlyRequiredFields?: boolean;
  modules?: string;
  layouts?: LayoutIntegrationConfigCrm[];
}

export class IntegrationConfigZohoCrm implements IIntegrationConfigZohoCrm {
  constructor(
    public showOnlyRequiredFields?: boolean,
    public modules?: string,
    public layouts?: LayoutIntegrationConfigCrm[],
  ) {}
}

export interface ILayoutIntegrationConfigCrm {
  module?: string;
	layoutId?: string;
  layoutName?: string;
}

export class LayoutIntegrationConfigCrm implements ILayoutIntegrationConfigCrm {
  constructor(
    public module?: string,
    public layoutId?: string,
    public layoutName?: string
  ) {}
}
