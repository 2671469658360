import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MenuItem } from 'primeng/api';
import { Subscription, interval } from 'rxjs';
import { MessagingHubService } from 'src/app/chat/data-access/services/messaging-hub.service';
import { LoginService } from 'src/app/shared/data-access/login.service';
import { CustomerState } from 'src/app/shared/states/customer.state';
import Swal from 'sweetalert2';
import { Languages } from '../../data-access/language.interface';
import { MenuItemI, SetupI } from '../../data-access/menu.interface';
import { LANG } from '../../utils/languages';
import { MENU, returnMenuItems } from '../../utils/menu';
import { environment } from 'src/environments/environment';
@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
	public translate = inject(TranslateService);
	private _router = inject(Router);
	private _oidcSecurityService = inject(OidcSecurityService);
	private messagingService = inject(MessagingHubService);
	public store = inject(Store);
	public loginService = inject(LoginService);

	public menuItems: MenuItemI[] = [];
  public menuApps = this.getMenuApps();

	public mode: string | undefined = 'light';

	public language: Languages;
	public languageId: string;
	public listLang: Languages[] = [];

	public fullName: string = '';
	private customerId: string = '';
	public isInfo: boolean = false;
	public numberInstances: number = 0;
	public itemsProfile: MenuItem[] | undefined = [
		{
			items: [
				{
					label: 'logout',
					icon: 'ri-logout-circle-r-line'
				}
			]
		}
	];

	private connectionCheckSubscription: Subscription;
	public isConnected = false;
	private timeSecondsCheckOutConnection = 30;

	ngOnInit(): void {
		(document.querySelector('.logo-light') as HTMLElement).style.display = 'block';
		(document.querySelector('.logo-dark') as HTMLElement).style.display = 'none';

		this.initializeLanguage();
		this.setDataUser();
		this.startConnection();

		this.scheduleConnectionCheck();
	}

	ngOnDestroy(): void {
		if (this.connectionCheckSubscription) {
			this.connectionCheckSubscription.unsubscribe();
		}
	}

  // Get Menu Options
	private getMenuApps(): any[] {
		return [
			{ label: 'Spellty portal', image: 'Spellty.svg', url: environment.SPELLTY_PORTAL },
			{ label: 'Spellty campaign', image: 'logo-spellty-campaign.svg', url: environment.SPELLTY_CAMPAIGN },
		];
	}

	private initializeLanguage(): void {
		this.menuItems = returnMenuItems();
		this.listLang = LANG;
		this.language = LANG.filter((lang) => lang.id == this.translate.currentLang)[0];
	}

	private setDataUser(): void {
		this.loginService.isLoadingInfo$.subscribe((loadingUserInfo) => {
			if (loadingUserInfo) {
				this.customerId = this.store.selectSnapshot(CustomerState.getCustomer)['sub'];
				this.fullName = localStorage.getItem('userName');
				this.isInfo = true;
			}
		});
	}

	private startConnection(): void {
		this.messagingService.startConnection().subscribe({
			next: () => {
				this.isConnected = true;
				this.showConnectionSuccess();
			},
			error: (err) => {
				this.isConnected = false;
				this.showConnectionError();
			}
		});
	}

	private scheduleConnectionCheck(): void {
		this.connectionCheckSubscription = interval(this.timeSecondsCheckOutConnection * 1000).subscribe(() =>
			this.checkConnection()
		);
	}

	private checkConnection(): void {
		if (this.messagingService.checkConnectionState() !== 'Connected') {
			this.messagingService.reconnectIfDisconnected().subscribe({
				next: () => {
					this.isConnected = true;
					this.showConnectionSuccess('You’re back online');
				},
				error: (err) => {
					this.isConnected = false;
					this.showConnectionError();
				}
			});
		}
	}

	private showConnectionError(): void {
		Swal.fire({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 4000,
			timerProgressBar: true,
			icon: 'error',
			title: this.translate.instant('Connection lost')
		});
	}

	private showConnectionSuccess(message: string = 'You are connected'): void {
		Swal.fire({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 4000,
			timerProgressBar: true,
			icon: 'success',
			title: this.translate.instant(message)
		});
	}

	navigateToChat(): void {
		this.menuItems[0].isSelected = true;
	}

	configSetups(data: any[]): SetupI[] {
		return data.map((item) => ({
			idSetup: item.SetupId,
			nameSetup: item.Name,
			channelSetup: item.Channel
		}));
	}

	navigateToModule(routerLink: MenuItemI, index: number, infoSetup: SetupI): void {
		this.menuItems = this.menuItems.map((data) => ({ ...data, isSelected: false }));
		this.menuItems[index].isSelected = true;

		this._router.navigate([routerLink.link], {
			queryParams: {
				...routerLink.queryParams,
				...infoSetup
			}
		});
	}

	changeLanguage(id: string) {
		this.translate.use(id);
		this.languageId = id;
		this.language = this.listLang.filter((lang) => lang.id == id)[0];
	}

	changeMode(mode: string) {
		this.mode = mode;
		switch (mode) {
			case 'light':
				(document.querySelector('.logo-light') as HTMLElement).style.display = 'block';
				(document.querySelector('.logo-dark') as HTMLElement).style.display = 'none';
				document.body.setAttribute('data-bs-theme', 'light');
				break;
			case 'dark':
				(document.querySelector('.logo-dark') as HTMLElement).style.display = 'block';
				(document.querySelector('.logo-light') as HTMLElement).style.display = 'none';
				document.body.setAttribute('data-bs-theme', 'dark');
				break;
			default:
				document.body.setAttribute('data-bs-theme', 'light');
				break;
		}
	}

	logout() {
		this._oidcSecurityService.logoff().subscribe();
	}
}
