import { DOCUMENT } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, Inject, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/app/shared/states/auth.state';
import { MessageType } from '../../data-access/interface/message.interface';
import { Chat } from '../../data-access/models/chat';
import { Message } from '../../data-access/models/message';
import { ConfiguratorService } from '../../data-access/services/configurator.service';
import { ConversationEventService } from '../../data-access/services/conversation-event.service';

import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Howl } from 'howler';
import { catchError, forkJoin, of } from 'rxjs';
import { ServiceInstanceService } from 'src/app/shared/data-access/service-instance.service';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { SetupChannelI } from '../../data-access/interface/configurator.interface';
import { ServiceInstanceManagerI } from '../../data-access/interface/service-instance-manager.interface';
import { TransferMessage } from '../../data-access/interface/transfer-message.interface';
import { MessagingHubService } from '../../data-access/services/messaging-hub.service';
import { TrafficService } from '../../data-access/services/traffic.service';
import {
	findChannelById,
	findConversationById,
	findExistConversationById,
	findObjectById,
	getIndexConversationById,
	getLastTimeStamp,
	removeConversationById,
	sortConversation
} from '../../utils/chat-method';

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
	@ViewChild('sucessfullTransfer') sucessfullTransfer: SwalComponent;

	@Inject(DOCUMENT) private document: Document;

	public translate = inject(TranslateService);
	private conversationEventService = inject(ConversationEventService);
	private messagingService = inject(MessagingHubService);
	private serviceInstanceService = inject(ServiceInstanceService);
	private configuratorService = inject(ConfiguratorService);
	private trafficService = inject(TrafficService);
	private sanitizer = inject(DomSanitizer);
	private store = inject(Store);
	private router = inject(Router);
	private securityService = inject(OidcSecurityService);

	public serviceInstancesData: IServiceInstance[] = [];
	public service;
	public message: string = '';
	public conversations: Chat[] = [];
	public selectedChat: Chat;
	public isOpenModalIntegration: boolean = true;
	public isLoading: boolean = false;
	public isChangedConversation: boolean = false;
	public limit: number = 10;
	public offset: number = 0;
	public isFetchingConversations: boolean = false;
	public stateSidebar = 'none';
	public isAuthenticated: boolean;
	public currentConversationId: string = '';
	public sonido: Howl;
	public soundNotification: string = 'true';
	public serviceInstanceId: string = '';
	public serviceInstanceName: string = '';
	public setupName: string = '';
	public isFinish: boolean = false;

	public dataChannelSetup: SetupChannelI[] = [];
	public dataChannelSetupSelected: SetupChannelI = null;
	public setupIdArray: string[] = [];
	public arrayServiceInstancesManager: ServiceInstanceManagerI[] = [];
	public serviceInstanceSelected: IServiceInstance = null;

	ngOnInit(): void {
		this.isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

		this.sonido = new Howl({
			src: ['../../../../assets/sound/notification_2.mp3'],
			volume: 0.9
		});
    this.soundNotification = localStorage.getItem('soundNotification')
    ? localStorage.getItem('soundNotification')
    : 'true';

		this.getServicesInstance();
		this.onReceiveMessage();
	}

	ngOnDestroy(): void {
		// this.messagingService.stopConnection();
		// this.conversations = [];
	}

	getServicesInstance(): void {
		this.serviceInstanceService.getServiceInstances(0).subscribe({
			next: (servicesInstances) => {
				if (servicesInstances.length === 0) {
					this.router.navigate(['/chat/no-channels']);
					return;
				}

				const userId = localStorage.getItem('userId');
				this.serviceInstancesData = servicesInstances.filter((item) =>
					item.config.Agents.WhiteList.some((agent) => agent.Id === userId)
				);

				// Recopilar todos los observables en un solo array
				const requests: any = servicesInstances.flatMap((servicesInstance) =>
					servicesInstance.config.Setups.map((setupInfo) =>
						this.configuratorService.getInformationConfig(setupInfo.SetupId).pipe(
							catchError((error) => of(null)) // Manejar errores devolviendo null en caso de error
						)
					)
				);

				// Ejecutar todas las solicitudes en paralelo usando forkJoin
				forkJoin(requests).subscribe({
					next: (responses: any[]) => {
						// Manejar la respuesta exitosa de las solicitudes
						const successfulResponses = responses.filter((response) => response !== null); // Filtrar respuestas exitosas
						this.dataChannelSetup.push(...successfulResponses); // Agregar respuestas exitosas al array de datos
						this.setupIdArray = this.dataChannelSetup.map((data) => data.businessNumber);

						this.isFinish = true;

						this.getConversations();
					},
					error: (error: any) => {
						// Manejar errores de las solicitudes
						console.error('Error fetching users:', error); // Registrar el error en la consola
					}
				});
			},
			error: (err) => console.error('Observable emitted an error: ' + err)
		});
	}

	onReceiveMessage(): void {
		this.messagingService.onReceiveMessage((message: Message) => {

			if (this.soundNotification === 'true') this.sonido.play();

			if ([MessageType.Image, MessageType.Video, MessageType.Audio].includes(message.messageType)) {
				this.messagingService
					.downloadFile(message.message.id, this.serviceInstancesData[0].id, this.dataChannelSetupSelected.id)
					.subscribe((event) => {
						if (event.type === HttpEventType.Response) {
							message.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
						}
					});
			}
			var chat = this.conversations.find((c) => c.id == message.conversationId);

			const indexServiceManager: number = findObjectById(this.arrayServiceInstancesManager, message.to);
			const channelsByConfig = this.arrayServiceInstancesManager[indexServiceManager].config.channels;
			const indexChannel: number = findChannelById(channelsByConfig, message.to);
			const conversationsByChannel: Chat[] =
				this.arrayServiceInstancesManager[indexServiceManager].config.channels[indexChannel].conversations;

			if (!findExistConversationById(this.arrayServiceInstancesManager, message.conversationId)) {
				chat = new Chat(
					message.conversationId!,
					message.contact!,
					'',
					message.from!,
					message.to!,
					message,
					1,
					message.channel
				);

				this.arrayServiceInstancesManager[indexServiceManager].config.channels[indexChannel].conversations.push(chat);
				chat.setGroupedMessagesByDate();
			} else {
				chat.addMessage(message);
				chat.increaseUnSeenMsg();
				chat.setGroupedMessagesByDate();
			}

			this.setFullname();

			this.arrayServiceInstancesManager[indexServiceManager].config.channels[indexChannel].conversations =
				sortConversation(conversationsByChannel);
		});
	}

	getConversations() {
		this.conversations = [];

		this.trafficService.conversation(0, 10).subscribe((_) => {
			if (_.results?.length !== 0)
				_.results?.forEach((value, index, array) => {
					let messages: Message[] = [];

					value.messages?.forEach((messageItem, index, array) => {
						let message = new Message(
							messageItem.id!,
							value.id!,
							messageItem.message!,
							messageItem.receivedOn!,
							messageItem.messageType!,
							messageItem.to!,
							messageItem.from!,
							messageItem.contact!
						);

						if ([MessageType.Image, MessageType.Video, MessageType.Audio].includes(message.messageType)) {
							this.dataChannelSetupSelected = this.searchSetup(value.bussinessPhoneNumber);

							this.messagingService
								.downloadFile(message.message.id, this.serviceInstancesData[0].id, this.dataChannelSetupSelected.id)
								.subscribe((event) => {
									if (event.type === HttpEventType.Response) {
										message.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
									}
								});
						}

						messages.unshift(message);
					});

					let chat = new Chat(
						value.id!,
						value.customerPhoneNumber!,
						'',
						value.customerPhoneNumber!,
						value.bussinessPhoneNumber!,
						undefined,
						0,
						value.channel!,
						value.state
					);
					messages.forEach((value) => {
						chat.addMessage(value);
					});
					chat.setGroupedMessagesByDate();
					this.conversations.push(chat);
				});

			this.setFullname();

			this.conversations = this.conversations.sort((conversacionA, conversacionB) => {
				const lastTimeStampA = getLastTimeStamp(conversacionA);
				const lastTimeStampB = getLastTimeStamp(conversacionB);

				return lastTimeStampB - lastTimeStampA;
			});

			this.createObjectServiceInstanceManager();
			this.isFetchingConversations = false;
		});
	}

	createObjectServiceInstanceManager(): void {
		this.serviceInstancesData.forEach((dataServiceInstance) => {
			const { id, name, config } = dataServiceInstance;
			const { Integrations, Bots, Extentions, Agents, Setups } = config;

			const serviceInstanceManager = {
				id,
				name,
				config: {
					integrations: Integrations,
					bots: Bots,
					extensiones: Extentions,
					agents: Agents,
					channels: []
				}
			};

			Setups.forEach((setupInfo) => {
				const filteredItemsDataChannel = this.dataChannelSetup.filter((item) => setupInfo.SetupId === item.id);

				if (filteredItemsDataChannel.length > 0) {
					const [channelInfo] = filteredItemsDataChannel;
					const conversations = this.conversations.filter((chat) => chat.businessPhone === channelInfo.businessNumber);

					serviceInstanceManager.config.channels.push({
						channelInfo,
						conversations
					});
				}
			});

			this.arrayServiceInstancesManager.push(serviceInstanceManager);
		});
	}

	handleDataChanged(data: any) {
		document.querySelector('.user-chat').classList.add('user-chat-show');
		document.querySelector('.chat-welcome-section').classList.add('d-none');
		document.querySelector('.user-chat').classList.remove('d-none');
		// Do something with the emitted data
		let chat = findConversationById(this.arrayServiceInstancesManager, data.conversationId);
		chat!.unSeenMsg = 0;
		this.conversationEventService.emitEvent(chat);

		this.selectedChat = chat!;
		this.currentConversationId = data.conversationId;
		this.isChangedConversation = !this.isChangedConversation;

		this.dataChannelSetupSelected = this.searchSetup(this.selectedChat.businessPhone);

		const indexServiceInstance: number = getIndexConversationById(
			this.arrayServiceInstancesManager,
			this.currentConversationId
		);
		this.serviceInstanceSelected = this.serviceInstancesData[indexServiceInstance];
	}

	handleMessageSend(message: Message) {
		// Do something with the emitted data
		message.conversationId = this.selectedChat.id;
		message.channel = this.selectedChat.channel;
		message.from = this.dataChannelSetupSelected.businessNumber;
		this.selectedChat.setGroupedMessagesByDate();
		this.messagingService.sendMessage(message, this.dataChannelSetupSelected.id);
	}

	handleSearch(event: any): void {
		if (event.search) this.conversationSearch(event.search);
	}

	conversationSearch(searchValue: number) {
		this.conversations = [];
		if (!this.isFetchingConversations) {
			this.isFetchingConversations = true;
			this.getConversations();
		} else {
			this.trafficService.GetConversation(0, 10, searchValue).subscribe((result) => {
				this.conversations = [];
				result.results?.forEach((value) => {
					let messages: Message[] = [];
					value.messages?.forEach((messageItem) => {
						let message = new Message(
							messageItem.id!,
							value.id!,
							messageItem.message!,
							messageItem.receivedOn!,
							messageItem.messageType!,
							messageItem.to!,
							messageItem.from!,
							messageItem.contact!
						);
						if ([MessageType.Image, MessageType.Video, MessageType.Audio].includes(message.messageType)) {
							this.messagingService
								.downloadFile(message.message.id, this.serviceInstancesData[0].id, this.dataChannelSetupSelected.id)
								.subscribe((event) => {
									if (event.type === HttpEventType.Response) {
										message.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
									}
								});
						}

						messages.unshift(message);
					});

					let chat = new Chat(
						value.id!,
						value.customerPhoneNumber!,
						'',
						value.customerPhoneNumber!,
						value.bussinessPhoneNumber!,
						undefined,
						0,
						value.channel!,
						value.state
					);
					messages.forEach((value) => {
						chat.addMessage(value);
					});
					chat.setGroupedMessagesByDate();
					this.conversations.push(chat);
				});

				this.setFullname();

				this.createObjectServiceInstanceManager();
			});
		}
	}

	onChatPanelClicked() {
		if (this.stateSidebar === 'none') {
			(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'block';
			this.stateSidebar = 'block';
		} else {
			(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'none';
			this.stateSidebar = 'none';
		}
	}

	closeChatInfo(event: any) {
		(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'none';
		this.stateSidebar = 'none';
	}

	handleChatClosed(data: any) {
		this.messagingService.closeConversation(this.selectedChat.id);
		this.arrayServiceInstancesManager = removeConversationById(this.arrayServiceInstancesManager, this.selectedChat.id);
		var chat: Chat;
		this.selectedChat = chat!;
		(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'none';
		this.stateSidebar = 'none';
	}

	handleMessageLoading(data: any) {
		this.isLoading = true;
		this.offset = this.currentConversationId !== data.conversationId ? 10 : this.offset + 10;
		this.trafficService.message(this.offset, this.limit, data.conversationId).subscribe((resp) => {
			let messages: Message[] = [];
			resp.results?.forEach((messageItem, index, array) => {
				const message = new Message(
					messageItem.id!,
					data.conversationId,
					messageItem.message!,
					messageItem.receivedOn!,
					messageItem.messageType!,
					messageItem.to!,
					messageItem.from!,
					messageItem.contact!
				);

				if ([MessageType.Image, MessageType.Video, MessageType.Audio].includes(message.messageType)) {
					this.messagingService
						.downloadFile(message.message.id, this.serviceInstancesData[0].id, this.dataChannelSetupSelected.id)
						.subscribe((event) => {
							if (event.type === HttpEventType.Response) {
								message.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
							}
						});
				}

				messages.unshift(message);
			});

			if (this.selectedChat.messages.length + messages.length <= resp.pager?.count!) {
				messages.forEach((value) => {
					this.selectedChat.addMessage(value);
				});
				this.selectedChat.setGroupedMessagesByDate();
			}

			if (this.currentConversationId !== data.conversationId) {
				this.currentConversationId = data.conversationId;
			}
		});
		setTimeout(() => {
			this.isLoading = false;
		}, 500);
	}

	getNameContact(conversation: Chat): string {
		const contactName = conversation.messages.filter((message) => this.setupIdArray.includes(message.to));
		return contactName.length !== 0 ? contactName[0].contact : '';
	}

	setFullname(): void {
		this.conversations.forEach((conversation, index) => {
			const newFullName = this.getNameContact(conversation);

			if (newFullName) {
				conversation.fullName = newFullName;
			}
		});
	}

	handleTransfer(object: TransferMessage): void {
		const body = {
			to: object.idUser,
			adapter: object.adapter,
			initialData: object.body
		};

		this.trafficService.transferConversation(this.selectedChat.id, body).subscribe({
			next: (_) => {
				this.arrayServiceInstancesManager = removeConversationById(
					this.arrayServiceInstancesManager,
					this.selectedChat.id
				);
				this.selectedChat = null;
				this.sucessfullTransfer.fire();
				this.conversationEventService.emitEventTransfer(true);
			},
			error: (err) => console.error('Observable emitted an error: ', err)
		});
	}

	searchSetup(businessNumber: string): any | null {
		const setupData = this.dataChannelSetup.find((setup) => setup.businessNumber === businessNumber);
		return setupData ? setupData : null;
	}
}
