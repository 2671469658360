<div class="row g-0">
	<div class="col">
		<div>
			<input
				type="text"
				class="form-control form-control-lg bg-light border-light"
				placeholder="Enter Message..."
				[(ngModel)]="message"
				(blur)="onBlur()"
				(focus)="onFocus()"
			/>
		</div>
	</div>
	<div class="col-auto">
		<div class="chat-input-links ms-md-2 me-md-0">
			<ul class="list-inline mb-0">
				<li class="list-inline-item">
					<button
						type="button"
						class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
						data-toggle="tooltip"
						data-placement="top"
						pTooltip="{{ 'Emoji' | translate }}"
						tooltipPosition="top"
						(click)="toggleEmojiPicker()"
						id="emoji-btn"
					>
						<i class="ri-emotion-happy-line"></i>
					</button>
					<emoji-mart
						class="emoji-mart"
						*ngIf="showEmojiPicker"
						(emojiSelect)="addEmoji($event)"
						title="Pick your emoji…"
					></emoji-mart>
				</li>
				<li class="list-inline-item">
					<label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
						<i class="ri-attachment-line"></i>
						<input
							name="fileInput"
							size="60"
							type="file"
							class="form-control-file d-none"
							(change)="captureMedia($event)"
						/>
					</label>
				</li>
				<li class="list-inline-item">
					<button
						type="submit"
						class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light"
						(click)="messageButtonClick()"
					>
						<i class="ri-send-plane-2-fill"></i>
					</button>
				</li>
			</ul>
		</div>
	</div>
</div>
