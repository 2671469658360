<app-formly
  *ngIf="loaded"
  [recordData] = "recordData"
  [module] = "subModule"
  [useLayout] = true
  [titleCode] = "titleCode"
  (emitAction)="submit($event)"
  (cancelAction)="cancel()"
  [sending] = "sending"
></app-formly>

