import { Component, ElementRef, EventEmitter, HostListener, inject, Input, Output } from '@angular/core';

@Component({
	selector: 'app-mini-card-contact',
	templateUrl: './mini-card-contact.component.html',
	styleUrls: ['./mini-card-contact.component.scss']
})
export class MiniCardContactComponent {
	@Input() fullName: string = 'J';
	@Input() phoneNumber: string = 'J';
	@Input() email: string = 'mikesoros@email.com';
	@Input() isExistContact: boolean = false;
	@Input() showField: boolean = false;

	@Output() emitValue: EventEmitter<any> = new EventEmitter<any>();

	private elementRef = inject(ElementRef);

	@HostListener('document:click', ['$event'])
	handleDocumentClick(event: MouseEvent) {
		const clickedElement = event.target as HTMLElement;
		if (!this.elementRef.nativeElement.contains(clickedElement)) {
			this.showField = false;
		}
	}
}
