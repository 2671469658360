import { CheckboxType } from "../ui/formly-fields/checkbox.type";
import { DateFieldType } from "../ui/formly-fields/date-field.type";
import { DateTimeFieldType } from "../ui/formly-fields/date-time-field.type";
import { InputFieldType } from "../ui/formly-fields/input-field.type";
import { LookUpAutocompleteFieldType } from "../ui/formly-fields/lookup-autocomplete-field.type";
import { LookUpFieldType } from "../ui/formly-fields/lookup-field.type";
import { MultiSelectFieldType } from "../ui/formly-fields/multi-select-field.type";
import { MultiSelectLookUpFieldType } from "../ui/formly-fields/multi-select-lookup-field.type";
import { SelectFieldType } from "../ui/formly-fields/select-field.type";
import { TextAreaFieldType } from "../ui/formly-fields/textarea-field.type";

export const formlyDeclaretionsTypes = [
	{
		name: 'input-custom',
		component: InputFieldType,
		wrappers: ['form-field']
	},
	{
		name: 'select-custom',
		component: SelectFieldType,
		wrappers: ['form-field']
	},
	{
		name: 'textarea-custom',
		component: TextAreaFieldType,
		wrappers: ['form-field']
	},
	{
		name: 'date-custom',
		component: DateFieldType,
		wrappers: ['form-field']
	},
	{
		name: 'datetime-custom',
		component: DateTimeFieldType,
		wrappers: ['form-field']
	},
	{
		name: 'multi-select',
		component: MultiSelectFieldType,
		wrappers: ['form-field']
	},
	{
		name: 'lookup-custom',
		component: LookUpFieldType,
		wrappers: ['form-field']
	},
	{
		name: 'custom-checkbox',
		component: CheckboxType,
		wrappers: ['form-field']
	},
	{
		name: 'multiSelectLookUp',
		component: MultiSelectLookUpFieldType,
		wrappers: ['form-field']
	},
  {
		name: 'lookup-autocomplete-input',
		component: LookUpAutocompleteFieldType,
		wrappers: ['form-field']
	},
];
