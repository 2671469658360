import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Contact } from '../../data-access/interface/search-contact.type';
import { SupportType } from '../../data-access/interface/search-supportType.interface';
import { Ticket } from '../../data-access/interface/search-ticket.interface';
import { TicketType } from '../../data-access/interface/search-ticketType.interface';
import { TicketService } from '../../data-access/services/ticket.service';

@Component({
	selector: 'app-ticket-list',
	templateUrl: './ticket-list.component.html',
	styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent {
	@Output() cancelTicketList = new EventEmitter<boolean>();
	@Input() infoContac: Contact;

	private _TicketService = inject(TicketService);

	public supportTickets: SupportType[];
	public ticketTypes: TicketType[];

	public tickets: Ticket[];
	public moreTickets: boolean = true;
	public numberPage: number = 1;

	getSupportType(id: number): string {
		let aux: SupportType[] = this.supportTickets.filter((element) => {
			return element.id === Number(id);
		});
		return aux[0].descripcion;
	}

	getTicketType(id: number): string {
		let aux: TicketType[] = this.ticketTypes.filter((element) => {
			return element.id === Number(id);
		});

		return aux.length !== 0 ? aux[0].descripcion : '';
	}

	searchTickets() {
		this._TicketService.searchTickets(this.infoContac.mobile_number, this.numberPage).subscribe((response) => {
			if (this.numberPage === 1) {
				this.tickets = response.data;
			} else {
				if (response.data && this.numberPage !== 1) {
					for (let item of response.data) {
						this.tickets.push(item);
					}
				}
				if (response.data) {
					this.moreTickets = false;
				}
			}

			this.numberPage = this.numberPage + 1;
		});
	}

	cancel() {
		this.cancelTicketList.emit(false);
	}
}
