<p-card
  [styleClass]="'mt-3 pt-3'"
  [style]="{ padding: '20px' }"
>
  <ng-template pTemplate="header">
    <div class="row">
      <div class="col-4">
        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
      class="rounded-circle img-fluid" style="width: 80px;" />
      </div>
      <div class="col-8">
        <table class="table-tickets-details">
          <tr>
            <td class="row-details tlabel">Fullname</td>
            <td class="tvalue">{{ contact.firstName }} {{ contact.lastName }}</td>
          </tr>
          <tr>
            <td class="row-details tlabel">Phone</td>
            <td class="tvalue">{{ contact.phone }}</td>
          </tr>
          <tr>
              <td class="row-details tlabel">City</td>
              <td class="tvalue">{{ contact.city }}</td>
          </tr>
      </table>
      </div>
    </div>
    <div style="text-align: right;">
      <p-button
      [text]="true"
        (click)="updateContact()"
        label="{{ 'Integration.ZohoCRM.Update' | translate }}"
        icon="pi pi-refresh"
        size="small"
        [disabled] = "updatingRecord"
      />
    </div>
  </ng-template>
</p-card>
