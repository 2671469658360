import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { MessageService } from '../../../utils/messages.service';
import { TicketsFormComponent } from './form/tickets-form.component';
import { TicketsListComponent } from './list/tickets-list.component';
import { TicketsDetailsComponent } from './details/tickets-details.component';

@Component({
	selector: 'app-tickets',
	templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  @ViewChild('childComponents', { read: ViewContainerRef }) container!: ViewContainerRef;

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();
  @Input("contact") contact?: any ;
  private messageService = inject(MessageService);
  private subscriptions = new Subscription();

  ngOnInit(): void {
    setTimeout(() => {
      this.refreshListActivity(false);
    });
  }

  createTickets(): void {
    this.container.clear();
    const ticketsFormComponent = this.container.createComponent(TicketsFormComponent);
    ticketsFormComponent.instance.contact = this.contact;
    const ticketsFormEvent$ = ticketsFormComponent.instance.emitAction.subscribe((values) => {
        this.subscriptions.add(ticketsFormEvent$);
        if (values.action === 'create') {
          this.messageService.showMessage('The ticket has been created successfully', 'success', true);
          this.refreshListActivity(true);
        } else {
          if (values.action === 'error') {
            this.emitAction.emit(values);
          } else {
            if (values.action === 'cancel') {
              this.refreshListActivity(false);
            }
          }
        }
    });
	}

  refreshListActivity(update: boolean): void {
    this.container.clear();
    const ticketsListComponent = this.container.createComponent(TicketsListComponent);
    ticketsListComponent.instance.contact = this.contact;
    ticketsListComponent.instance.updateCache = update;
    const ticketsListEvent$ = ticketsListComponent.instance.emitAction.subscribe((values) => {
      this.subscriptions.add(ticketsListEvent$);
      if (values.action === 'ticket-details') {
        this.ticketsDetails(values.values);
      }
    });
	}

  ticketsDetails(ticket: any) {
    this.container.clear();
    const ticketsDetailsComponent = this.container.createComponent(TicketsDetailsComponent);
    ticketsDetailsComponent.instance.ticketId = ticket.id;
    const ticketsDetailsEvent$ = ticketsDetailsComponent.instance.emitAction.subscribe((values) => {
      this.subscriptions.add(ticketsDetailsEvent$);
      if (values.action === 'cancel') {
        this.refreshListActivity(false)
      }
    });
  }

}
