import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { MessageService } from '../../utils/messages.service';
import { LoadingZohoCrmComponent } from '../../ui/loading/loading.component';
import { Organization } from '../../models/organization-response.model';
import { ResendConversationEventService } from '../../service/resend-conversation-event.service';
import { InitZohoCrmComponent } from '../../zoho-crm/ui/init-zoho-crm/init-zoho-crm.component';
import { IntegrationConfigResponse } from '../../models/integration-config-response.model';
import { IntegrationConfigComponent } from '../../zoho-crm/ui/integration-config/integration-config.component';
import { LayoutMetadata } from '../../models/layout-metadata.model';
import { ZohoDeskBackendService } from '../data-access/zoho-desk-backend.service';
import { ContactDeskDetailsComponent } from '../ui/contact-desk-details/contact-desk-details.component';
import { MultiplesContactsResultComponent } from '../ui/multiples-contacts-result/multiples-contacts-result.component';
import { ContactDeskFormComponent } from '../ui/contact-desk-form/contact-desk-form.component';

@Component({
	selector: 'app-zoho-desk',
	templateUrl: './zoho-desk.component.html',
	styleUrls: ['./zoho-desk.component.scss']
})
export class ZohoDeskComponent implements OnInit, OnDestroy {

	serviceInstance: IServiceInstance;

	@ViewChild('childZohoDeskComponents', { read: ViewContainerRef }) container!: ViewContainerRef;

	@Input() ocultIntegrationSidebar: boolean = false;

  @Output() emitAction: EventEmitter<any> = new EventEmitter<any>();

	@Output() closeZCRM: EventEmitter<any> = new EventEmitter<any>();

	private _ResendConversationEventService = inject(ResendConversationEventService);
	private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
	public translate = inject(TranslateService);
	private fb = inject(FormBuilder);
	private messageService = inject(MessageService);
	loadingActive = false;
	showNavBar = false;
	serviceInstanciaId: string;
	public numberPhone = '';
	public fullName = '';
	searching = false;
	private subscriptions = new Subscription();

	searchForm: FormGroup;

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		this.serviceInstanciaId = this.serviceInstance.id;
		this.checkIfShowNavBar();
		this.subscribeConversationEvent();
		setTimeout(() => {
      if (localStorage.getItem('ZohoDeskOrgDomainName') == null) {
        this.registerIntegration()
      } else {
        this.initZohoCRM();
      }
    });
		this.createSearchForm();
	}

	subscribeConversationEvent() {
		const chatEvent$ = this._ResendConversationEventService.getEvent().subscribe((data) => {
      this.subscriptions.add(chatEvent$);
      if (localStorage.getItem('activeZoho') === 'desk') {
        if (!this.checkIfStartConfiguration()) {
          this.numberPhone = data.clientPhone;
          this.fullName = data.fullName;
          this.container.clear();
          this.searchInZoho(this.numberPhone, this.fullName);
        } else {
          this.startIntegrationConfig();
        }
      }
		});
	}

	searchInZoho(phone: string, fullName: string): void {
		if (!phone) {
			this.initZohoCRM();
			return;
		}
		this.loadingZohoCRM();
		const searchRecords$ = this._ZohoDeskBackendService.searchContacts(phone).subscribe(
			(resp: HttpResponse<any[]>) => {
				this.processedSearching(resp, 'phone');
				this.subscriptions.add(searchRecords$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

  searchContactsByTerm(term: string): void {
		// this.loadingZohoCRM();
		const searchRecords$ = this._ZohoDeskBackendService.searchContactsByTerm(term).subscribe(
			(resp: HttpResponse<any[]>) => {
				this.processedSearching(resp, 'term');
				this.subscriptions.add(searchRecords$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

  processedSearching(resp: HttpResponse<any[]>, from: string): void {
    this.searchForm.enable();
    this.searchForm.patchValue({
			searchInput: ''
		});
    if (resp.status == 200) {
      this.loadingActive = false;
      const response: any[] = resp.body;
      if (!response || response.length == 0) {
        if (from === 'phone') {
          const defaultData = {
            phone: this.numberPhone,
            mobile: this.numberPhone,
            firstName: this.fullName
          };
          this.recordForm(JSON.parse(JSON.stringify(defaultData)), 'Contacts');
        }
        if (from === 'term') {
          this.messageService.showMessage('No record was found with that search.', 'success', true);
          return;
        }
      } else {
        if (response.length == 1) {
          const contactData: any = response[0];
          this.contactDetails(contactData);
        } else {
          this.selectedOnMultiplesContacts(response);
        }
      }
    }
  }

	initZohoCRM(): void {
		this.container.clear();
		const initZohoCRMComponent = this.container.createComponent(InitZohoCrmComponent);
		const initZohoCRMEvent$ = initZohoCRMComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(initZohoCRMEvent$);
			if (values.action === 'cancel') {
				this.closeZCRM.emit();
			}
		});
	}

	loadingZohoCRM(): void {
		this.container.clear();
		this.loadingActive = true;
		this.container.createComponent(LoadingZohoCrmComponent);
	}

	registerIntegration(): void {
		this.loadingZohoCRM();
		const registerIntegration$ = this._ZohoDeskBackendService.registerIntegration().subscribe(
			(resp: HttpResponse<Organization>) => {
				this.loadingActive = false;
				if (resp.status == 200) {
          this.startIntegrationConfig();
					localStorage.setItem('ZohoDeskOrgDomainName', resp.body.domainName);
				}
				this.subscriptions.add(registerIntegration$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

  startIntegrationConfigComponent(integrationConfigResponse: IntegrationConfigResponse): void {
		this.container.clear();
		const onIntegrationConfigComponent = this.container.createComponent(IntegrationConfigComponent);
		onIntegrationConfigComponent.instance.integrationConfigResponse = integrationConfigResponse;
		const onLoginEvent$ = onIntegrationConfigComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onLoginEvent$);
			if (values.action === 'save-init-config') {
				const registerIntegration$ = this._ZohoDeskBackendService
					.registerIntegrationConfig(values.values)
					.subscribe(
						(res: HttpResponse<LayoutMetadata>) => {
							const layoutMetaData: LayoutMetadata = res.body;
							localStorage.setItem('tickets-metadata-desk', JSON.stringify(layoutMetaData.ticketsSections));
              localStorage.setItem('contacts-metadata-desk', JSON.stringify(layoutMetaData.clientsSections));
							this.messageService.showMessage('The settings have been saved.', 'success', true);
							this.container.clear();
							this.initZohoCRM();
							this.subscriptions.add(registerIntegration$);
						},
						(error: HttpErrorResponse) => this.errorHandler(error)
					);
			} else {
				this.closeZCRM.emit();
			}
		});
	}

	startIntegrationConfig() {
		this.loadingZohoCRM();
			const calls: any[] = [];
			calls.push(this._ZohoDeskBackendService.getLayoutsConfig());
			const forkJoin$ = forkJoin(calls).subscribe(
				(data: any[]) => {
				this.loadingActive = false;
				const integrationConfigResponse: IntegrationConfigResponse = data[0].body;
				if (integrationConfigResponse && integrationConfigResponse.layouts) {
					localStorage.setItem('zohoDeskLayouts', JSON.stringify(integrationConfigResponse.layouts));
				}
				this.startIntegrationConfigComponent(integrationConfigResponse);
				this.subscriptions.add(forkJoin$);
				},
				(error: HttpErrorResponse) => this.errorHandler(error)
			);
	}

	errorHandler(error: HttpErrorResponse): void {
		this.searching = false;
		this.searchForm.controls.searchInput.enable();
		if (error && error.error && error.error.message && error.error.code) {
			const errorMessage: string = error.error.message;
			const errorCode: string = error.error.code;
			if (errorCode.startsWith('error.zohocrm.auth')) {
				this.emitAction.emit({
          action: 'error',
          values: error,
          module: ''
        });
			} else {
				if (errorCode.startsWith('INVALID TOKEN ERROR') || errorCode.startsWith('unauthorized.access')) {
					this.emitAction.emit({
            action: 'error',
            values: error,
            module: ''
          });
				} else {
					this.messageService.showMessage(errorMessage, 'error', false);
					if (this.loadingActive) {
						this.initZohoCRM();
					}
				}
			}
		} else {
			this.messageService.showMessage('An error has occurred, try again', 'error', false);
			this.initZohoCRM();
		}
	}

	checkIfShowNavBar(): void {
		this.showNavBar = localStorage.getItem('zohoDeskCredencials') != null;
	}

  checkIfStartConfiguration(): boolean {
		return localStorage.getItem('tickets-metadata-desk') == null;
	}

	logout(): void {
		this.emitAction.emit({
      action: 'logout',
      values: '',
      module: ''
    });
	}

	createSearchForm(): void {
		this.searchForm = this.fb.group({});
		this.searchForm.addControl('searchInput', this.fb.control('', [Validators.required]));
	}

  recordForm(contact?: Map<string, any>, module?: string) {
		this.container.clear();
		const recordFormComponent = this.container.createComponent(ContactDeskFormComponent);
		recordFormComponent.instance.contact = contact;
		const recordFormComponent$ = recordFormComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordFormComponent$);
			if (values.action === 'update-record') {
				this.messageService.showMessage('The record has been updated.', 'success', true);
				this.contactDetails(values.values);
			} else {
				if (values.action === 'create-record') {
					this.messageService.showMessage('The record has been created.', 'success', true);
					this.contactDetails(values.values);
				} else {
					if (values.action === 'error') {
						this.errorHandler(values.values);
					} else {
						if (values.action === 'cancel') {
							if (contact && contact['id']) {
								this.contactDetails(contact);
							} else {
								this.initZohoCRM();
							}
						}
					}
				}
			}
		});
	}

  contactDetails(contact: Map<string, any>) {
		this.container.clear();
		const contactDetailsComponent = this.container.createComponent(ContactDeskDetailsComponent);
		contactDetailsComponent.instance.contact = contact;
		const contactDetailsEvent$ = contactDetailsComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(contactDetailsEvent$);
		  if (values.action === 'edit') {
				this.recordForm(values.values, values.module);
			} else {
				if (values.action === 'error') {
					this.errorHandler(values.values);
				} else {
						this.closeZCRM.emit();
				}
      }
		});
	}

  selectedOnMultiplesContacts(contacts: any[]): void {
		this.container.clear();
		const recordMultiplesRecordsComponent = this.container.createComponent(MultiplesContactsResultComponent);
		recordMultiplesRecordsComponent.instance.contacts = contacts;
		const recordMultiplesRecordsEvent$ = recordMultiplesRecordsComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordMultiplesRecordsEvent$);
			if (values.action === 'selected') {
				this.contactDetails(values.values);
			} else {
				this.closeZCRM.emit();
			}
		});
	}

  search(): void {
    this.searchForm.disable();
		this.searchContactsByTerm(this.searchForm.value['searchInput']);
	}

}
