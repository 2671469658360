import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateTicketDTO } from '../interface/create-ticket.interface';
import { SearchSupportTypeDTO, SupportType } from '../interface/search-supportType.interface';
import { DataTicket, SearchTicketDTO, Ticket } from '../interface/search-ticket.interface';
import { SearchTicketTypeDTO, TicketType } from '../interface/search-ticketType.interface';
import { AuthenticationTTCService } from './authentication-ttc.service';

@Injectable({
	providedIn: 'root'
})
export class TicketService {
  private http = inject(HttpClient)
	private baseUrl = environment.URL_API_TIME_TO_CHANGE;
	supportType: SupportType[];
	ticketType: TicketType[];

	getTicketType(): Observable<TicketType[]> {
		const URL = this.baseUrl + '/api/v1/ticketType';

		return this.http.post<SearchTicketTypeDTO>(URL, {}).pipe(
			map((response) => {
				return response.data;
			})
		);
	}

	getSupportType(): Observable<SupportType[]> {
		const URL = this.baseUrl + '/api/v1/supportType';

		return this.http.post<SearchSupportTypeDTO>(URL, {}).pipe(
			map((response) => {
				return response.data;
			})
		);
	}

	createTicket(ticket: Ticket): Observable<CreateTicketDTO> {
		const URL = this.baseUrl + '/api/v1/ticket';

		return this.http.post<CreateTicketDTO>(URL, ticket).pipe(
			map((response) => {
				return response;
			})
		);
	}

	searchTickets(numberPhone: string, page: number): Observable<DataTicket> {
		const URL = this.baseUrl + '/api/v1/ticket/list?page=' + page;

		return this.http
			.post<SearchTicketDTO>(URL, {
				mobile_number: numberPhone
			})
			.pipe(
				map((response) => {
					return response.data;
				})
			);
	}
}
