<div *ngIf="!loading && ticketHistory && ticketHistory.length > 0" class="mt-2">


    <table class="table-tickets-details">
      <tr *ngFor="let th of ticketHistory">
        <td class="row-details tlabel"> {{ th.eventTime }}</td>
        <td class="tvalue">
          <div class="flex flex-column gap-1">
            <span class="font-bold text-md">{{  th.eventName }}</span>
            <span class="text-secondary"> {{ th.authorName }}</span>
            <span class="text-secondary"> {{ th.source }}</span>
        </div>
        </td>
      </tr>
    </table>

</div>

<p class="empty-list" *ngIf="!loading && (!ticketHistory || ticketHistory.length == 0)">
  {{ 'Integration.ZohoCRM.There are no records' | translate }}
</p>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>
