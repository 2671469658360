import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoDeskBackendService } from '../../../../data-access/zoho-desk-backend.service';

@Component({
  selector: 'app-tickets-details-resolution',
  templateUrl: './tickets-details-resolution.component.html',
  styleUrls: ['./tickets-details-resolution.component.scss']
})
export class TicketsDetailsResolutionComponent implements OnDestroy, OnInit {

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  private subscriptions = new Subscription();

  @Input("ticketId") ticketId?: any ;
  @Input("updateCache") updateCache?: boolean ;

  public ticketResolution?:  any[];
  loading: boolean;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    this.loading = true;
    setTimeout(() => {
        this.getResolutionTickets();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getResolutionTickets(): void {
    const createRecord$ = this._ZohoDeskBackendService
    .getResolutionTickets(this.ticketId, this.updateCache)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.ticketResolution = resp.body;
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
    });
  }

}
