import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { RedirectZohoCRMModule } from 'src/app/redirect-zoho/feature/redirect-zoho.module';
import { ChatErrorComponent } from '../chat-error/chat-error.component';
import { ChatComponent } from '../chat-feature/chat.component';
import { ChatNoChannelsAvailableComponent } from '../chat-no-channels-available/chat-no-channels-available.component';

const routes: Routes = [
	{
		path: '',
		component: ChatComponent
	},
	{
		path: 'error',
		component: ChatErrorComponent
	},
	{
		path: 'no-channels',
		component: ChatNoChannelsAvailableComponent
	},
	{
		path: 'zoho-crm-redirect',
		component: RedirectZohoCRMModule
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes), FormsModule, ReactiveFormsModule],
	exports: [RouterModule]
})
export class ChatRoutingModule {}
