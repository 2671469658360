import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Domains } from 'src/app/core/enums/domains.enum';
import { CustomerState } from 'src/app/shared/states/customer.state';

@Injectable()
export class ZohoInterceptor implements HttpInterceptor {
	private store = inject(Store);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let zohoCredencialsAsString = null;

    let zohoType = 'crm';
    if (req.url.includes(Domains.ZohoDESK)) {
      zohoCredencialsAsString = localStorage.getItem('zohoDeskCredencials');
      zohoType = 'desk';
    }
    if (req.url.includes(Domains.ZohoCRM)) {
      zohoCredencialsAsString = localStorage.getItem('zohoCrmCredencials');
      zohoType = 'crm';
    }

    if (zohoCredencialsAsString != null) {
      const zohoCredencials = JSON.parse(zohoCredencialsAsString);
      const profile = this.store.selectSnapshot(CustomerState.getCustomer);
      const agentId = profile['sub'];
      req = req.clone({
        setHeaders: {
          'grantToken': (zohoCredencials.grantToken) ? zohoCredencials.grantToken : '',
          'agentId': (agentId) ? agentId : '',
          'serviceInstanceId': (zohoCredencials.serviceInstanceId) ? zohoCredencials.serviceInstanceId : '',
          'location': (zohoCredencials.location) ? zohoCredencials.location : '',
          'email': (profile && profile.email) ? profile.email : '',
          'zohoType': zohoType
        }
      });
      return next.handle(req);
    }
		return next.handle(req);
	}

}
