<div class="container-list" [ngClass]="{ 'hidden-panel': ocultIntegrationSidebar }">
	<ng-container *ngFor="let item of integrations">
		<div
			*ngIf="item.IsIntegrationAdded"
			class="container-integration"
			[ngClass]="item.selected ? 'active' : ''"
			(click)="handleSelectionIntegration(item)"
		>
			<img [src]="item.Image" width="24" />
		</div>
	</ng-container>
</div>
