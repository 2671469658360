<app-formly
  *ngIf="!loading"
  [recordData] = "record"
  [module] = "'tickets'"
  [useLayout] = true
  [titleCode] = ""
  (emitAction)="submit($event)"
  (cancelAction)="cancel()"
  [sending] = "sending"
  [zohoType] = "'desk'"
  [departments] = "departments"
></app-formly>
<div *ngIf="loading" class="spinner-border" role="status">
  <span class="visually-hidden"></span>
</div>

