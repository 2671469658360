import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from '../../../models/emitData.interface';
import { Record } from '../../../models/records-response.model';
import { ZohoDeskBackendService } from '../../data-access/zoho-desk-backend.service';

@Component({
  selector: 'app-contact-desk-form',
  templateUrl: './contact-desk-form.component.html',
  styleUrls: ['./contact-desk-form.component.scss']
})
export class ContactDeskFormComponent implements OnInit, OnDestroy {

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  private subscriptions = new Subscription();
  public contact?: Map<string, any>;
  sending = false;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit(data: emitData): void {
    this.sending = true;
    if (data.action === 'update') {
      const createRecord$ = this._ZohoDeskBackendService
        .updateContact(this.contact['id'], data.values)
        .subscribe((resp: HttpResponse<any>) => {
          if (resp.status == 200) {
            if (resp.body && resp.body.data && resp.body.data.length > 0) {
              this.emitAction.emit({
                action: 'update-record',
                values: resp.body.data[0],
                module: ''
              });
            }
          }
          this.sending = false;
          this.subscriptions.add(createRecord$);
        }, (error: HttpErrorResponse) => {
            this.sending = false;
            this.emitAction.emit({
              action: 'error',
              values: error,
              module: data.module
            });
        });
    } else {
      if (data.action === 'create') {
        const createRecord$ = this._ZohoDeskBackendService
          .createContact(data.values)
          .subscribe((resp: HttpResponse<Record>) => {
            if (resp.status == 200) {
              if (resp.body && resp.body.data && resp.body.data.length > 0) {
                this.emitAction.emit({
                  action: 'create-record',
                  values: resp.body,
                  module: data.module
                });
              }
            }
            this.sending = false;
            this.subscriptions.add(createRecord$);
          }, (error: HttpErrorResponse) => {
              this.sending = false;
              this.emitAction.emit({
                action: 'error',
                values: error,
                module: data.module
              });
          });
      }
    }
  }

  cancel(): void {
    this.emitAction.emit({
      action: 'cancel',
      values: '',
      module: ''
    });
  }

}
