<ul *ngIf="!loading && deals && deals.length > 0" class="list-group list-group-flush mt-2">
  <li *ngFor="let deal of deals" class="list-group-item mb-2">

    <span style="font-weight: bold;">Nombre: </span> <span >{{ deal.name }}</span><br />
    <div *ngIf="deal.amount"> <span style="font-weight: bold;">Monto: </span> <span > $ {{ deal.amount }}</span><br /></div>
    <div *ngIf="deal.probability"> <span style="font-weight: bold;">Probabilidad: </span> <span > {{ deal.probability }} %</span><br /></div>
    <div *ngIf="deal.closingDate"> <span style="font-weight: bold;">Cierre: </span> <span > {{ deal.closingDate }}</span><br /></div>
    <div *ngIf="deal.stage"> <span style="font-weight: bold;">Nivel: </span> <span > {{ deal.stage }}</span><br /></div>
    <br/>
    {{ deal.createdBy }}
    <br />
    {{ deal.created }}
  </li>
</ul>

<p *ngIf="!loading && (!deals || deals.length == 0)">
  {{ 'Integration.ZohoCRM.There are no opportunities' | translate }}
</p>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>
