import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthTimeToChange, UserLogin } from '../interface/auth-timeToChange.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationTTCService {
	private _http = inject(HttpClient);
	private baseUrl = environment.URL_API_TIME_TO_CHANGE;
  public token: string = '';
  public expiredDate: Date;

	authentication(user: UserLogin): Observable<AuthTimeToChange> {
		const URL = this.baseUrl + '/oauth/v1/auth';

		return this._http.post<AuthTimeToChange>(URL, user);
	}
}
