import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent {
	private fb = inject(FormBuilder);

	public form = this.fb.group({
		id: ['', Validators.required],
		name: ['Aaron', Validators.required],
		lastName: ['Adams', Validators.required],
		address: new FormGroup({
			line1: new FormControl<string>('', Validators.required),
			line2: new FormControl<string>(''),
			postalCode: new FormControl<string>(''),
			country: new FormControl<string>('', Validators.required),
			state: new FormControl<string>('', Validators.required),
			city: new FormControl<string>('', Validators.required)
		}),
		billingAddress: new FormGroup({
			line1: new FormControl<string>('', Validators.required),
			line2: new FormControl<string>(''),
			postalCode: new FormControl<string>(''),
			country: new FormControl<string>('', Validators.required),
			state: new FormControl<string>('', Validators.required),
			city: new FormControl<string>('', Validators.required)
		}),
		phone: ['', Validators.required],
		email: ['aaron.adams@example.com', Validators.required],
		clientId: ['', Validators.required],
		sameAddressAsBilling: [false, Validators.required]
	});

	public genderOptions: string[] = ['Female', 'Male', 'Other'];
}
