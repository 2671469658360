import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IntegrationI } from '../../data-access/interfaces/integration.interface';

@Component({
	selector: 'app-select-integration-module',
	templateUrl: './select-integration-module.component.html',
	styleUrls: ['./select-integration-module.component.scss']
})
export class SelectIntegrationModuleComponent {
	@Input() integrations: IntegrationI[];
  @Input() ocultIntegrationSidebar: boolean = false;

	@Output() selectIntegration = new EventEmitter<number>();

	handleSelectionIntegration(integration: IntegrationI): void {
		this.integrations = this.integrations.map((element) =>
			element === integration ? { ...element, selected: true } : { ...element, selected: false }
		);

		this.selectIntegration.emit(integration.integrationType);
	}
}
