import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-card',
  template: `
    <div>
      <h6 class="mt-5">{{ props['display_label'] }}</h6>
      <div class="p-grid p-fluid">
        <ng-container #fieldComponent></ng-container>
      </div>
</div>
  `,
})
export class CardWrapperComponent extends FieldWrapper {}
