import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin } from 'rxjs';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { ZohoIntegrationBackendService } from '../../data-access/zoho-integration-backend.service';
import { MessageService } from '../../utils/messages.service';
import { RecordResponse } from '../../models/records-response.model';
import { LoginFormComponent } from '../../ui/login-form/login-form.component';
import { InitZohoCrmComponent } from '../ui/init-zoho-crm/init-zoho-crm.component';
import { RecordFormComponent } from '../ui/record-form/record-form.component';
import { LoadingZohoCrmComponent } from '../../ui/loading/loading.component';
import { Organization } from '../../models/organization-response.model';
import { IntegrationConfigResponse } from '../../models/integration-config-response.model';
import { IntegrationConfigComponent } from '../ui/integration-config/integration-config.component';
import { LayoutMetadata } from '../../models/layout-metadata.model';
import { RecordMultiplesRecordsComponent } from '../ui/record-multiples-results/record-multiples-records.component';
import { ContactDetailsComponent } from '../ui/contact-details/contact-details.component';
import { ResendConversationEventService } from '../../service/resend-conversation-event.service';


@Component({
	selector: 'app-zoho-crm',
	templateUrl: './zoho-crm.component.html',
	styleUrls: ['./zoho-crm.component.scss']
})
export class ZohoCRMComponent implements OnInit, OnDestroy {
	serviceInstance: IServiceInstance;

	@ViewChild('childZohoCRMComponents', { read: ViewContainerRef }) container!: ViewContainerRef;

	@Input() ocultIntegrationSidebar: boolean = false;

  @Output() emitAction: EventEmitter<any> = new EventEmitter<any>();

	@Output() closeZCRM: EventEmitter<any> = new EventEmitter<any>();

	private _ResendConversationEventService = inject(ResendConversationEventService);
	private _ZohoIntegrationBackendService = inject(ZohoIntegrationBackendService);
	public translate = inject(TranslateService);
	private fb = inject(FormBuilder);
	private messageService = inject(MessageService);
	loadingActive = false;
	showNavBar = false;
	serviceInstanciaId: string;
	public numberPhone = '';
	public fullName = '';
	searching = false;
	private subscriptions = new Subscription();

	searchForm: FormGroup;

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		this.serviceInstanciaId = this.serviceInstance.id;
		this.checkIfShowNavBar();
		this.subscribeConversationEvent();
		setTimeout(() => {
      if (localStorage.getItem('ZohoCrmOrgDomainName') == null) {
        this.registerIntegration()
      } else {
        this.initZohoCRM();
      }
    });
		this.createSearchForm();
	}

	subscribeConversationEvent() {
		const chatEvent$ = this._ResendConversationEventService.getEvent().subscribe((data) => {
      this.subscriptions.add(chatEvent$);
      if (localStorage.getItem('activeZoho') === 'crm') {
        if (!this.checkIfStartConfiguration()) {
          this.numberPhone = data.clientPhone;
          this.fullName = data.fullName;
          this.container.clear();
          this.searchInZohoCRM(this.numberPhone, this.fullName);
        } else {
          this.startIntegrationConfig();
        }
      }
		});
	}

	searchByTerm(term: string): void {
		this.searching = true;
		this.searchForm.controls.searchInput.disable();
    this.searchForm.enable();
    this.searchForm.patchValue({
			searchInput: ''
		});
		const searchRecords$ = this._ZohoIntegrationBackendService.search(term).subscribe(
			(resp: HttpResponse<RecordResponse[]>) => {
        this.searchForm.enable();
				if (resp.status == 200) {
					this.searching = false;
					const response: RecordResponse[] = resp.body;
					if (!response || response.length == 0) {
						this.messageService.showMessage('No record was found with that search.', 'success', true);
						return;
					} else {
						if (response.length == 1) {
							const recordData: RecordResponse = response[0];
							if (recordData.countFounded == 1) {
								this.contactDetails(recordData.moduleZohoCrmType, recordData.records.data[0]);
							} else {
								this.selectedOnMultiplesRecords(response);
							}
						} else {
							this.selectedOnMultiplesRecords(response);
						}
					}
				}
				this.subscriptions.add(searchRecords$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

	searchInZohoCRM(phone: string, fullName: string): void {
		if (!phone) {
			this.initZohoCRM();
			return;
		}
		this.loadingZohoCRM();
		const searchRecords$ = this._ZohoIntegrationBackendService.searchRecords(phone).subscribe(
			(resp: HttpResponse<RecordResponse[]>) => {
				if (resp.status == 200) {
					this.loadingActive = false;
					const response: RecordResponse[] = resp.body;
					if (!response || response.length == 0) {
						const defaultData = {
							Phone: this.numberPhone,
							Mobile: this.numberPhone,
							Full_Name: this.fullName
						};
						this.recordForm(JSON.parse(JSON.stringify(defaultData)), 'Leads');
					} else {
						if (response.length == 1) {
							const recordData: RecordResponse = response[0];
							if (recordData.countFounded == 1) {
								this.contactDetails(recordData.moduleZohoCrmType, recordData.records.data[0]);
							} else {
								this.selectedOnMultiplesRecords(response);
							}
						} else {
							this.selectedOnMultiplesRecords(response);
						}
					}
				}
				this.subscriptions.add(searchRecords$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

	initZohoCRM(): void {
		this.container.clear();
		const initZohoCRMComponent = this.container.createComponent(InitZohoCrmComponent);
		const initZohoCRMEvent$ = initZohoCRMComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(initZohoCRMEvent$);
			if (values.action === 'cancel') {
				this.closeZCRM.emit();
			}
		});
	}

	formRecord(): void {
		this.container.clear();
		const recordFormComponent = this.container.createComponent(RecordFormComponent);
		const recordFormEvent$ = recordFormComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordFormEvent$);
			if (values.action === 'config-zoho') {
				this.startIntegrationConfig();
			} else {
				this.closeZCRM.emit();
			}
		});
	}

	loadingZohoCRM(): void {
		this.container.clear();
		this.loadingActive = true;
		this.container.createComponent(LoadingZohoCrmComponent);
	}

	registerIntegration(): void {
		this.loadingZohoCRM();
		const registerIntegration$ = this._ZohoIntegrationBackendService.registerIntegration().subscribe(
			(resp: HttpResponse<Organization>) => {
				this.loadingActive = false;
				if (resp.status == 200) {
          this.startIntegrationConfig();
					localStorage.setItem('ZohoCrmOrgDomainName', resp.body.domainName);
				}
				this.subscriptions.add(registerIntegration$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

	startIntegrationConfigComponent(integrationConfigResponse: IntegrationConfigResponse): void {
		this.container.clear();
		const onIntegrationConfigComponent = this.container.createComponent(IntegrationConfigComponent);
		onIntegrationConfigComponent.instance.integrationConfigResponse = integrationConfigResponse;
		const onLoginEvent$ = onIntegrationConfigComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onLoginEvent$);
			if (values.action === 'save-init-config') {
				const registerIntegration$ = this._ZohoIntegrationBackendService
					.registerIntegrationConfig(values.values)
					.subscribe(
						(res: HttpResponse<LayoutMetadata>) => {
							const layoutMetaData: LayoutMetadata = res.body;
							localStorage.setItem('leads-metadata-crm', JSON.stringify(layoutMetaData.leadsSections));
							localStorage.setItem('contacts-metadata-crm', JSON.stringify(layoutMetaData.contactsSections));
							localStorage.setItem('deals-metadata-crm', JSON.stringify(layoutMetaData.dealsSections));
							localStorage.setItem('tasks-metadata-crm', JSON.stringify(layoutMetaData.tasksSections));
							localStorage.setItem('events-metadata-crm', JSON.stringify(layoutMetaData.eventsSections));
							localStorage.setItem('calls-metadata-crm', JSON.stringify(layoutMetaData.callsSections));
							this.messageService.showMessage('The settings have been saved.', 'success', true);
							this.container.clear();
							this.initZohoCRM();
							this.subscriptions.add(registerIntegration$);
						},
						(error: HttpErrorResponse) => this.errorHandler(error)
					);
			} else {
				this.closeZCRM.emit();
			}
		});
	}

	startIntegrationConfig() {
   this.loadingZohoCRM();
      const calls: any[] = [];
      calls.push(this._ZohoIntegrationBackendService.getLayoutsConfig());
      const forkJoin$ = forkJoin(calls).subscribe(
        (data: any[]) => {
          this.loadingActive = false;
          const integrationConfigResponse: IntegrationConfigResponse = data[0].body;
          if (integrationConfigResponse && integrationConfigResponse.layouts) {
            localStorage.setItem('zohoCrmLayouts', JSON.stringify(integrationConfigResponse.layouts));
          }
          this.startIntegrationConfigComponent(integrationConfigResponse);
          this.subscriptions.add(forkJoin$);
        },
        (error: HttpErrorResponse) => this.errorHandler(error)
      );

	}

	selectedOnMultiplesRecords(records: RecordResponse[]): void {
		this.container.clear();
		const recordMultiplesRecordsComponent = this.container.createComponent(RecordMultiplesRecordsComponent);
		recordMultiplesRecordsComponent.instance.records = records;
		const recordMultiplesRecordsEvent$ = recordMultiplesRecordsComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordMultiplesRecordsEvent$);
			if (values.action === 'selected') {
				this.contactDetails(values.module, values.values);
			} else {
				this.closeZCRM.emit();
			}
		});
	}

	contactDetails(module: string, recordData: Map<string, any>) {
		this.container.clear();
		const contactDetailsComponent = this.container.createComponent(ContactDetailsComponent);
		contactDetailsComponent.instance.recordData = recordData;
		contactDetailsComponent.instance.module = module;
		const contactDetailsEvent$ = contactDetailsComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(contactDetailsEvent$);
			if (values.action === 'config-zoho') {
				this.startIntegrationConfig();
			} else {
				if (values.action === 'edit') {
					this.recordForm(values.values, values.module);
				} else {
					if (values.action === 'error') {
						this.errorHandler(values.values);
					} else {
						if (values.action === 'created-activity') {
							this.messageService.showMessage('Successfully created activity', 'success', true);
						} else {
							if (values.action === 'created-activity') {
								this.messageService.showMessage('Successfully created opportunity', 'success', true);
							} else {
								if (values.action === 'convert') {
									console.error('Convert');
								} else {
									this.closeZCRM.emit();
								}
							}
						}
					}
				}
			}
		});
	}

	recordForm(recordData?: Map<string, any>, module?: string) {
		this.container.clear();
		const recordFormComponent = this.container.createComponent(RecordFormComponent);
		recordFormComponent.instance.recordData = recordData;
		recordFormComponent.instance.module = module;
		const recordFormComponent$ = recordFormComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordFormComponent$);
			if (values.action === 'update-record') {
				this.messageService.showMessage('The record has been updated.', 'success', true);
				this.contactDetails(values.module, values.values);
			} else {
				if (values.action === 'create-record') {
					this.messageService.showMessage('The record has been created.', 'success', true);
					this.contactDetails(values.module, values.values);
				} else {
					if (values.action === 'error') {
						this.errorHandler(values.values);
					} else {
						if (values.action === 'cancel') {
							if (recordData && recordData['id']) {
								this.contactDetails(module, recordData);
							} else {
								this.initZohoCRM();
							}
						}
					}
				}
			}
		});
	}

	errorHandler(error: HttpErrorResponse): void {
		this.searching = false;
		this.searchForm.controls.searchInput.enable();
		if (error && error.error && error.error.message && error.error.code) {
			const errorMessage: string = error.error.message;
			const errorCode: string = error.error.code;
			if (errorCode.startsWith('error.zohocrm.auth')) {
				this.emitAction.emit({
          action: 'error',
          values: error,
          module: ''
        });
			} else {
				if (errorCode.startsWith('INVALID TOKEN ERROR') || errorCode.startsWith('unauthorized.access')) {
          this.emitAction.emit({
            action: 'error',
            values: error,
            module: ''
          });
				} else {
					this.messageService.showMessage(errorMessage, 'error', false);
					if (this.loadingActive) {
						this.initZohoCRM();
					}
				}
			}
		} else {
			this.messageService.showMessage('An error has occurred, try again', 'error', false);
			this.initZohoCRM();
		}
	}

	checkIfShowNavBar(): void {
		this.showNavBar = localStorage.getItem('zohoCrmCredencials') != null;
	}

  checkIfStartConfiguration(): boolean {
		return localStorage.getItem('leads-metadata-crm') == null;
	}

	logout(): void {
		this.emitAction.emit({
      action: 'logout',
      values: '',
      module: ''
    });
	}

	createSearchForm(): void {
		this.searchForm = this.fb.group({});
		this.searchForm.addControl('searchInput', this.fb.control('', [Validators.required]));
	}

	search(): void {
    this.searchForm.disable();
		this.searchByTerm(this.searchForm.value['searchInput']);
	}
}
