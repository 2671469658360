<div *ngIf="!loading && ticketResolution && ticketResolution.length > 0" class="mt-2">
    <table class="table-tickets-details">
      <tr *ngFor="let tr of ticketResolution">
        <td class="row-details tlabel"> {{ tr.modifiedTime }}</td>
        <td class="tvalue">
          <div class="flex flex-column gap-1">
            <span class="font-bold text-md">{{  tr.content }}</span>
            <span class="text-secondary"> {{ tr.authorName }}</span>
        </div>
        </td>
      </tr>
    </table>
</div>

<p class="empty-list" *ngIf="!loading && (!ticketResolution || ticketResolution.length == 0)">
  {{ 'Integration.ZohoCRM.There is no description' | translate }}
</p>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>
