import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LightboxModule } from 'ngx-lightbox';

import { ChatRoutingModule } from './chat-routing.module';

import { DatePipe } from '@angular/common';

// Emoji Picker
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { CarouselModule } from 'ngx-owl-carousel-o';

// Simplebar
import { SimplebarAngularModule } from 'simplebar-angular';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { MenuModule } from 'primeng/menu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { TokenInterceptor } from '../../../core/helpers/token.interceptor';
import { IntegrationPanelModule } from '../../../integrations/integration-panel/feature/integration-panel.module';
import { ChatContentMessageComponent } from '../../ui/chat-content/chat-content-message/chat-content-message.component';
import { ChatContentComponent } from '../../ui/chat-content/chat-content.component';
import { ChatWelcomeComponent } from '../../ui/chat-content/chat-welcome/chat-welcome.component';
import { DialogTransferComponent } from '../../ui/chat-content/dialog-transfer/dialog-transfer.component';
import { FieldSearchComponent } from '../../ui/chat-content/field-search/field-search.component';
import { TypingBarComponent } from '../../ui/chat-content/typing-bar/typing-bar.component';
import { ChatConversationItemComponent } from '../../ui/chat-conversation/chat-conversation-item/chat-conversation-item.component';
import { ChatConversationComponent } from '../../ui/chat-conversation/chat-conversation.component';
import { ChatErrorComponent } from '../chat-error/chat-error.component';
import { ChatComponent } from '../chat-feature/chat.component';
import { ChatNoChannelsAvailableComponent } from '../chat-no-channels-available/chat-no-channels-available.component';

const primeNGModules = [
	TooltipModule,
	AccordionModule,
	MenuModule,
	ButtonModule,
	DialogModule,
	RadioButtonModule,
	IconFieldModule,
	DropdownModule,
	BadgeModule
];

@NgModule({
	declarations: [
		ChatConversationComponent,
		ChatComponent,
		ChatContentComponent,
		ChatContentMessageComponent,
		TypingBarComponent,
		ChatWelcomeComponent,
		ChatErrorComponent,
		FieldSearchComponent,
		DialogTransferComponent,
		ChatConversationItemComponent,
		ChatNoChannelsAvailableComponent
	],
	imports: [
		LightboxModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ChatRoutingModule,
		TranslateModule,
		SimplebarAngularModule,
		PickerModule,
		CarouselModule,
		IntegrationPanelModule,
		InfiniteScrollModule,
		SharedModule,
		SweetAlert2Module.forRoot(),
		...primeNGModules
	],
	providers: [
		DatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		}
	],
	exports: []
})
export class ChatModule {}
