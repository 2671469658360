import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { SimplebarAngularModule } from 'simplebar-angular';
import { TimeToChangeModule } from '../../time-to-change/feature/time-to-change.module';
import { SelectIntegrationModuleComponent } from '../ui/select-integration-module/select-integration-module.component';
import { IntegrationPanelComponent } from './integration-panel.component';
import { ZohoModule } from '../../zoho/zoho.module';

@NgModule({
	declarations: [IntegrationPanelComponent, SelectIntegrationModuleComponent],
	imports: [CommonModule, SimplebarAngularModule, TranslateModule, TimeToChangeModule, ZohoModule],
	exports: [IntegrationPanelComponent]
})
export class IntegrationPanelModule {}
