import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoIntegrationBackendService } from 'src/app/integrations/zoho/data-access/zoho-integration-backend.service';
import { Record } from 'src/app/integrations/zoho/models/records-response.model';

@Component({
	selector: 'app-zohocrm-profile-banner',
	templateUrl: './profile-banner.component.html',
	styleUrls: ['./profile-banner.component.scss']
})
export class ProfileBannerComponent {

	@Output() emitRecordUpdate: EventEmitter<Map<string, any>> = new EventEmitter<Map<string, any>>();
	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  @Input("recordData") recordData?: Map<string, any> ;
  @Input("module") module?: string ;

  private _ZohoIntegrationBackendService = inject(ZohoIntegrationBackendService);

  updatingRecord = false;
  private subscriptions = new Subscription();

  errorHandler(error: HttpErrorResponse) {
		this.emitAction.emit({
			action: 'error',
			values: error,
      module: this.module
		});
	}

  updateRecord(): void {
    this.updatingRecord = true;
    const updateRecord$ = this._ZohoIntegrationBackendService
    .getRecord(this.module, this.recordData['id'])
    .subscribe((res: HttpResponse<Record>) => {
        if (res && res.body && res.body.data && res.body.data.length > 0) {
          this.recordData = res.body.data[0];
        }
        this.subscriptions.add(updateRecord$);
        this.updatingRecord = false;
    },
    (error: HttpErrorResponse) => {
      this.updatingRecord = false;
      this.errorHandler(error);
    });
  }

}
