import { Component, EventEmitter, HostListener, Output, inject } from '@angular/core';
import { emitData } from '../../../models/emitData.interface';

@Component({
	selector: 'app-init-zoho-crm',
	templateUrl: './init-zoho-crm.component.html',
	styleUrls: ['./init-zoho-crm.component.scss']
})
export class InitZohoCrmComponent {

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  cancel() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: ''
		});
	}
}
