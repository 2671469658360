<div class="col-md-6 offset-md-3 mt-5">
  <div class="alert alert-warning" role="alert">
    <div class="media">
      <div class="media-body ml-3">
        <h5 class="mt-0 alert-heading">Unauthorized</h5>
        You need to login before accessing that page.
      </div>
    </div>
  </div>
</div>
