import { IPager } from "./conversation.interface";

export interface IMessagePage {
  pager?: IPager;
  results?: IMessage[] | undefined;
}

export interface IMessage {
  id?: string;
  internalId?: string | undefined;
  from?: string | undefined;
  to?: string | undefined;
  channel?: number;
  message?: string | undefined;
  receivedOn?: number;
  messageType?: number;
  contact?: string;
}


export enum MessageType {
  Text = 0,
  Template = 1,
  Document = 2,
  Video = 3,
  Audio = 4,
  Sticker = 5,
  Reaction = 6,
  Location = 7,
  Image = 8
}
