import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth-config.module';
import { ChatModule } from './chat/feature/chat-shell/chat.module';
import { LayoutModule } from './layout/feature/layout.module';
import { CustomerState } from './shared/states/customer.state';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TokenTTCState } from './integrations/time-to-change/data-access/states/token-ttc.state';
import { AuthState } from './shared/states/auth.state';
import { SubscriptionState } from './shared/states/subs.state';
import { ContactRoutingModule } from './contact/feature/contact-routing.module';
export function createTranslateLoader(http: HttpClient): any {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		HttpClientModule,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		ChatModule,
		LayoutModule,
		NgxsModule.forRoot([AuthState, CustomerState, TokenTTCState, SubscriptionState], {
			developmentMode: !environment.production
		}),
		//  Logger config based on environment
		/**NOTE: Aqui se puede hacer una configuracion para enviar los logs a un enspoint del back. */
		LoggerModule.forRoot({
			level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
			// serverLogLevel
			//serverLoggingUrl: '/api/logs',
			serverLogLevel: NgxLoggerLevel.OFF
		}),
		/*NgxsLoggerPluginModule.forRoot({
			// logger: NGXLogger,
			disabled: !(environment.logLevel == NgxLoggerLevel.TRACE || environment.logLevel == NgxLoggerLevel.DEBUG)
		}),*/
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		AuthConfigModule,
  ContactRoutingModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {}
