<div class="container-list">
	<span class="title text-center">Ticket</span>
	<div class="d-flex justify-content-start col-md-3">
		<button type="button" class="btn btn-secondary btn-sm text-center btn-icon" style="width: 80%" (click)="cancel()">
			<i class="ri-arrow-left-fill"></i>
		</button>
	</div>
	<div class="ticket-list" *ngFor="let item of tickets">
		<app-ticket-list-item
			[supportT]="getSupportType(item.support_type_id)"
			[description]="item.description"
			[ticketT]="getTicketType(item.ticket_type_id)"
		></app-ticket-list-item>
	</div>
	<div *ngIf="moreTickets" class="d-flex justify-content-center mb-3 col-md-12">
		<button type="button" class="btn btn-primary btn-sm text-center btn-icon" (click)="searchTickets()">Ver más</button>
	</div>
</div>
