import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-no-channels-available',
  templateUrl: './chat-no-channels-available.component.html',
  styleUrls: ['./chat-no-channels-available.component.scss']
})
export class ChatNoChannelsAvailableComponent {

}
