<app-zohocrm-profile-banner *ngIf="loaded" [recordData]="recordData" [module]="module"></app-zohocrm-profile-banner>

<div *ngIf="loaded" class="card mt-2">
	<p-tabView [style]="{ marginTop: '10px;'}">
		<p-tabPanel>
      <ng-template pTemplate="header">
        <i
          pTooltip="{{ 'Integration.ZohoCRM.Information' | translate }}"
          class="pi pi-id-card"
        ></i>
      </ng-template>
			<div class="pt-4">
				<p-accordion [activeIndex]="0">
					<p-accordionTab header="{{ 'Integration.ZohoCRM.Basic information' | translate }}">
						<dl class="row-md jh-entity-details">
							<dt>
								<span>{{ 'Integration.ZohoCRM.Status' | translate }}</span>
							</dt>
							<dd>
								<span>{{
									recordData['Lead_Status']
										? recordData['Lead_Status']['value']
											? recordData['Lead_Status']['value']
											: ''
										: '-'
								}}</span>
							</dd>
							<dt>
								<span>{{ 'Integration.ZohoCRM.Email' | translate }}</span>
							</dt>
							<dd>
								<span>{{ recordData['Email'] }}</span>
							</dd>
							<dt>
								<span>{{ 'Integration.ZohoCRM.phone' | translate }}</span>
							</dt>
							<dd>
								<span>{{ recordData['Mobile'] }}</span>
							</dd>
						</dl>
						<hr />

						<div class="flex justify-content-center flex-wrap align-items-center">
							<p-button
								(click)="openOnZohoCRM(recordData['id'])"
                pTooltip="{{ 'Integration.ZohoCRM.open' | translate }}"
								icon="pi pi-globe"
								size="small"
								class="mr-2"
							/>
							<p-button
								(click)="edit()"
                pTooltip="{{ 'Integration.ZohoCRM.edit' | translate }}"
								icon="pi pi-pencil"
								size="small"
								class="mr-2"
							/>
							<p-button
								*ngIf="module === 'LEADS'"
                pTooltip="{{ 'Integration.ZohoCRM.convert' | translate }}"
								icon="pi pi-objects-column"
								size="small"
                (click)="convert()"
							/>
						</div>
					</p-accordionTab>

					<p-accordionTab header="{{ 'Integration.ZohoCRM.Notes' | translate }}">
						<form [formGroup]="noteForm" (ngSubmit)="addNote()">
							<div class="mb-3">
								<label for="exampleFormControlTextarea1" class="form-label">
									{{ 'Integration.ZohoCRM.new note' | translate }}
								</label>
								<textarea
									pInputTextarea
									formControlName="noteContent"
									class="form-control"
									id="exampleFormControlTextarea1"
									rows="3"
								>
								</textarea>
								<input type="hidden" formControlName="recordId" />
								<input type="hidden" formControlName="moduleZohoCrmType" />
							</div>

							<div class="flex justify-content-center flex-wrap align-items-center">
								<p-button
									label="{{ 'Integration.ZohoCRM.add' | translate }}"
									icon="pi pi-plus"
									size="small"
									class="mr-2"
									type="submit"
									[disabled]="savingNote || noteForm.invalid"
								/>
							</div>
						</form>

						<div *ngIf="loadingNotes" class="spinner-border mt-5" role="status">
							<span class="visually-hidden"></span>
						</div>
						<ul *ngIf="!loadingNotes && notes && notes.length > 0" class="list-group list-group-flush mt-2">
							<li *ngFor="let note of notes" class="list-group-item">
								<p style="font-weight: bold">{{ note.content }}</p>
								{{ note.owner }}
								<br />
								{{ note.createAt }}
							</li>
						</ul>
						<span *ngIf="!loadingNotes && (!notes || notes.length == 0)" class="mt-2">No existen notas</span>
						<a
							*ngIf="!loadingNotes && notes && notes.length > 0"
							(click)="openOnZohoCRM(recordData['id'])"
							class="icon-link icon-link-hover mt-2"
							href="#"
						>
							Mas notas
							<svg class="bi" aria-hidden="true"><use xlink:href="#arrow-right"></use></svg>
						</a>
					</p-accordionTab>
				</p-accordion>
			</div>
		</p-tabPanel>
		<p-tabPanel *ngIf="module === 'CONTACTS'">
			<ng-template pTemplate="header">
        <i
          pTooltip="{{ 'Integration.ZohoCRM.Opportunities' | translate }}"
          class="pi pi-check-square"
        ></i>
      </ng-template>
      <app-zohocrm-deals
				[recordData]="recordData"
				[module]="module"
				(emitAction)="emitOthersAction($event)"
			></app-zohocrm-deals>
		</p-tabPanel>
		<p-tabPanel>
      <ng-template pTemplate="header">
        <i
          pTooltip="{{ 'Integration.ZohoCRM.Activities' | translate }}"
          class="pi pi-list-check"
        ></i>
      </ng-template>
			<app-zohocrm-activities
				[module]="module"
				[recordData]="recordData"
				(emitAction)="emitOthersAction($event)"
			></app-zohocrm-activities>
		</p-tabPanel>
	</p-tabView>
</div>
