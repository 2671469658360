import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';

import { Store } from '@ngxs/store';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Subscription, forkJoin } from 'rxjs';
import { ConversationEventService } from 'src/app/chat/data-access/services/conversation-event.service';
import Swal from 'sweetalert2';
import { Contact } from '../data-access/interface/search-contact.type';
import { AccountTTCService } from '../data-access/services/account-ttc.service';
import { AuthenticationTTCService } from '../data-access/services/authentication-ttc.service';
import { ContactTTCService } from '../data-access/services/contact-ttc.service';
import { TicketService } from '../data-access/services/ticket.service';
import { LoadToken } from '../data-access/states/token-ttc.actions';
import { AccountFormComponent } from '../ui/account-form/account-form.component';
import { ContactFormComponent } from '../ui/contact-form/contact-form.component';
import { LoginFormComponent } from '../ui/login-form/login-form.component';
import { TicketFormComponent } from '../ui/ticket-form/ticket-form.component';
import { TicketListComponent } from '../ui/ticket-list/ticket-list.component';
@Component({
	selector: 'app-time-to-change',
	templateUrl: './time-to-change.component.html',
	styleUrls: ['./time-to-change.component.scss']
})
export class TimeToChangeComponent implements OnInit, OnDestroy {
	@ViewChild('childTTCComponents', { read: ViewContainerRef }) container!: ViewContainerRef;
	@ViewChild('loginSwal') loginSwal: SwalComponent;
	@ViewChild('saveContactSwal') saveContactSwal: SwalComponent;
	@ViewChild('saveAccountSwal') saveAccountSwal: SwalComponent;

	@Output() closeTTC: EventEmitter<any> = new EventEmitter<any>();

	private _AccountTTCService = inject(AccountTTCService);
	private _AuthenticationTTCService = inject(AuthenticationTTCService);
	private _ContactTTCService = inject(ContactTTCService);
	private _ConversationEventService = inject(ConversationEventService);
	private _ticketService = inject(TicketService);
	private store = inject(Store);

	public numberPhone = '57320';
	public options = { autoHide: false, scrollbarMinSize: 100 };

	//Contact
	public infoContac: Contact;

	private subscriptions = new Subscription();

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		this._ConversationEventService.getEvent().subscribe((data) => {
			this.numberPhone = data.clientPhone;
			this.container.clear();
			this.searchContact();
		});

		setTimeout(() => this.startLogin());
	}

	startLogin(): void {
		const onLoginComponent = this.container.createComponent(LoginFormComponent);

		const onLoginEvent$ = onLoginComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onLoginEvent$);
			if (values.action === 'login') {
				const authentication$ = this._AuthenticationTTCService.authentication(values.values).subscribe((resp) => {
					this.subscriptions.add(authentication$);
					this.loginSwal.fire();
					this.store.dispatch(new LoadToken(resp.token));
					this._AuthenticationTTCService.expiredDate = resp.expiredDate;
					this.container.clear();
					this.searchContact();
				});
			} else {
				this.closeTTC.emit();
			}
		});
	}

	searchContact(): void {
		const searchContact$ = this._ContactTTCService.searchContact(this.numberPhone).subscribe(
			(resp) => {
				this.subscriptions.add(searchContact$);
				if (resp !== null) this.infoContac = resp;
				this.startContact(resp !== null ? true : false, resp);
			},
			(error) => {
				this.startLogin();
			}
		);
	}

	startContact(contact_exists: boolean = false, contact: Contact): void {
		const onContactComponent = this.container.createComponent(ContactFormComponent);
		onContactComponent.instance.contact_exists = contact_exists;
		if (contact_exists) {
			onContactComponent.instance.form.patchValue({
				firstname: contact.firstname,
				lastname: contact.lastname,
				mobile_number: contact.mobile_number,
				email: contact.email,
				identification_type_id: contact.identification_type_id,
				identification: contact.identification,
				account_id: contact.account?.name,
				state: contact.state,
				city: contact.city,
				country: contact.country
			});
			onContactComponent.instance.form.disable();
			onContactComponent.instance.search = true;
		}

		onContactComponent.instance.form.controls.mobile_number.setValue(this.numberPhone);

		const onContactEvent$ = onContactComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onContactEvent$);
			switch (values.action) {
				case 'create contact':
					const createContact$ = this._ContactTTCService.createContact(values.values).subscribe((response) => {
						this.subscriptions.add(createContact$);
						const accoundId = Number(onContactComponent.instance.form.controls['account_id'].value);
						const createColinkContactToAccountntact$ = this._ContactTTCService
							.linkContactToAccount(accoundId, response.contact_id)
							.subscribe((_) => {
								this.subscriptions.add(createColinkContactToAccountntact$);
								this.saveContactSwal.fire();
								this.searchContact();
							});
					});
					break;
				case 'create ticket':
					this.container.clear();
					this.startCreateTicket();
					break;
				case 'view ticket':
					this.container.clear();
					this.startViewTicket();
					break;
				case 'create account':
					this.container.clear();
					this.startAccount();
					break;
				case 'back login':
					this.container.clear();
					this.startLogin();
					break;
			}
		});
	}

	startAccount(): void {
		const onAccountComponent = this.container.createComponent(AccountFormComponent);
		const onAccountEvent$ = onAccountComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onAccountEvent$);
			if (values.action === 'cancel') {
				this.container.clear();
				this.searchContact();
			} else {
				const createAccount$ = this._AccountTTCService.createAccount(values.values).subscribe(
					(_) => {
						this.subscriptions.add(createAccount$);
						this.saveAccountSwal.fire();
					},
					(error) => {
						this.startLogin();
					}
				);
			}
		});
	}

	startCreateTicket(): void {
		const getSupportType$ = this._ticketService.getSupportType();
		const getTicketType$ = this._ticketService.getTicketType();

		forkJoin([getSupportType$, getTicketType$]).subscribe(([getSupportType, getTicketType]) => {
			const onCreateTicketComponent = this.container.createComponent(TicketFormComponent);

			onCreateTicketComponent.instance.supportTicket = getSupportType;
			onCreateTicketComponent.instance.ticketType = getTicketType;

			onCreateTicketComponent.instance.infoContac = this.infoContac;
			onCreateTicketComponent.instance.assignData();

			const onTicketEvent$ = onCreateTicketComponent.instance.emitAction.subscribe(
				(values) => {
					this.subscriptions.add(onTicketEvent$);
					if (values.action === 'create') {
						const createTicket$ = this._ticketService.createTicket(values.values).subscribe((response) => {
							this.subscriptions.add(createTicket$);
							Swal.fire({
								toast: true,
								position: 'top-end',
								showConfirmButton: false,
								timer: 3000,
								timerProgressBar: true,
								title: `Se ha creado el Ticket #${response.data.ticket_id}.`,
								icon: 'success'
							});
							this.container.clear();
							this.searchContact();
						});
					} else {
						this.container.clear();
						this.searchContact();
					}
				},
				(error) => {
					this.startLogin();
				}
			);
		});
	}

	startViewTicket(): void {
		const getSupportType$ = this._ticketService.getSupportType();
		const getTicketType$ = this._ticketService.getTicketType();

		forkJoin([getSupportType$, getTicketType$]).subscribe(
			([getSupportType, getTicketType]) => {
				const onListTicketComponent = this.container.createComponent(TicketListComponent);

				onListTicketComponent.instance.supportTickets = getSupportType;
				onListTicketComponent.instance.ticketTypes = getTicketType;
				onListTicketComponent.instance.infoContac = this.infoContac;

				onListTicketComponent.instance.searchTickets();

				const onViewTicketsEvent$ = onListTicketComponent.instance.cancelTicketList.subscribe((_) => {
					this.subscriptions.add(onViewTicketsEvent$);
					this.container.clear();
					this.searchContact();
				});
			},
			(error) => {
				this.startLogin();
			}
		);
	}

	handleCloseTTC(): void {
		this.closeTTC.emit();
	}
}
