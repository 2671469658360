import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  public popupWindow: Window | null = null;

  constructor() { }

  redirectToZohoCRM(url: string) {
    this.popupWindow = window.open(url, '_blank', 'width=600,height=400');
    const interval = setInterval(() => {
      if (this.popupWindow.closed) {
        clearInterval(interval);
      }
    }, 1000);
  }

}
