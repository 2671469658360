import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoDeskBackendService } from '../../../data-access/zoho-desk-backend.service';

@Component({
  selector: 'app-tickets-form',
  templateUrl: './tickets-form.component.html',
  styleUrls: ['./tickets-form.component.scss']
})
export class TicketsFormComponent implements OnDestroy, OnInit {

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  private subscriptions = new Subscription();
  public contact?:  any;
  record: any;
  sending = false;
  loading = false;
  departments = [];

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    this.loading = true;
    setTimeout(() => {
      this.getDepartaments();
      this.record = this.contact;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit(data: emitData): void {
    this.sending = true;
    const createRecord$ = this._ZohoDeskBackendService
    .createTickets(data.values)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          this.emitAction.emit({
              action: 'create',
              values: data.values,
              module: ''
            });
        }
        this.sending = false;
        this.subscriptions.add(createRecord$);
    }, (error: HttpErrorResponse) => {
      this.sending = false;
      this.emitAction.emit({
        action: 'error',
        values: error,
        module: ''
      });
    });
  }

  cancel(): void {
    this.emitAction.emit({
      action: 'cancel',
      values: '',
      module: ''
    });
  }

  getDepartaments(): void {
    const getDepartament$ = this._ZohoDeskBackendService
    .getDepartaments()
    .subscribe((resp: HttpResponse<any[]>) => {
        if (resp.status == 200) {
          this.departments = resp.body;
        }
        this.loading = false;
        this.subscriptions.add(getDepartament$);
    }, (error: HttpErrorResponse) => {
      this.sending = false;
      this.emitAction.emit({
        action: 'error',
        values: error,
        module: ''
      });
    });
  }

}
