export interface Countrie {
	code: string;
	name: string;
	iso: string;
	example: string;
	selected: boolean;
}

export const CountrieList: Countrie[] = [
	{
		code: '+1',
		name: 'United States',
		iso: 'US',
		example: '555-555-5555',
		selected: false
	},
	{
		code: '+1',
		name: 'Canada',
		iso: 'CA',
		example: '416-555-5555',
		selected: false
	},
	{
		code: '+44',
		name: 'United Kingdom',
		iso: 'GB',
		example: '20 7946 0958',
		selected: false
	},
	{
		code: '+91',
		name: 'India',
		iso: 'IN',
		example: '98765 43210',
		selected: false
	},
	{
		code: '+61',
		name: 'Australia',
		iso: 'AU',
		example: '4 1234 5678',
		selected: false
	},
	{
		code: '+49',
		name: 'Germany',
		iso: 'DE',
		example: '30 123456',
		selected: false
	},
	{
		code: '+33',
		name: 'France',
		iso: 'FR',
		example: '1 23 45 67 89',
		selected: false
	},
	{
		code: '+81',
		name: 'Japan',
		iso: 'JP',
		example: '3-1234-5678',
		selected: false
	},
	{
		code: '+55',
		name: 'Brazil',
		iso: 'BR',
		example: '11 91234-5678',
		selected: false
	},
	{
		code: '+86',
		name: 'China',
		iso: 'CN',
		example: '10 1234 5678',
		selected: false
	},
	{
		code: '+7',
		name: 'Russia',
		iso: 'RU',
		example: '495 123-45-67',
		selected: false
	},
	{
		code: '+27',
		name: 'South Africa',
		iso: 'ZA',
		example: '21 123 4567',
		selected: false
	},
	{
		code: '+34',
		name: 'Spain',
		iso: 'ES',
		example: '912 34 56 78',
		selected: false
	},
	{
		code: '+39',
		name: 'Italy',
		iso: 'IT',
		example: '06 1234 5678',
		selected: false
	},
	{
		code: '+52',
		name: 'Mexico',
		iso: 'MX',
		example: '55 1234 5678',
		selected: false
	},
	{
		code: '+62',
		name: 'Indonesia',
		iso: 'ID',
		example: '21 1234 5678',
		selected: false
	},
	{
		code: '+54',
		name: 'Argentina',
		iso: 'AR',
		example: '11 1234-5678',
		selected: false
	},
	{
		code: '+64',
		name: 'New Zealand',
		iso: 'NZ',
		example: '21 123 4567',
		selected: false
	},
	{
		code: '+47',
		name: 'Norway',
		iso: 'NO',
		example: '21 23 45 67',
		selected: false
	},
	{
		code: '+46',
		name: 'Sweden',
		iso: 'SE',
		example: '8 123 456',
		selected: false
	},
	{
		code: '+591',
		name: 'Bolivia',
		iso: 'BO',
		example: '2 1234567',
		selected: false
	},
	{
		code: '+56',
		name: 'Chile',
		iso: 'CL',
		example: '2 1234 5678',
		selected: false
	},
	{
		code: '+57',
		name: 'Colombia',
		iso: 'CO',
		example: '3201234567',
		selected: false
	},
	{
		code: '+506',
		name: 'Costa Rica',
		iso: 'CR',
		example: '2123 4567',
		selected: false
	},
	{
		code: '+53',
		name: 'Cuba',
		iso: 'CU',
		example: '7 1234567',
		selected: false
	},
	{
		code: '+593',
		name: 'Ecuador',
		iso: 'EC',
		example: '2 123 4567',
		selected: false
	},
	{
		code: '+503',
		name: 'El Salvador',
		iso: 'SV',
		example: '2123 4567',
		selected: false
	},
	{
		code: '+502',
		name: 'Guatemala',
		iso: 'GT',
		example: '2 123 4567',
		selected: false
	},
	{
		code: '+504',
		name: 'Honduras',
		iso: 'HN',
		example: '2 123 4567',
		selected: false
	},
	{
		code: '+505',
		name: 'Nicaragua',
		iso: 'NI',
		example: '2 123 4567',
		selected: false
	},
	{
		code: '+507',
		name: 'Panama',
		iso: 'PA',
		example: '2 123 4567',
		selected: false
	},
	{
		code: '+595',
		name: 'Paraguay',
		iso: 'PY',
		example: '21 123 4567',
		selected: false
	},
	{
		code: '+51',
		name: 'Peru',
		iso: 'PE',
		example: '1 1234567',
		selected: false
	},
	{
		code: '+598',
		name: 'Uruguay',
		iso: 'UY',
		example: '2 123 4567',
		selected: false
	},
	{
		code: '+58',
		name: 'Venezuela',
		iso: 'VE',
		example: '212 1234567',
		selected: false
	}
];
