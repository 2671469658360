<div class="container-ttc">
	<div class="header-ttc">
		<i class="material-icons-outlined cursor-pointer" (click)="handleCloseTTC()">close</i>
	</div>
	<div class="body-ttc">
		<div #childTTCComponents></div>
		<!-- Dinamic components below -->
	</div>
</div>

<swal
	#loginSwal
	[toast]="true"
	position="top-end"
	[showConfirmButton]="false"
	[timer]="5000"
	[timerProgressBar]="true"
	title="Bienvenid@ Mesa"
	icon="success"
>
</swal>

<swal
	#saveContactSwal
	[toast]="true"
	position="top-end"
	[showConfirmButton]="false"
	[timer]="5000"
	[timerProgressBar]="true"
	title="Se ha creado el contacto exitosamente."
	icon="success"
>
</swal>

<swal
	#saveAccountSwal
	[toast]="true"
	position="top-end"
	[showConfirmButton]="false"
	[timer]="5000"
	[timerProgressBar]="true"
	title="Se ha creado el contacto exitosamente."
	icon="success"
>
</swal>
