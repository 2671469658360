<p-card
  [styleClass]="'mt-3 pt-3 pb-3'"
  [style]="{ textAlign: 'center' }"
  header="{{recordData['Full_Name']}}" subheader="{{ recordData['Company'] }}"
>
  <ng-template pTemplate="header">
    <div>
      <!-- src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp" -->
     <!-- [src]="recordData['Record_Image']" -->
      <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
      class="rounded-circle img-fluid" style="width: 80px;" />
    </div>
  </ng-template>

  <ng-template pTemplate="footer">

    <div class="flex justify-content-center flex-wrap align-items-center">
      <p-button
        (click)="updateRecord()"
        label="{{ 'Integration.ZohoCRM.Update' | translate }}"
        icon="pi pi-refresh"
        size="small"
        [disabled] = "updatingRecord"
      />
    </div>
  </ng-template>
</p-card>
