<form [formGroup]="form" class="form-contact">
	<span class="title">
		{{ 'Contact details' | translate }}
	</span>
	<div class="container-form">
		<div class="row-contact">
			<div class="col-contact-3">
				<span class="label">
					{{ 'Name' | translate }}
				</span>
				<input type="text" pInputText formControlName="name" class="custom-input" />
			</div>
			<div class="col-contact-3">
				<span class="label">
					{{ 'Last name' | translate }}
				</span>
				<input type="text" pInputText formControlName="lastName" class="custom-input" />
			</div>
		</div>
		<div class="row-contact">
			<div class="col-contact-3">
				<span class="label">
					{{ 'Phone number' | translate }}
				</span>
				<app-field-phonenumber></app-field-phonenumber>
			</div>
			<div class="col-contact-3">
				<span class="label">
					{{ 'Email' | translate }}
				</span>
				<input type="text" pInputText formControlName="email" class="custom-input" />
			</div>
		</div>
		<div class="row-contact">
			<div class="col-contact-3">
				<span class="label">
					{{ 'Birthday' | translate }}
				</span>
				<p-calendar [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay" class="custom-input" />
			</div>
			<div class="col-contact-3">
				<span class="label">
					{{ 'Gender' | translate }}
				</span>
				<p-dropdown [options]="genderOptions" />
			</div>
		</div>
		<div class="row-contact">
			<div class="col-contact-3">
				<span class="label">
					{{ 'ZIP code' | translate }}
				</span>
				<input type="text" pInputText class="custom-input" />
			</div>
			<div class="col-contact-3">
				<span class="label">
					{{ 'Address' | translate }}
				</span>
				<input type="text" pInputText class="custom-input" />
			</div>
		</div>
		<div class="row-contact">
			<div class="col-contact-3">
				<span class="label">
					{{ 'City' | translate }}
				</span>
				<input type="text" pInputText class="custom-input" />
			</div>
			<div class="col-contact-3">
				<span class="label">
					{{ 'State' | translate }}
				</span>
				<input type="text" pInputText class="custom-input" />
			</div>
		</div>
		<div class="row-contact">
			<div class="col-contact-3">
				<span class="label">
					{{ 'Country' | translate }}
				</span>
				<input type="text" pInputText class="custom-input" />
			</div>
			<div class="col-contact-3">
				<span class="label">
					{{ 'Language' | translate }}
				</span>
				<input type="text" pInputText class="custom-input" />
			</div>
		</div>
	</div>
</form>
