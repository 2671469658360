import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SimplebarAngularModule } from 'simplebar-angular';

import { FormlyModule } from '@ngx-formly/core';

import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { TableModule } from 'primeng/table';

import { formlyDeclaretionsTypes } from './utils/formly-declaretions-types';

import { InputFieldType } from './ui/formly-fields/input-field.type';
import { SelectFieldType } from './ui/formly-fields/select-field.type';
import { TextAreaFieldType } from './ui/formly-fields/textarea-field.type';
import { LookUpFieldType } from './ui/formly-fields/lookup-field.type';
import { DateFieldType } from './ui/formly-fields/date-field.type';
import { DateTimeFieldType } from './ui/formly-fields/date-time-field.type';
import { MultiSelectFieldType } from './ui/formly-fields/multi-select-field.type';
import { MultiSelectLookUpFieldType } from './ui/formly-fields/multi-select-lookup-field.type';
import { CheckboxType } from './ui/formly-fields/checkbox.type';

import { ZohoInterceptor } from './interceptor/Zoho.interceptor';

import { CardWrapperComponent } from './wrappers/card-wrapper.component';
import { ZohoCRMComponent } from './zoho-crm/feature/zoho-crm.component';
import { LoginFormComponent } from './ui/login-form/login-form.component';
import { IntegrationConfigComponent } from './zoho-crm/ui/integration-config/integration-config.component';
import { InitZohoCrmComponent } from './zoho-crm/ui/init-zoho-crm/init-zoho-crm.component';
import { LoadingZohoCrmComponent } from './ui/loading/loading.component';
import { ContactDetailsComponent } from './zoho-crm/ui/contact-details/contact-details.component';
import { RecordFormComponent } from './zoho-crm/ui/record-form/record-form.component';
import { RecordMultiplesRecordsComponent } from './zoho-crm/ui/record-multiples-results/record-multiples-records.component';
import { ProfileBannerComponent } from './zoho-crm/ui/contact-details/profile-banner/profile-banner.component';
import { ActivitiesComponent } from './zoho-crm/ui/contact-details/activities/activities.component';
import { FormlyComponent } from './ui/formly/formly.component';
import { ActivityFormComponent } from './zoho-crm/ui/contact-details/activities/form/activity-form.component';
import { DealsComponent } from './zoho-crm/ui/contact-details/deals/deals.component';
import { ActivityListComponent } from './zoho-crm/ui/contact-details/activities/list/activity-list.component';
import { DealsFormComponent } from './zoho-crm/ui/contact-details/deals/form/deals-form.component';
import { DealsListComponent } from './zoho-crm/ui/contact-details/deals/list/deals-list.component';
import { ZohoComponent } from './feature/zoho.component';
import { ZohoDeskComponent } from './zoho-desk/feature/zoho-desk.component';
import { ContactDeskDetailsComponent } from './zoho-desk/ui/contact-desk-details/contact-desk-details.component';
import { MultiplesContactsResultComponent } from './zoho-desk/ui/multiples-contacts-result/multiples-contacts-result.component';
import { ContactDeskFormComponent } from './zoho-desk/ui/contact-desk-form/contact-desk-form.component';
import { ContactDeskBannerComponent } from './zoho-desk/ui/contact-desk-details/contact-banner/contact-desk-banner.component';
import { TicketsListComponent } from './zoho-desk/ui/tickets/list/tickets-list.component';
import { TicketsFormComponent } from './zoho-desk/ui/tickets/form/tickets-form.component';
import { TicketsComponent } from './zoho-desk/ui/tickets/tickets.component';
import { LookUpAutocompleteFieldType } from './ui/formly-fields/lookup-autocomplete-field.type';
import { TicketsDetailsComponent } from './zoho-desk/ui/tickets/details/tickets-details.component';
import { TicketsDetailsHistoryComponent } from './zoho-desk/ui/tickets/details/history/tickets-details-history.component';
import { TicketsDetailsResolutionComponent } from './zoho-desk/ui/tickets/details/resolution/tickets-details-resolution.component';
import { TicketsDetailsMetricsComponent } from './zoho-desk/ui/tickets/details/metrics/tickets-details-metrics.component';
import { TicketsDetailsDescriptionComponent } from './zoho-desk/ui/tickets/details/description/tickets-details-description.component';


const customFormlyTypes = [
	InputFieldType,
	SelectFieldType,
	TextAreaFieldType,
	LookUpFieldType,
	DateFieldType,
	DateTimeFieldType,
	MultiSelectFieldType,
	MultiSelectLookUpFieldType,
	CheckboxType,
  LookUpAutocompleteFieldType
];

const primeNGModules = [
	InputTextareaModule,
	CalendarModule,
	InputNumberModule,
	MultiSelectModule,
	InputTextModule,
	DropdownModule,
	CardModule,
	DividerModule,
	FloatLabelModule,
	TabViewModule,
	InputGroupModule,
	CheckboxModule,
	AccordionModule,
  MenubarModule,
  MenuModule,
  ButtonModule,
  AutoCompleteModule,
  InputSwitchModule,
  BadgeModule,
  TableModule
];

@NgModule({
	declarations: [
    ZohoComponent,
		ZohoCRMComponent,
		LoginFormComponent,
		IntegrationConfigComponent,
		InitZohoCrmComponent,
		LoadingZohoCrmComponent,
		ContactDetailsComponent,
		RecordFormComponent,
		RecordMultiplesRecordsComponent,
		ProfileBannerComponent,
		CardWrapperComponent,
    ActivitiesComponent,
    FormlyComponent,
    ActivityFormComponent,
    DealsComponent,
    ActivityListComponent,
    DealsFormComponent,
    DealsListComponent,
    ZohoDeskComponent,
    ContactDeskFormComponent,
    ContactDeskDetailsComponent,
    MultiplesContactsResultComponent,
    ContactDeskBannerComponent,
    TicketsListComponent,
    TicketsFormComponent,
    TicketsComponent,
    TicketsDetailsComponent,
    TicketsDetailsHistoryComponent,
    TicketsDetailsResolutionComponent,
    TicketsDetailsMetricsComponent,
    TicketsDetailsDescriptionComponent,
		...customFormlyTypes
	],
	imports: [
		CommonModule,
		TranslateModule,
		SweetAlert2Module.forRoot(),
		ReactiveFormsModule,
		FormsModule,
		SimplebarAngularModule,
		NgScrollbarModule,
		HttpClientModule,
		FormlyPrimeNGModule,

		FormlyModule.forRoot({
			validationMessages: [{ name: 'required', message: 'This field is required' }],
			types: formlyDeclaretionsTypes,
			wrappers: [{ name: 'card', component: CardWrapperComponent }]
		}),

		...primeNGModules
	],

	exports: [ZohoCRMComponent],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ZohoInterceptor,
			multi: true
		}
	]
})
export class ZohoModule {}
