import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoDeskBackendService } from '../../../../data-access/zoho-desk-backend.service';

@Component({
  selector: 'app-tickets-details-description',
  templateUrl: './tickets-details-description.component.html',
  styleUrls: ['./tickets-details-description.component.scss']
})
export class TicketsDetailsDescriptionComponent implements OnDestroy, OnInit {

  private subscriptions = new Subscription();

  @Input("description") description?: string ;
  loading: boolean;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    this.loading = true;
    setTimeout(() => {
        this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
