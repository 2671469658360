import { Component, inject } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-input',
  template: `
    <div class="grid  mb-3">
      <div class="col-10">
        <span class="p-float-label">
          <ng-container *ngIf="isSearching; else multiSelectTemplate">
            <input
              class="w-full"
              pInputText
              id="username"
              [(ngModel)]="value"
            />
            <label for="username">{{ to['display_label'] }}</label>
          </ng-container>

          <ng-template #multiSelectTemplate>
            <p-multiSelect
              class="w-full"
              inputId="float-label"
              [formControl]="formControl"
              [formlyAttributes]="field"
              [options]="values"
              [filter]="false"
            ></p-multiSelect>
            <label for="float-label">{{ to['display_label'] }}</label>
          </ng-template>
        </span>
      </div>
      <div class="col-2 flex justify-content-center flex-wrap">
        <p-button
          [icon]="isSearching ? 'pi pi-search' : 'pi pi-times'"
          [severity]="isSearching ? 'danger' : 'secondary'"
          size="small"
          (click)="isSearching ? search() : reSearch()"
        ></p-button>
      </div>
    </div>
  `,
})
export class MultiSelectLookUpFieldType extends FieldType<FieldTypeConfig> {

  public isSearching = true;
  public value: string = '';
  public values: any[] = [];

  get type() {
    return this.props.type || 'text';
  }

  search(): void {
    this.values = [];
    this.isSearching = false;
  }

  reSearch(): void {
    this.isSearching = true;
    this.values = [];
  }
}
