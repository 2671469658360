import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Domains } from '../enums/domains.enum';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	private _oidcSecurityService = inject(OidcSecurityService);

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.includes(Domains.TimeToChange)) return next.handle(request);
		this._oidcSecurityService.getAccessToken().subscribe((token) => {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			});
		});

		return next.handle(request);
	}
}
