import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceBaseService } from 'src/app/shared/data-access/resource-base.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class IntegrationService extends ResourceBaseService<any> {
	constructor(http: HttpClient) {
		super(http);
	}

	protected apiPath(): string {
		return '/api/Integration';
	}
	protected baseUrl(): string {
		return environment.URL_API_SUBSCRIPTION;
	}
}
