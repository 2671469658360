<app-contact-desk-banner
  *ngIf="loaded"
  [contact]="contact"
></app-contact-desk-banner>

<div *ngIf="loaded" class="pt-4">
  <app-tickets
    [contact]="contact"
    (emitAction)="emitOthersAction($event)"
  ></app-tickets>
</div>
