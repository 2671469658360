import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './shared/data-access/login.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	constructor(public translate: TranslateService, private loginService: LoginService) {
		// this.oidcSecurityService.authorize();

		translate.addLangs(['en', 'es', 'it', 'ru', 'de']);
		translate.setDefaultLang('en');

		const browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|it|es|ru|de/) ? browserLang : 'en');
	}

	ngOnInit() {
		this.loginService.handleLogin();
	}
}
