import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivityFormComponent } from './form/activity-form.component';
import { ActivityListComponent } from './list/activity-list.component';
import { MenuItem } from 'primeng/api';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';

@Component({
	selector: 'app-zohocrm-activities',
	templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {

  @ViewChild('childActivitiesComponents', { read: ViewContainerRef }) container!: ViewContainerRef;

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();
  @Input("recordData") recordData?: Map<string, any> ;
  @Input("module") module?: string;
  public subModule?: string;

  private subscriptions = new Subscription();

  items: MenuItem[] | undefined;


  ngOnInit(): void {
    setTimeout(() => {
      this.refreshListActivity(false);
    });
  }

  createActivity(subModule: string): void {
    this.container.clear();
    const activityFormComponent = this.container.createComponent(ActivityFormComponent);
    activityFormComponent.instance.module = this.module;
    activityFormComponent.instance.subModule = subModule;
    activityFormComponent.instance.recordData = this.recordData;
    const activityFormEvent$ = activityFormComponent.instance.emitAction.subscribe((values) => {
        this.subscriptions.add(activityFormEvent$);
        if (values.action === 'create') {
          this.emitAction.emit({
            action: 'created-activity',
            values: '',
            module: this.module
          });
          this.refreshListActivity(true);
        } else {
          if (values.action === 'error') {
            this.emitAction.emit(values);
          } else {
            if (values.action === 'cancel') {
              this.refreshListActivity(false);
            }
          }
        }
    });
	}

  refreshListActivity(updateCache: boolean): void {
    this.container.clear();
    const activityFormComponent = this.container.createComponent(ActivityListComponent);
    activityFormComponent.instance.module = this.module;
    activityFormComponent.instance.recordData = this.recordData;
    activityFormComponent.instance.updateCache = updateCache;
	}

}
