<p-dialog
	header="Header"
	[closable]="true"
	[(visible)]="isVisible"
	[modal]="true"
	[style]="{ width: '480px' }"
	[draggable]="false"
	[resizable]="false"
	(onHide)="onModalClose()"
>
	<ng-template pTemplate="header">
		<h4 class="modal-title" id="modal-basic-title">{{ 'Transfer Chat' | translate }}</h4>
	</ng-template>
	<div class="modal-content">
		<span>{{ 'Transfer to' | translate }}</span>
		<div class="d-flex justify-content-center">
			<div class="form-check form-check-inline">
				<p-radioButton
					name="pizza"
					inputId="agentRadio"
					value="agent"
					[(ngModel)]="toTransfer"
					(ngModelChange)="handleTransferTo()"
				/>
				<label for="agentRadio" class="ml-2"> {{ 'Agent' | translate }} </label>
			</div>
			<div class="form-check form-check-inline">
				<p-radioButton
					name="pizza"
					inputId="botRadio"
					value="bot"
					[(ngModel)]="toTransfer"
					(ngModelChange)="handleTransferTo()"
				/>
				<label for="botRadio" class="ml-2"> {{ 'Bot' | translate }} </label>
			</div>
		</div>

		<input
			type="text"
			pInputText
			class="form-control form-control-lg bg-light border-light"
			placeholder="{{ 'Enter Message' | translate }}"
			[(ngModel)]="messageTransfer"
			(ngModelChange)="handleMessage()"
      />

		<p-dropdown
			styleClass="bg-light border-light"
			[options]="options"
			[(ngModel)]="agentTransfer"
			(ngModelChange)="handleMessage()"
			appendTo="body"
		/>
	</div>

	<ng-template pTemplate="footer">
		<p-button
			[label]="'Transfer' | translate"
			styleClass="px-6 mr-0"
			[disabled]="chatTransferDisabled"
			(click)="handleTransfer()"
		></p-button>
	</ng-template>
</p-dialog>
