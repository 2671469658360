import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { PhoneMaskService } from 'src/app/shared/data-access/phone-mask.service';
import { Countrie, CountrieList } from 'src/app/shared/utils/countries_field';

@Component({
	selector: 'app-field-phonenumber',
	templateUrl: './field-phonenumber.component.html',
	styleUrls: ['./field-phonenumber.component.scss']
})
export class FieldPhonenumberComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
	@ViewChild('dropdown', { static: false }) dropdown!: ElementRef;

	@Input() label = 'Phonenumber';
	@Input() placeholder = 'Select filters';
	@Input() initialValue: string = '';
	@Input() isModal: boolean = false;
	@Input() isDisabled: boolean = false;
	@Input() isComponentTemplate: boolean = false;

	@Output() emitValue: EventEmitter<string> = new EventEmitter<string>();

	private elementRef = inject(ElementRef);
	private phoneMaskService = inject(PhoneMaskService);
	private fb = inject(FormBuilder);

	public langs: Countrie[] = CountrieList;
	public langsAvaileble: Countrie[] = CountrieList;

	public extensionCodeSelected: Countrie = {
		code: '+1',
		name: 'Canada',
		iso: 'CA',
		example: '416-555-5555',
		selected: true
	};
	public showOptions: boolean = false;
	public valueSelect: string = '';
	public searchValue: string = '';
	public phoneMask: string = this.getMaskFromExample(this.phoneMaskService.getPhoneMask('CA'));

	public phoneForm: FormGroup = this.fb.group({
		phoneNumber: ['', [Validators.required]]
	});

	ngOnInit(): void {
		this.phoneForm.controls['phoneNumber'].valueChanges.subscribe((value) => {
			this.handleValueField();
		});

		const examplePhonenumber: string = this.phoneMaskService.getPhoneMask(this.extensionCodeSelected.iso);
		this.extensionCodeSelected.example = examplePhonenumber;
		this.phoneMask = this.getMaskFromExample(examplePhonenumber);

		window.addEventListener('scroll', this.handleScroll, true);
	}

	ngAfterViewInit(): void {
		this.setPosition();
	}

	ngOnChanges(): void {
		if (this.initialValue) this.setInitialValue(this.initialValue);

		if (this.isDisabled) {
			this.phoneForm.disable();
		}
	}

	ngOnDestroy(): void {
		window.removeEventListener('scroll', this.handleScroll, true);
	}

	private handleScroll = (event: Event): void => {
		const target = event.target as HTMLElement;
		if (this.dropdown && !this.dropdown.nativeElement.contains(target)) {
			this.showOptions = false;
		}
	};

	viewOptions(): void {
		if (!this.isDisabled) {
			this.showOptions = !this.showOptions;
			if (this.showOptions) {
				setTimeout(() => {
					this.setPosition();
				}, 0);
			}
		}
	}

	handleOptionsClick(event: MouseEvent) {
		event.stopPropagation();
	}

	handleSelectedOption(extensionCodeSelected: Countrie): void {
		this.langs = this.langs.map((lang) =>
			lang === extensionCodeSelected ? { ...lang, selected: true } : { ...lang, selected: false }
		);
		this.extensionCodeSelected = extensionCodeSelected;
		const examplePhonenumber: string = this.phoneMaskService.getPhoneMask(this.extensionCodeSelected.iso);
		this.extensionCodeSelected.example = examplePhonenumber;
		this.phoneMask = this.getMaskFromExample(examplePhonenumber);
		this.showOptions = false;
	}

	handleSearch(): void {
		this.langs = this.langsAvaileble
			.filter((lang) => lang.name.toLowerCase().includes(this.searchValue.toLowerCase()))
			.map((lang) => ({
				...lang,
				selected: lang === this.extensionCodeSelected
			}));
	}

	@HostListener('document:click', ['$event'])
	handleDocumentClick(event: MouseEvent) {
		const clickedElement = event.target as HTMLElement;
		if (!this.elementRef.nativeElement.contains(clickedElement)) {
			this.showOptions = false;
		}
	}

	private setPosition(): void {
		if (this.dropdown && this.elementRef) {
			const rect = this.elementRef.nativeElement.getBoundingClientRect();
			const viewportHeight = window.innerHeight;

			{
				// Hay suficiente espacio abajo, desplegar hacia abajo desde la parte inferior del elemento
				this.dropdown.nativeElement.classList.remove('upward');
				this.dropdown.nativeElement.style.top = `${rect.bottom}px`;
				this.dropdown.nativeElement.style.bottom = 'auto';
			}
		}
	}

	private getMaskFromExample(example: string): string {
		return example.replace(/\d/g, '9');
	}

	handleValueField(): void {
		const isValid = this.phoneMaskService.isValidPhoneNumber(
			`${this.extensionCodeSelected.code}${this.phoneForm.controls['phoneNumber'].value}`
		);
		if (isValid) {
			this.emitValue.emit(`${this.extensionCodeSelected.code}${this.phoneForm.controls['phoneNumber'].value}`);
			this.phoneForm.controls['phoneNumber'].setErrors(null);
		} else {
			this.phoneForm.controls['phoneNumber'].setErrors({ invalidPhoneNumber: true });
		}
	}

	setInitialValue(phoneNumber: string): void {
		const parsedNumber = parsePhoneNumberFromString(phoneNumber.trim().replace(/[\s-]/g, ''));
		if (parsedNumber) {
			const countryCode = parsedNumber.countryCallingCode;
			const nationalNumber = parsedNumber.nationalNumber;

			const country = this.langsAvaileble.find((lang) => lang.code === `+${countryCode}`);
			if (country) {
				this.handleSelectedOption(country);
				this.phoneForm.controls['phoneNumber'].setValue(nationalNumber);
			}
		}
	}
}
