import { HttpEventType } from '@angular/common/http';
import { Component, Input, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';
import { ICustomerFeature } from 'src/app/chat/data-access/interface/cutomer-feature.interface';
import { Message } from 'src/app/chat/data-access/models/message';
import { MessagingHubService } from 'src/app/chat/data-access/services/messaging-hub.service';
import { calculateDownloadProgress } from 'src/app/chat/utils/downloadProgress';

@Component({
	selector: 'app-chat-content-message',
	templateUrl: './chat-content-message.component.html',
	styleUrls: ['./chat-content-message.component.scss']
})
export class ChatContentMessageComponent {
	private _messagingService = inject(MessagingHubService);
	private sanitizer = inject(DomSanitizer);
	private translate = inject(TranslateService);
	private lightbox = inject(Lightbox);

	@Input() message: Message;
	@Input() isFriend: boolean = true;
	@Input() customerFeatureStorage: ICustomerFeature;

	@Input() messageGruop: any;

	progress: number;
	progressState: boolean;

	//Aqui la funcion lo que debe es dar el icono de acuerdo al mime type del fichero
	getMessageMimeTypeIconClass(): string {
		switch (this.messageGruop.message.mime_type) {
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				return 'ri-file-word-fill';
			case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				return 'ri-file-ppt-fill';
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return 'ri-file-excel-fill';
			case 'application/x-rar-compressed':
				return 'ri-file-zip-fill';
			case 'application/x-msdownload':
				return 'ri-code-box-fill';
			case 'application/pdf':
				return 'ri-file-pdf-fill';
			default:
				return 'ri-file-line';
		}
	}

	getTime(timestamp: number): string {
		let date = new Date(timestamp * 1000);
		let language = this.translate.currentLang + '-' + this.translate.currentLang.toUpperCase();
		return date.toLocaleDateString(language, {
			weekday: 'long',
			hour: '2-digit',
			minute: '2-digit'
		});
	}

	openImage(): void {
		let _album = [];
		const blobUrl = this.messageGruop.message.url.changingThisBreaksApplicationSecurity;
		const albumItem = { src: blobUrl, caption: 'Imagen Blob', thumb: blobUrl };
		_album.push(albumItem);

		this.lightbox.open(_album, 0, {
			showZoom: true
		});
	}

	downloadFile() {
		const link = document.createElement('a');
		this.progressState = true;
		let setups = this.customerFeatureStorage.config;
		this._messagingService
			.downloadFile(this.messageGruop.message.id, this.customerFeatureStorage.featureId, setups.Setups[0].SetupId)
			.subscribe((event) => {
				this.progress = calculateDownloadProgress(event);
				if (this.progress === 100) {
					this.progress = 0;
					this.progressState = false;
				}
				if (event.type === HttpEventType.Response) {
					this.messageGruop.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
					link.href = this.messageGruop.message.url.changingThisBreaksApplicationSecurity;
					link.download = this.getFileName();
					link.dispatchEvent(new MouseEvent('click'));
				}
			});
	}

	getFileName() {
		if (this.messageGruop.message.filename) return this.messageGruop.message.filename;
		if (this.messageGruop.message.fileName) return this.messageGruop.message.fileName;
		if (this.messageGruop.message.FileName) return this.messageGruop.message.FileName;
	}
}
