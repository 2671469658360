import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../models/page';

@Injectable({
	providedIn: 'root'
})
export abstract class ResourceBaseService<T> {
	protected constructor(protected http: HttpClient) {}

	protected abstract baseUrl(): string;
	protected abstract apiPath(): string;

	protected apiUrl(): string {
		return `${this.baseUrl()}${this.apiPath()}`;
	}

	queryPage(offset: number = 0, limit: number = 10, params: any = {}): Observable<Page<T>> {
		let headers = new HttpHeaders().set('accept', 'text/plain');

		let url = `${this.apiUrl()}`;

		let queryParams = new HttpParams().set('offset', offset).set('limit', limit);

		if (Object.keys(params).length !== 0) {
			if (params.search) {
				queryParams = queryParams.set('search', params.search);
			}
			if (params.filter) {
				const filters = params.filter;
				Object.keys(filters).forEach((key) => {
					const filterValues = filters[key];
					if (Array.isArray(filterValues)) {
						filterValues.forEach((value) => {
							queryParams = queryParams.append(`${key}`, value);
						});
					} else {
						queryParams = queryParams.append(`${key}`, filterValues);
					}
				});
			}
			if (params.sort) {
				queryParams = queryParams.set('sort', params.sort);
			}
		}

		return this.http.get<Page<T>>(`${url}`, {
			headers: headers,
			params: queryParams
		});
	}

	queryPageParams(offset: number = 0, limit: number = 10, params: string = ''): Observable<Page<T>> {
		let url = `${this.apiUrl()}?offset=${offset}&limit=${limit}`;

		if (params.length !== 0) {
			url += `&${params}`;
		}
		return this.http.get<Page<T>>(url);
	}

	get(id: string): Observable<T> {
		return this.http.get<T>(`${this.apiUrl()}/${id}`);
	}

	post(item: T): Observable<HttpResponse<any>> {
		return this.http.post<HttpResponse<any>>(this.apiUrl(), item);
	}

	put(id: string, item: T): Observable<HttpResponse<any>> {
		return this.http.put<HttpResponse<any>>(`${this.apiUrl()}/${id}`, item);
	}

	delete(id: string): Observable<HttpResponse<any>> {
		return this.http.delete<HttpResponse<any>>(`${this.apiUrl()}/${id}`);
	}
}
