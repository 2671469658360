<!-- start chat conversation section -->
<div class="w-100 overflow-hidden position-relative">
	<div class="container-conversation-header user-chat-topbar">
		<div class="flex flex-row align-items-center justify-content-between">
			<div class="flex flex-row gap-2">
				<div class="flex flex-row align-items-center gap-3">
					<div class="avatar-xs">
						<span class="avatar-title rounded-circle bg-primary-subtle text-primary">
							{{ fullName.charAt(0) }}
						</span>
					</div>

					<div class="flex flex-column gap-2">
						<div class="container-name-phone">
							<span class="name">{{ fullName }}</span>
							<span class="phoneNumber">{{ phoneNumber }}</span>
						</div>
						<div>
							<div class="primary-badge">
								<i [class]="classChannel"></i>
								{{ nameChannel }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex flex-row gap-2">
				<div class="item-action" [ngClass]="{ 'item-action-active': optionActive[0] }" (click)="activeOption(0)">
					<app-field-search
						class="item-class"
						[showField]="showSearchMessage"
						(emitValue)="MessageSearch($event)"
					></app-field-search>
				</div>
				<div class="item-action" [ngClass]="{ 'item-action-active': optionActive[1] }" (click)="activeOption(1)">
					<app-mini-card-contact
						class="item-class"
						[fullName]="fullName"
						[phoneNumber]="phoneNumber"
						[showField]="showInfoContact"
						(emitValue)="handleContact()"
					></app-mini-card-contact>
				</div>
				<div
					class="item-action"
					[ngClass]="{ 'item-action-active': optionActive[2] }"
					pTooltip="{{ 'Transfer Chat' | translate }}"
					tooltipPosition="left"
					(click)="activeOption(2)"
				>
					<i class="material-icons-outlined">textsms</i>
				</div>
				<div
					class="item-action"
					[ngClass]="{ 'item-action-active': optionActive[3] }"
					pTooltip="{{ 'End chat' | translate }}"
					tooltipPosition="left"
					(click)="activeOption(3)"
				>
					<i class="material-icons-outlined" placement="bottom">power_settings_new</i>
				</div>
			</div>
		</div>
	</div>
	<!-- end chat user head -->

	<!-- start chat conversation -->

	<div
		#scrollBar
		infinite-scroll
		[infiniteScrollDistance]="scrollDistance"
		[infiniteScrollUpDistance]="scrollUpDistance"
		[infiniteScrollThrottle]="throttle"
		[scrollWindow]="true"
		(scrolledUp)="onScrollUp($event)"
		class="chat-conversation p-3 p-lg-4 w-100 scroll-custom"
	>
		<div class="">
			<ul class="list-unstyled mb-0" id="users-conversation">
				<ng-container *ngFor="let groupMessage of groupedMessagesByDate; let i = index">
					<app-chat-content-message
						[messageGruop]="groupMessage"
						[isFriend]="groupMessage.from !== businnessPhoneNumber"
						[fullName]="getName(groupMessage)"
						[customerFeatureStorage]="customerFeatureStorage"
					></app-chat-content-message>
				</ng-container>
			</ul>
		</div>
	</div>
	<!-- end chat conversation end -->

	<!-- start chat input section -->
	<div class="chat-input-section p-3 p-lg-4 border-top mb-0 send-msg">
		<app-typing-bar
			[phoneNumber]="phoneNumber"
			[from]="businnessPhoneNumber"
			[serviceInstanceData]="serviceInstanceData"
			[profile]="profile"
			[setupId]="setupId"
			(messageSend)="receiveMessageSender($event)"
		></app-typing-bar>
	</div>
	<!-- end chat input section -->
</div>
<!-- end chat conversation section -->

<app-dialog-transfer
	[isVisible]="isVisibleDialogTransfer"
	[options]="options"
	[optionsAgents]="optionsAgents"
	[optionsBots]="optionsBots"
	(dialogClosed)="isVisibleDialogTransfer = $event"
	(emitValue)="handleMessageTransfer($event)"
></app-dialog-transfer>
