import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, Output, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageType } from 'src/app/chat/data-access/enum/message-type.enum';
import { Message, MessageContent } from 'src/app/chat/data-access/models/message';
import { MessagingHubService } from 'src/app/chat/data-access/services/messaging-hub.service';
import { MimetypeService } from 'src/app/chat/data-access/services/mimetype.service';
import { ICustomer } from 'src/app/shared/interfaces/customer.interface';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import Swal from 'sweetalert2';
import { v4 as uuid } from 'uuid';

@Component({
	selector: 'app-typing-bar',
	templateUrl: './typing-bar.component.html',
	styleUrls: ['./typing-bar.component.scss']
})
export class TypingBarComponent {
	@Input() phoneNumber: string = '';
	@Input() from: string = '';
	@Input() serviceInstanceData: IServiceInstance | undefined = undefined;
  @Input() setupId: string = '';
  @Input() profile: ICustomer | undefined = undefined ;

	@Output() messageSend: EventEmitter<Message> = new EventEmitter<Message>();

	private _messagingService = inject(MessagingHubService);
	private _mimetypeService = inject(MimetypeService);
	private sanitizer = inject(DomSanitizer);

	message: string = ''; //TODO: Hay que ajustar el contenido del mensaje para que pueda soportar mensajes de media (objeto)

	//Emoji
	showEmojiPicker = false;
	emoji = '';

	// File Upload
	fileCaptured: File;
	progress: number;
	progressState: boolean;
	img: any;

	onFocus() {
		this.showEmojiPicker = false;
	}
	onBlur() {}

	toggleEmojiPicker() {
		this.showEmojiPicker = !this.showEmojiPicker;
	}

	addEmoji(event: any) {
		const { emoji } = this;
		const text = `${emoji}${event.emoji.native}`;
		this.message = text;
		this.showEmojiPicker = false;
	}

	captureMedia(event: any): any {
		this.fileCaptured = event.target.files[0];
		let bytes = Number((this.fileCaptured.size / 1048576).toFixed(2));
		if ((this.fileCaptured.type === 'image/jpeg' || this.fileCaptured.type === 'image/png') && bytes >= 5) {
			this.alert();
			return;
		} else if ((this.fileCaptured.type === 'video/mp4' || this.fileCaptured.type === 'video/3gp') && bytes >= 16) {
			this.alert();
			return;
		} else if (bytes >= 100) {
			this.alert();
			return;
		}
		this.sendMedia();
	}

	alert() {
		Swal.fire({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: true,
			title: 'Limite excedido.',
			icon: 'warning'
		});
	}

	sendMedia() {
		this.progressState = true;
		//this.toBase64(this.fileCaptured).then((imageBase64) => {
		this._messagingService
			.uploadFile(this.fileCaptured, this.serviceInstanceData.id, this.setupId)
			.subscribe((event) => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress = Math.round((100 * event.loaded) / event.total); //Usar esta parte para hacer un progress
					if (this.progress === 100) {
						this.progress = 0;
						this.progressState = false;
					}
				} else if (event.type === HttpEventType.Response) {
					let fileId = event.body.id;
					// Implementar servicio que devuelva el tipo de media a partir del type del fichero
					let type = this._mimetypeService.getMessageType(this._mimetypeService.mapFile(this.fileCaptured)?.type!);
					let messageContent = this.createMessageContent(fileId, type, this.fileCaptured);

					this.dispatchMessage(messageContent, type);
				}
			});
	}

	createMessageContent(id: string, messageType: MessageType, fileCaptured: File): MessageContent {
		let commonValues = {
			FileName: fileCaptured.name,
			Caption: fileCaptured.name
		};
		switch (messageType) {
			case MessageType.Text:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Template:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Document:
				return { id: id, ...commonValues, mime_type: fileCaptured.type };
			case MessageType.Video:
				return {
					id: id,
					Caption: fileCaptured.name,
					mime_type: fileCaptured.type
				};
			case MessageType.Audio:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Sticker:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Reaction:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Location:
				return { id: id, mime_type: fileCaptured.type };
			case MessageType.Image:
				return { id: id, mime_type: fileCaptured.type };
			default:
				return { id: id, mime_type: fileCaptured.type };
		}
	}

	messageButtonClick() {
		if (this.message != null || this.message != '') this.dispatchMessage(this.message, MessageType.Text);
	}

	dispatchMessage(messageContent: any, messageType: MessageType = MessageType.Text) {
		let timestamp = Math.floor(Date.now() / 1000);

		//  Si el mensaje es tipo texto se envia, pero si es de los tipos de media (audio, video, document, sticker) se crea objeto y se serializa en string
		let messageObj = new Message(uuid(), null, messageContent, timestamp, messageType, this.phoneNumber, this.from, this.profile.given_name);

		if ([MessageType.Image, MessageType.Video, MessageType.Audio].includes(messageObj.messageType)) {
			this._messagingService
				.downloadFile(messageObj.message.id, this.serviceInstanceData.id, this.setupId)
				.subscribe((event) => {
					if (event.type === HttpEventType.Response) {
						messageObj.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
					}
				});
		}
		this.messageSend.emit(messageObj);
		this.message = '';
	}

	@HostListener('keydown', ['$event'])
	onKeydown(event: KeyboardEvent) {
		if (event.key === 'Enter' && !event.shiftKey) {
			// Si se presiona Enter sin presionar Shift, envía el mensaje
			event.preventDefault(); // Evita que se cree una nueva línea en el cuadro de entrada
			this.dispatchMessage(this.message, MessageType.Text);
		} else if (event.key === ' ' && event.shiftKey) {
			// Si se presiona Espacio + Shift, envía el mensaje
			event.preventDefault(); // Evita que se cree un espacio en el cuadro de entrada
			//this.sendMessage();
		}
	}
}
