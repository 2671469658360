  <div class="container-body">
    <div class="alert alert-warning" role="alert">
      {{ 'Integration.ZohoCRM.Several records have been found with the same phone number, press which one you want to operate with' | translate }}
    </div>
    <div class="card" style="width: 18rem;">
      <div class="card-header">
        {{ 'Integration.ZohoCRM.contacts' | translate }}
      </div>
      <ul id="fullNames" class="list-group list-group-flush">
        <li *ngFor="let contact of contacts" class="list-group-item list-group-item-action" (click)="selected(contact)">
           {{ contact.firstName }} {{ contact.lastName }}
        </li>
      </ul>
    </div>
  </div>
