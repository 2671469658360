import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ICustomer } from 'src/app/shared/interfaces/customer.interface';
import { LoadCustomer } from './customer.actions';
declare const Zone: any;

export class CustomerStateModel {
	customer?: ICustomer | null;
}

const defaults: CustomerStateModel = {
	customer: null
};

@State<CustomerStateModel>({
	name: 'cust',
	defaults
})
@Injectable()
export class CustomerState {
	@Selector()
	static getCustomer(state: CustomerStateModel): ICustomer {
		let customer: any = state;
		return customer;
	}

	@Action(LoadCustomer)
	LoadCustomer(ctx: StateContext<ICustomer>, action: LoadCustomer) {
		ctx.setState(action.customer);
	}
}
