import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ISubscription } from '../interfaces/subscription.interface';

@Injectable({
	providedIn: 'root'
})
export class CustomerService {
	private http = inject(HttpClient);

	private baseUrl: string = environment.URL_API_SUBSCRIPTION;
	private baseUrlIdentity: string = environment.URL_IDENTITY_API;

	getCustomerSubscription(): Observable<ISubscription> {
		let url_ = this.baseUrl + '/api/Customer/subscription';

		return this.http.get<ISubscription>(url_);
	}
}
