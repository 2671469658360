import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IntegrationConfigZohoCrm } from '../models/integration-config-zoho-crm';
import { IntegrationConfigResponse } from '../models/integration-config-response.model';
import { Record, RecordResponse, SearchLookupResponse } from '../models/records-response.model';
import { NoteRequest } from '../models/note-request.model';
import { NoteResponse } from '../models/note-response.model';
import { LayoutMetadata } from '../models/layout-metadata.model';
import { Organization } from '../models/organization-response.model';
import { Activity } from '../models/activity.model';

type EntityResponseVoidType = HttpResponse<void>;

@Injectable({
	providedIn: 'root'
})
export class ZohoIntegrationBackendService {
	private _http = inject(HttpClient);

	private baseUrl = environment.URL_API_ZOHO;
  private baseCrmUrl = this.baseUrl + '/crm';

	registerIntegration(): Observable<HttpResponse<Organization>> {
		const URL = this.baseCrmUrl + '/company';
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<Organization>(URL, { observe: 'response', headers: headers });
	}

  closeIntegration(): Observable<HttpResponse<void>> {
		const URL = this.baseCrmUrl + '/integration/close';
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<void>(URL, { observe: 'response', headers: headers });
	}

  registerIntegrationConfig(integrationConfigZohoCrm: IntegrationConfigZohoCrm): Observable<HttpResponse<LayoutMetadata>> {
    const URL = this.baseCrmUrl + '/integration-config';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<LayoutMetadata>(
      URL,
      integrationConfigZohoCrm,
      { observe: 'response', headers: headers  }
    );
  }

  getLayoutsConfig(): Observable<HttpResponse<IntegrationConfigResponse>> {
		const URL = this.baseCrmUrl + '/integration-config';
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<IntegrationConfigResponse>(URL, { observe: 'response', headers: headers });
	}

  searchRecords(phone: string): Observable<HttpResponse<RecordResponse[]>> {
		const URL = this.baseCrmUrl + '/records/search/' + phone;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<RecordResponse[]>(URL, { observe: 'response', headers: headers });
	}

  saveNote(noteRequest: NoteRequest): Observable<EntityResponseVoidType> {
    const URL = this.baseCrmUrl + '/note';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<void>(
      URL,
      noteRequest,
      { observe: 'response', headers: headers  }
    );
  }

  getNotes(module: string, recordId: string, update: boolean): Observable<HttpResponse<NoteResponse[]>> {
		const URL = this.baseCrmUrl + '/note/module/' + module + '/id/' + recordId + '?update='+update;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<NoteResponse[]>(URL, { observe: 'response', headers: headers });
	}

  getRecord(module: string, id: string): Observable<HttpResponse<Record>> {
		const URL = this.baseCrmUrl + '/records/' + module + '/' + id;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<Record>(URL, { observe: 'response', headers: headers });
	}

  searchLookUpByTerm(module: string, term: string): Observable<HttpResponse<SearchLookupResponse[]>> {
		const URL = this.baseCrmUrl + '/records/search/lookup/module/' + module + "/term/" + term;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<SearchLookupResponse[]>(URL, { observe: 'response', headers: headers });
	}

  search(term: string): Observable<HttpResponse<RecordResponse[]>> {
		const URL = this.baseCrmUrl + '/records/search/term/' + term;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<RecordResponse[]>(URL, { observe: 'response', headers: headers });
	}

  createRecord(record: any, module: string): Observable<HttpResponse<Record>> {
    const URL = this.baseCrmUrl + '/records/module/' + module;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<Record>(
      URL,
      record,
      { observe: 'response', headers: headers  }
    );
  }

  updateRecord(recordId: string, record: any, module: string): Observable<HttpResponse<Record>> {
    const URL = this.baseCrmUrl + '/records/module/' + module + '/' + recordId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<Record>(
      URL,
      record,
      { observe: 'response', headers: headers  }
    );
  }

  createActivity(data: any, module: string, subModule: string): Observable<HttpResponse<any>> {
    const URL = this.baseCrmUrl + '/activity/module/' + module + '/submodule/' + subModule;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<any>(
      URL,
      data,
      { observe: 'response', headers: headers  }
    );
  }

  getActivity(recordId: any, module: string, update: boolean): Observable<HttpResponse<Activity>> {
    const URL = this.baseCrmUrl + '/activity/module/' + module + '/id/' + recordId + '?update=' + update;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get<Activity>(URL, { observe: 'response', headers: headers });
  }

  createDeals(data: any, recordId: string): Observable<HttpResponse<any>> {
    const URL = this.baseCrmUrl + '/deals/' + recordId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<any>(
      URL,
      data,
      { observe: 'response', headers: headers  }
    );
  }

  getDeals(recordId: any, updateCache: boolean): Observable<HttpResponse<Activity>> {
    const URL = this.baseCrmUrl + '/deals/' + recordId + '?update=' + updateCache;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get<Activity>(URL, { observe: 'response', headers: headers });
  }

  convertLead(recordId: string): Observable<HttpResponse<any>> {
    const URL = this.baseCrmUrl + '/records/convert/' + recordId;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<any>(
      URL,
      null,
      { observe: 'response', headers: headers  }
    );
  }

  suggestionsLookup(module: string): Observable<HttpResponse<any[]>> {
		const URL = this.baseCrmUrl + '/suggestions/lookup?&module=' + module;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

}
