import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-redirect-zoho',
	templateUrl: './redirect-zoho.component.html',
	styleUrls: ['./redirect-zoho.component.scss']
})
export class RedirectZohoCRMComponent implements OnInit {
	private route = inject(ActivatedRoute);

	ngOnInit(): void {
		this.route.queryParams.subscribe();
	}
}
