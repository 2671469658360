import { Component, ElementRef, EventEmitter, HostListener, Input, Output, inject } from '@angular/core';

@Component({
	selector: 'app-field-search',
	templateUrl: './field-search.component.html',
	styleUrls: ['./field-search.component.scss']
})
export class FieldSearchComponent {
	@Input() classInput: string = '';
	@Input() placeholderInput: string = 'chatComponent.chatpanelheader.placeholder';
	@Input() showField: boolean = false;

	@Output() emitValue: EventEmitter<string> = new EventEmitter<string>();

	private elementRef = inject(ElementRef);

	public valueInput: string = '';

	handleWrite(): void {
		this.emitValue.emit(this.valueInput);
	}

	@HostListener('document:click', ['$event'])
	handleDocumentClick(event: MouseEvent) {
		const clickedElement = event.target as HTMLElement;
		if (!this.elementRef.nativeElement.contains(clickedElement)) {
			this.showField = false;
		}
	}
}
