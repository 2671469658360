<div *ngIf="stateSearchAccount" class="search-field">
	<input type="text" class="form-control" [(ngModel)]="accountName" [placeholder]="placeholder" />

	<button type="button" class="btn btn-danger btn-icon" (click)="searchAccount()">
		<i class="material-icons-outlined">search</i>
	</button>
</div>
<div *ngIf="!stateSearchAccount" class="search-field-select">
	<p-dropdown
		[options]="accounts"
		optionLabel="name"
		optionValue="id_account"
		[(ngModel)]="accountName"
		(change)="emitSelectAcount()"
	/>

	<button type="button" class="btn btn-danger btn-icon" (click)="searchStateAccount()">
		<i class="ri-search-line"></i>
	</button>
</div>
