import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Channel } from 'src/app/chat/data-access/enum/channel-type.enum';
import { MessageType } from 'src/app/chat/data-access/interface/message.interface';
import { Message } from 'src/app/chat/data-access/models/message';

@Component({
	selector: 'app-chat-conversation-item',
	templateUrl: './chat-conversation-item.component.html',
	styleUrls: ['./chat-conversation-item.component.scss']
})
export class ChatConversationItemComponent {
	@Input() conversationId: string = '';
	@Input() fullname: string = '';
	@Input() phoneNumber: string = '';
	@Input() lastMessage: Message;
	@Input() timestamp: number = 0;
	@Input() unSeenMessages: number = 0;
	@Input() channel: number = 0;
	@Input() nameChannel: string = '';

	@Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();

	sendConversation(event: any) {
		this.removeClass();
		this.addClass(event);
		event.preventDefault();
		this.dataChanged.emit({ conversationId: this.conversationId, phoneNumber: this.phoneNumber });
	}

	removeClass(): void {
		var removeClass = document.querySelectorAll('.chat-user-list li');
		removeClass.forEach((element: any) => {
			element.classList.remove('active');
		});
	}

	addClass(event: any): void {
		event.target.closest('li').classList.add('active');
	}

	getTime(timestamp: number): string {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
		const date = new Date(timestamp * 1000);
		let groupKey = date.toLocaleDateString('es-ES', { day: 'numeric', month: 'short', year: 'numeric' });

		// Comparar las fechas ignorando la hora, los minutos y los segundos
		const isToday =
			date.getFullYear() === today.getFullYear() &&
			date.getMonth() === today.getMonth() &&
			date.getDate() === today.getDate();
		const isYesterday =
			date.getFullYear() === yesterday.getFullYear() &&
			date.getMonth() === yesterday.getMonth() &&
			date.getDate() === yesterday.getDate();

		if (isToday) {
			groupKey = 'Hoy';
		} else if (isYesterday) {
			groupKey = 'Ayer';
		}
		return groupKey;
	}

	getChannelTypeIcon(): string {
		switch (this.channel) {
			case Channel.WhatsApp:
				return 'ri-whatsapp-line';
			case Channel.Telegram:
				return 'ri-telegram-line';
			case Channel.Messenger:
				return 'ri-messenger-line';
			case Channel.MessagingHub:
				return 'ri-message-3-line';
			case Channel.Bot:
				return 'ri-robot-line';
			case Channel.Email:
				return 'ri-mail-line';
			default:
				return 'ri-whatsapp-line';
		}
	}

	getMessageType(): MessageType {
		return this.lastMessage.messageType;
	}

	isMessageText(): boolean {
		return [MessageType.Text, MessageType.Sticker].includes(this.getMessageType());
	}

	getMessageTypeText(): string {
		switch (this.getMessageType()) {
			case MessageType.Document:
				return 'File';
			case MessageType.Video:
				return 'Video';
			case MessageType.Audio:
				return 'Play';
			case MessageType.Sticker:
				return 'Smile';
			default:
				return 'Photo';
		}
	}

	getMessageTypeIconClass(): string {
		switch (this.getMessageType()) {
			case MessageType.Document:
				return 'ri-file-line';
			case MessageType.Video:
				return 'ri-video-line';
			case MessageType.Audio:
				return 'ri-mv-line';
			case MessageType.Sticker:
				return 'icon-smile';
			default:
				return 'ri-image-line';
		}
	}

  getTruncatedMessage(): string {
    if (this.lastMessage.message.length > 40) {
      return this.lastMessage.message.substring(0, 37) + '...';
    } else {
      return this.lastMessage.message;
    }
  }
}
