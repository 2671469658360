import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-error',
  templateUrl: './chat-error.component.html',
  styleUrls: ['./chat-error.component.scss']
})
export class ChatErrorComponent {

}
