import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-input',
  template: `
    <div class="flex flex-column gap-1">
      <label style="color: #7A7F9A; font-size: 12px; font-weight: 600;" >{{ props['display_label'] }}</label>
      <p-calendar
        [(ngModel)]="modelDate"
        styleClass="w-full"
        [showTime]="true"
        [showSeconds]="true"
        [formControl]="formControl"
        [formlyAttributes]="field"
      ></p-calendar>
    </div>
  `,
  styles: ['.custom { color: blue; }'],
})
export class DateTimeFieldType extends FieldType<FieldTypeConfig> implements OnInit {

  modelDate: Date | undefined;

  get type() {
    return this.props.type || 'text';
  }

  ngOnInit() {
    if (this.formControl.value) {
      this.modelDate = new Date(this.formControl.value);
    }
  }
}
