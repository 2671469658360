import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { responseConfig, responseTemplate } from '../interface/configurator.interface';
@Injectable({
	providedIn: 'root'
})
export class ConfiguratorService {
	private _http = inject(HttpClient);

	private baseUrl: string = environment.URL_INFRASTRUCTURE_CONFIGURATOR;

	getInformationConfig(id: string): Observable<responseConfig> {
		let url_ = this.baseUrl + `/v1/setup/Whatsapp/${id}`;
		return this._http.get<responseConfig>(url_);
	}

	getTemplatesByConfig(id: string, offset: number = 0, limit: number = 10): Observable<responseTemplate> {
		let url_ = this.baseUrl + `/v1/setup/Whatsapp/${id}/templates?offset=${offset}&limit=${limit}`;
		return this._http.get<responseTemplate>(url_);
	}

	getTemplatesByConfigAfter(id: string, after: string = ''): Observable<responseTemplate> {
		let url_ = this.baseUrl + `/v1/setup/Whatsapp/${id}/templates?after=${after}`;
		return this._http.get<responseTemplate>(url_);
	}

	deleteSetupById(id: string): Observable<any> {
		let url_ = this.baseUrl + '/v1/setup/Whatsapp/' + id;

		return this._http.delete<any>(url_);
	}

	updateTemplate(idSetup: string, idTemplate: string, body: any): Observable<any> {
		let url_ = this.baseUrl + `/v1/setup/Whatsapp/${idSetup}/templates/${idTemplate}`;

		return this._http.put<any>(url_, body);
	}
}
