<div class="pt-4">

  <div style="text-align: right;" class="flex-wrap mb-3">
    <p-button
    (click)="createTickets()"
    icon="pi pi-list-check" size="small"
    class="mr-2 btn-sm"
    pTooltip="{{ 'Integration.ZohoCRM.New ticket' | translate }}"
    label="{{ 'Integration.ZohoCRM.New ticket' | translate }}"
/>
  </div>


<div #childComponents></div>

</div>
