<div class="px-1 row">
	<div class="container-body">
		<h3 class="text-muted fw-normal mb-0">
      {{ 'Integration.ZohoCRM.Start your account in ' + getLabel() | translate }}
    </h3>

    <div class="flex justify-content-center flex-wrap align-items-center mt-5">
      <p-button
        label="{{ 'Integration.ZohoCRM.Go to ' + getLabel() | translate }}"
        icon="pi pi-sign-in" size="small"
        class="mr-2"
        type="button"
        (click)="login()"
      />
    </div>

    <div class="flex justify-content-center flex-wrap align-items-center mt-1">
      <p-button
        label=" {{ 'Go back' | translate }}"
        icon="pi pi-replay" size="small"
        class="mr-2"
        type="button"
        severity="danger"
        (click)="cancel()"
      />
    </div>

  </div>
</div>
