import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Domains } from 'src/app/core/enums/domains.enum';
import { TokenTTCState } from '../states/token-ttc.state';

@Injectable()
export class TimeToChangeInterceptor implements HttpInterceptor {
	private store = inject(Store);
	private token: string = '';

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.url.includes('oauth/v1/auth')) return next.handle(req);

		if (req.url.includes(Domains.TimeToChange)) {
			this.token = this.store.selectSnapshot(TokenTTCState.getToken);
			if (this.token === null) return next.handle(req);
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${this.token}`,
					'Content-Type': 'application/json'
				}
			});
			return next.handle(req);
		}
		return next.handle(req);
	}
}
