import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-logout-redirect',
	templateUrl: './logout-redirect.component.html',
	styleUrls: ['./logout-redirect.component.scss']
})
export class LogoutRedirectComponent implements OnInit {
	constructor(private router: Router) {}

	async ngOnInit() {
		this.router.navigateByUrl('', { replaceUrl: true });
	}
}
