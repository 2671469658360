import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact } from 'src/app/contact/data-access/interfaces/list-contact.interface';

@Component({
	selector: 'app-list-contact-item',
	templateUrl: './list-contact-item.component.html',
	styleUrls: ['./list-contact-item.component.scss']
})
export class ListContactItemComponent {
	@Input() infoContact: Contact;

	@Output() emitOption: EventEmitter<string> = new EventEmitter<string>();

	public listOptions: any[] = [
		{
			label: 'Copy link to contact',
			icon: 'share'
		},
		{
			label: 'Block',
			icon: 'block'
		},
		{
			label: 'Remove',
			icon: 'delete'
		}
	];

	handleClickOption(option: string): void {
		this.emitOption.emit(option);
	}
}
