<div class="side-menu flex-lg-column me-lg-1 ms-lg-0">
	<!-- LOGO -->
	<div class="navbar-brand-box">
		<a class="logo logo-dark">
			<span class="logo-sm">
				<img src="assets/images/logoSpelltyDark.png" alt="" height="30" />
			</span>
		</a>

		<a class="logo logo-light">
			<span class="logo-sm">
				<img src="assets/images/logoSpelltyLight.png" alt="" height="30" />
			</span>
		</a>
	</div>
	<!-- end navbar-brand-box -->

	<!-- Start side-menu nav -->
	<div class="flex-lg-column my-auto">
		<ul class="nav nav-pills side-menu-nav justify-content-center" role="tablist">
			<ng-container *ngFor="let item of menuItems; let i = index">
				<li class="nav-item" [pTooltip]="item.label | translate" tooltipPosition="right">
					<a
						id="pills-user-tab"
						class="nav-link"
						[ngClass]="{ active: item.isSelected }"
						[routerLink]="item.link"
						routerLinkActive="active"
						[routerLinkActiveOptions]="{ exact: false }"
					>
						<i class="material-icons-outlined">{{ item.icon }}</i>
					</a>
				</li>
			</ng-container>
		</ul>
	</div>
	<!-- end side-menu nav -->
	<div class="flex-lg-column d-none d-lg-block">
		<ul class="nav side-menu-nav justify-content-center">
			<li class="nav-item">
				<a class="nav-link" href="javascript:void(0);" (click)="menuApp.toggle($event)">
					<span class="material-symbols-outlined"> widgets </span>
				</a>

				<p-menu #menuApp [model]="menuApps" [popup]="true">
					<ng-template pTemplate="item" let-item>
						<a class="app-option" [href]="item.url" target="_blank">
							<img src="assets/images/{{ item.image }}" alt="user-image" class="me-1" height="25" />
							<span class="align-middle">{{ item.label }}</span>
						</a>
					</ng-template>
				</p-menu>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="javascript:void(0);" (click)="menuLanguage.toggle($event)">
					<i class="material-icons-outlined">language</i>
					<img src="assets/images/flags/{{ language.icon }}.svg" class="float-lenguage" />
				</a>

				<p-menu #menuLanguage [model]="listLang" [popup]="true">
					<ng-template pTemplate="item" let-item>
						<div
							class="contaner-option"
							[ngClass]="{ 'custom-option-active': mode === 'dark', active: item === language }"
							(click)="changeLanguage(item.id)"
						>
							<img src="assets/images/flags/{{ item.icon }}.svg" alt="user-image" class="me-1" height="25" />
							<span class="align-middle">{{ item.label }}</span>
						</div>
					</ng-template>
				</p-menu>
			</li>
			<li class="nav-item">
				<a
					class="nav-link light-dark-mode"
					id="light-dark"
					href="javascript:void(0);"
					pTooltip="{{ 'Light mode' | translate }}"
					tooltipPosition="right"
				>
					<i class="material-icons-outlined layout-mode-dark" (click)="changeMode('dark')">wb_sunny</i>
					<i class="material-icons-outlined layout-mode-light" (click)="changeMode('light')">dark_mode</i>
				</a>
			</li>

			<li class="nav-item">
				<a
					class="nav-link light-dark-mode"
					id="light-dark"
					href="javascript:void(0);"
					pTooltip="{{ 'logout' | translate }}"
					tooltipPosition="right"
					(click)="logout()"
				>
					<i class="material-icons-outlined">logout</i>
				</a>
			</li>

			<li class="nav-item">
				<a
					*ngIf="isInfo"
					class="nav-link flex justify-content-center"
					href="javascript:void(0);"
					(click)="menuProfile.toggle($event)"
				>
					<div class="flex align-content-center flex-wrap">
						<span class="avatar">
							{{ fullName.charAt(0) }}
						</span>
						<span class="user-status" [ngClass]="{ online: isConnected, away: !isConnected }"></span>
					</div>
				</a>

				<p-menu #menuProfile [model]="itemsProfile" [popup]="true">
					<ng-template pTemplate="item" let-item>
						<div class="contaner-option" [ngClass]="{ 'custom-option-active': mode === 'dark' }" (click)="logout()">
							<span class="align-middle">{{ item.label | translate }}</span>
							<i [class]="item.icon"></i>
						</div>
					</ng-template>
				</p-menu>
			</li>
		</ul>
	</div>
	<!-- Side menu user -->
</div>
