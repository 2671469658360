import { Channel } from '../data-access/enum/channel-type.enum';
import { ServiceInstanceManagerI, channelManagerI } from '../data-access/interface/service-instance-manager.interface';
import { Chat } from '../data-access/models/chat';

export function findExistConversationById(chatManager: ServiceInstanceManagerI[], conversationId: string): boolean {
	return chatManager.some((item) =>
		item.config.channels.some((itemChannel) =>
			itemChannel.conversations.some((conversation) => conversation.id === conversationId)
		)
	);
}

export function getIndexConversationById(chatManager: ServiceInstanceManagerI[], conversationId: string): number {
	return chatManager.findIndex((item) =>
		item.config.channels.some((itemChannel) =>
			itemChannel.conversations.some((conversation) => conversation.id === conversationId)
		)
	);
}

export function findObjectById(chatManager: ServiceInstanceManagerI[], setupId: string): number {
	return chatManager.findIndex((item) =>
		item.config.channels.some((itemChannel) => itemChannel.channelInfo.businessNumber === setupId)
	);
}

export function findChannelById(channelsManager: channelManagerI[], setupId: string): number {
	return channelsManager.findIndex((item) => item.channelInfo.businessNumber === setupId);
}

export function getLastTimeStamp(conversation: any): any {
	const messages = conversation.messages;
	return messages.length > 0 ? messages[messages.length - 1].timeStamp : 0;
}

export function sortConversation(conversations: Chat[]): Chat[] {
	conversations = conversations.sort((conversacionA, conversacionB) => {
		const lastTimeStampA = getLastTimeStamp(conversacionA);
		const lastTimeStampB = getLastTimeStamp(conversacionB);

		return lastTimeStampB - lastTimeStampA;
	});

	return conversations;
}

export function removeConversationById(
	chatManager: ServiceInstanceManagerI[],
	conversationId: string
): ServiceInstanceManagerI[] {
	return chatManager.map((item) => {
		return {
			...item,
			config: {
				...item.config,
				channels: item.config.channels.map((channel) => {
					return {
						...channel,
						conversations: channel.conversations.filter((conversation) => conversation.id !== conversationId)
					};
				})
			}
		};
	});
}

export function findConversationById(chatManager: ServiceInstanceManagerI[], conversationId: string): Chat | null {
	const allConversations = chatManager.reduce((acc, item) => {
		const channelConversations = item.config.channels.reduce((channelAcc, channel) => {
			return channelAcc.concat(channel.conversations);
		}, []);
		return acc.concat(channelConversations);
	}, []);

	return allConversations.find((conversation) => conversation.id === conversationId) || null;
}

export function getChannelTypeIcon(typeChannel: number): string {
	switch (typeChannel) {
		case Channel.WhatsApp:
			return 'ri-whatsapp-line';
		case Channel.Telegram:
			return 'ri-telegram-line';
		case Channel.Messenger:
			return 'ri-messenger-line';
		case Channel.MessagingHub:
			return 'ri-message-3-line';
		case Channel.Bot:
			return 'ri-robot-line';
		case Channel.Email:
			return 'ri-mail-line';
		default:
			return 'ri-whatsapp-line';
	}
}
