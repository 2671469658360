import { Injectable } from '@angular/core';
import { MessageType } from '../interface/message.interface';


@Injectable({
  providedIn: 'root',
})
export class MimetypeService {
  private supportedTypes: any = {
    audio: ['audio/aac', 'audio/mp4', 'audio/mpeg', 'audio/amr', 'audio/ogg'],
    document: [
      'text/plain',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    image: ['image/jpeg', 'image/png'],
    video: ['video/mp4', 'video/3gp'],
    sticker: ['image/webp'],
  };
  //MB
  private sizeLimits: any = {
    audio: 16,
    document: 100,
    image: 5,
    video: 16,
    sticker: 0.5,
  };

  public mapFile(file: File): { type: string; sizeLimit: number } | undefined {
    const mimetype = file.type;
    const sizeInMB = file.size / 1024 / 1024;

    for (let type in this.supportedTypes) {
      if (
        this.supportedTypes[type].includes(mimetype) &&
        sizeInMB <= this.sizeLimits[type]
      ) {
        return { type, sizeLimit: this.sizeLimits[type] };
      }
    }

    return undefined;
  }

  public getMessageType(fileType: string): MessageType {
    switch (fileType) {
      case 'audio':
        return MessageType.Audio;
      case 'document':
        return MessageType.Document;
      case 'image':
        return MessageType.Image;
      case 'video':
        return MessageType.Video;
      case 'sticker':
        return MessageType.Sticker;
      default:
        throw new Error('Invalid file type');
    }
  }
}
