<i class="material-icons-outlined">search</i>
<div
	*ngIf="showField"
	class="search-box p-2 options custom-scrollbar swing-in-top-fwd"
	(click)="$event.stopPropagation()"
>
	<input
		type="text"
		class="form-control bg-light border-0"
		placeholder="{{ placeholderInput | translate }}"
		[(ngModel)]="valueInput"
		(keyup)="handleWrite()"
	/>
</div>
