export interface identificationType {
  value: number;
  text: string;
}

export const identificationTypes: identificationType[] = [
  {
    value: 1,
    text: 'CC',
  },
  {
    value: 2,
    text: 'CE',
  },
  {
    value: 3,
    text: 'TI',
  },
]
